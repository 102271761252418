import React, {Component} from "react";
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl} from "react-bootstrap";
import {Redirect} from "react-router";
import Switch from "react-bootstrap-switch";
import axios from "axios/index";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import Button from "../../components/CustomButton/CustomButton";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import Card from "../../components/Card/Card.jsx";
import ModalSave from "../../components/CustomModals/ModalSave";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import {DropDownContentProviders} from "../../components/CustomDropDown/DropDownContentProviders";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

const adminArray = ['super-admin', 'admin']

class EditEventCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {

            _notificationSystem: null,

            loading: false,
            saving: false,
            redirect: false,
            showSaveModal: false,

            eventCatRedirect: false,
            back_button_text: "Cancel",

            categoryObject: [],

            live_event_cat_id: 0,
            live_event_cat_position: 0,
            live_event_cat_status: false,
            live_event_cat_global_status: false,
            live_event_cat_image: "",
            live_event_cat_name_short: "",
            live_event_cat_name_long: "",
            live_event_cat_description: "",

            live_event_cat_image_temp: false,
            live_event_cat_image_folder_temp: "",

            live_event_cat_content_provider:null,
            current_user: null,
            user_content_provider: null,
            custom_data: null
        };

        // this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);

    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
            });
        }
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/categories/events";
        let pathCategory = "/categories/edit-event-cat/" + this.state.live_event_cat_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.eventCatRedirect) {
            console.log(pathCategory);
            return <Redirect push to={pathCategory}/>
        }

    }

    submitCatImage(filename) {

        if (this.state.live_event_cat_image_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.live_event_cat_image_folder_temp);
            bodyFormData.set('location', "live_events_cat");
            bodyFormData.set('filename', filename);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({live_event_cat_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    handleChildImageSelect(folder, filename) {

        console.log("" + folder);
        console.log("" + filename);
        console.log(process.env.REACT_APP_ASSET_URL + "/tmp/" + folder + "/" + filename);

        this.setState({
            live_event_cat_image_temp: filename !== null,
            live_event_cat_image: filename,
            live_event_cat_image_folder_temp: folder
        });
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})
        this.setState({saving: false, loading: true})

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/LiveEventCategory/" + handle;

        if (handle === 'new') {
            this.setState({loading: false})
        } else {

            axios
                .get(url, axiosConfig)
                .then(res => {

                    const categoryObject = res.data[0];
                    const loading = false;

                    console.log(categoryObject);

                    this.setState({
                        back_button_text: "Back",
                        live_event_cat_id: categoryObject.live_event_cat_id,
                        live_event_cat_status: categoryObject.live_event_cat_status === 0 ? false : true,
                        live_event_cat_global_status: categoryObject.live_event_cat_global_status === 0 ? false : true,
                        live_event_cat_position: categoryObject.live_event_cat_position === null ? 0 : categoryObject.live_event_cat_position,
                        live_event_cat_name_short: categoryObject.live_event_cat_name_short === null ? "" : categoryObject.live_event_cat_name_short,
                        live_event_cat_name_long: categoryObject.live_event_cat_name_long === null ? "" : categoryObject.live_event_cat_name_long,
                        live_event_cat_description: categoryObject.live_event_cat_description === null ? "" : categoryObject.live_event_cat_description,
                        live_event_cat_image: categoryObject.live_event_cat_image === null ? "" : categoryObject.live_event_cat_image,
                        loading,
                        categoryObject,
                        current_user: user,
                        user_content_provider: categoryObject.live_event_cat_content_provider,
                        custom_data: categoryObject.custom_data
                    });

                    this.setState({saving: false, loading: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                    this.setState({saving: false, loading: false})
                })
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'statusSwitch' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    updateEventCategory() {

        this.setState({saving: true, loading: true})
        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        // this.submitPosterImage();

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/LiveEventCategory/" + handle;

        let tempEventCatStatus = this.state.live_event_cat_status === true ? 1 : 0;
        let tempEventCatGlobalStatus = this.state.live_event_cat_global_status === true ? 1 : 0;

        let filename = ""

        if (this.state.live_event_cat_image_temp) {
            let timeUTC = new Date().getTime()
            filename = timeUTC + "_" + this.state.live_event_cat_image
            this.setState({live_event_cat_image: filename})
            //console.log("*****" + filename)
            this.submitCatImage(filename);
        } else {
            filename = this.state.live_event_cat_image
        }

        // Send a PUT request
        var postData = {
            live_event_cat: {
                live_event_cat_id: handle,
                live_event_cat_status: tempEventCatStatus,
                live_event_cat_global_status: tempEventCatGlobalStatus,
                live_event_cat_position: this.state.live_event_cat_position,
                live_event_cat_name_short: this.state.live_event_cat_name_short,
                live_event_cat_name_long: this.state.live_event_cat_name_long,
                live_event_cat_description: this.state.live_event_cat_description,
                live_event_cat_image: filename,
                live_event_cat_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data,
            }
        };


        axios.put(url, postData, axiosConfig)
            .then((res) => {
                this.setState({saving: false, loading: false})
                this.notification("success")
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({saving: false, loading: false})
                this.notification("failed")
            })

    }

    createEventCategory() {

        this.setState({saving: true, loading: true})

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        const url = process.env.REACT_APP_API_URL + "/LiveEventCategory";

        let tempEventCatStatus = this.state.live_event_cat_status === true ? 1 : 0;
        let tempEventCatGlobalStatus = this.state.live_event_cat_global_status === true ? 1 : 0;

        let filename = ""

        if (this.state.live_event_cat_image_temp) {
            let timeUTC = new Date().getTime()
            filename = timeUTC + "_" + this.state.live_event_cat_image
            this.setState({live_event_cat_image: filename})
            //console.log("*****" + filename)
            this.submitCatImage(filename);
        } else {
            filename = this.state.live_event_cat_image
        }

        var postData = {
            live_event_cat: {
                live_event_cat_status: tempEventCatStatus,
                live_event_cat_global_status: tempEventCatGlobalStatus,
                live_event_cat_position: this.state.live_event_cat_position,
                live_event_cat_name_short: this.state.live_event_cat_name_short,
                live_event_cat_name_long: this.state.live_event_cat_name_long,
                live_event_cat_description: this.state.live_event_cat_description,
                live_event_cat_image: filename,
                live_event_cat_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data,
            }
        };


        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({saving: false, loading: false})
                this.submitCatImage();

                if (res.data.code === 300) {
                    console.log("*1: ", res.data.code);
                    console.log("*2: ", res.data.message);
                }
                const categoryObject = res.data;
                this.setState({live_event_cat_id: categoryObject.live_event_cat_id})
                console.log("live_event_cat_id SAVED: " + categoryObject.live_event_cat_id);
                this.notification("success")
                this.setEventCatRedirect();

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({saving: false, loading: false})
                this.notification("failed")
            })

    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'statusSwitch') {
            this.setState({live_event_cat_status: state})
        }
        if (elem.props.name === 'statusGlobalSwitch') {
            this.setState({live_event_cat_global_status: state})
        }
    }

    cancelEventCatEdit() {
        this.setRedirect();
    }

    setEventCatRedirect = () => {
        this.setState({
            eventCatRedirect: true,
            back_button_text: "Back"
        })
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Live Event Category: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.live_event_cat_id}
            />
        }
    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({saving: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createEventCategory();
            } else {
                this.updateEventCategory();
            }
        }
    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Event Category has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update Event Category</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    title() {
        if (this.state.live_event_cat_name_short === null) {
            return "New - " + this.props.t('event_category_edit.main_title', {framework: "react-i18next"});
        } else {
            return this.state.live_event_cat_name_short + " - " + this.props.t('event_category_edit.main_title', {framework: "react-i18next"});
        }
    }

    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (

                    <FormGroup>
                        <ControlLabel>Content Provider</ControlLabel>
                        <DropDownContentProviders
                            contentProviderSelected={this.state.user_content_provider}
                            name='contentProviderSelect'
                            handlerFromParent={this.handleChildContentProviderSelect}
                            disabled={this.state.loading}
                        />
                    </FormGroup>

                )
            }
        }

    }

    render() {
        return (
            <div className="main-content">
                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Card

                                content={
                                    <div>
                                        <h5>{this.title()}</h5>
                                        <legend/>
                                        <FormGroup>
                                            <ImageUpload
                                                imageName={this.state.live_event_cat_image}
                                                imageType="live_events_cat"
                                                imagePosition={"live_event_cat_image"}
                                                imageTemp={this.state.live_event_cat_image_temp}
                                                imageFolderTemp={this.state.live_event_cat_image_folder_temp}
                                                handlerFromParent={this.handleChildImageSelect}
                                            />
                                        </FormGroup>
                                        <legend></legend>

                                        <FormGroup>
                                            <p className="category">{this.props.t('global.status', {framework: "react-i18next"})}</p>
                                            <Switch
                                                name={"statusSwitch"}
                                                disabled={this.state.loading}
                                                value={this.state.live_event_cat_status}
                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <p className="category">{"Globally Available"}</p>
                                            <Switch
                                                name={"statusGlobalSwitch"}
                                                disabled={this.state.loading}
                                                value={this.state.live_event_cat_global_status}
                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>{this.props.t('global.position', {framework: "react-i18next"})}</ControlLabel>
                                            <FormControl
                                                placeholder={this.props.t('event_category_edit.position_placeholder', {framework: "react-i18next"})}
                                                type="number"
                                                name={"live_event_cat_position"}
                                                disabled={this.state.loading}
                                                onChange={this.handleInputChange}
                                                value={this.state.live_event_cat_position}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>{this.props.t('event_category_edit.name_short', {framework: "react-i18next"})}</ControlLabel>
                                            <FormControl
                                                placeholder={this.props.t('event_category_edit.name_short_placeholder', {framework: "react-i18next"})}
                                                type="text"
                                                name={"live_event_cat_name_short"}
                                                disabled={this.state.loading}
                                                onChange={this.handleInputChange}
                                                value={this.state.live_event_cat_name_short}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>{this.props.t('event_category_edit.name_long', {framework: "react-i18next"})}</ControlLabel>
                                            <FormControl
                                                placeholder={this.props.t('event_category_edit.name_long_placeholder', {framework: "react-i18next"})}
                                                type="text"
                                                name={"live_event_cat_name_long"}
                                                disabled={this.state.loading}
                                                onChange={this.handleInputChange}
                                                value={this.state.live_event_cat_name_long}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>{this.props.t('event_category_edit.description', {framework: "react-i18next"})}</ControlLabel>
                                            <FormControl componentClass="textarea"
                                                         type="text"
                                                         name="live_event_cat_description"
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         placeholder={this.props.t('event_category_edit.description_placeholder', {framework: "react-i18next"})}
                                                         value={this.state.live_event_cat_description}
                                            />
                                        </FormGroup>

                                        {this.renderContentProvider()}

                                    </div>
                                }
                                ftTextCenter
                                legend={
                                    <div className="buttons-with-margin">
                                        <Button bsStyle="info" fill wd
                                                name={"saveButton"}
                                                disabled={this.state.loading}
                                                onClick={() => this.setState({showSaveModal: true})}
                                        >
                                            {this.state.loading ? 'Loading...' : this.props.t('global.save', {framework: "react-i18next"})}
                                        </Button>
                                        <Button bsStyle="default" fill wd
                                                name={"cancelButton"}
                                                disabled={this.state.loading}
                                                onClick={this.cancelEventCatEdit.bind(this)}>
                                            {this.state.back_button_text}
                                        </Button>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>

            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedEditEventCategory = connect(mapStateToProps)(EditEventCategory);
const connectedEditEventCategoryTrans = withNamespaces('common')(connectedEditEventCategory);
export {connectedEditEventCategoryTrans as EditEventCategory};