import React, {Component} from "react";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Modal, Row} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import {withNamespaces} from "react-i18next";
import {connect} from 'react-redux';
import axios from "axios";
import {userActions} from "../../authentication/_actions";
import ImageUpload from "../ImageUpload/ImageUpload";
import {style} from "../../variables/Variables";
import NotificationSystem from "react-notification-system";
import {AxiosHeader} from "../../functions/GlobalFunctions";

class ModalOrganization extends Component {

    constructor(props) {
        super(props);

        let user = JSON.parse(localStorage.getItem('user'));

        this.state = {

            access: user.level,

            showModalOrganization: true,

            organization_id: null,
            organization_name: null,
            organization_description: null,
            organization_logo: null,
            organization_status: 0,
            organization_logo_temp: false,
            organization_logo_folder_temp: "",

            organizationProviderArray: [],

            _notificationSystem: null

        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOrganizationImageSelect = this.handleOrganizationImageSelect.bind(this);

    }

    handleOrganizationImageSelect(folder, filename, imagePosition) {

        if (imagePosition === "organization_logo") {
            if (filename !== null) {
                this.setState({
                    organization_logo_temp: true,
                    organization_logo: filename,
                    organization_logo_folder_temp: folder
                });
            } else {
                this.setState({
                    organization_logo_temp: false,
                    organization_logo: null,
                    organization_logo_folder_temp: ""
                });
            }

        }

    }

    submitPosterImage(filename_poster) {

        if (this.state.organization_logo_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.organization_logo_folder_temp);
            bodyFormData.set('location', "organization");
            bodyFormData.set('filename', filename_poster);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({organization_logo_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    handleClose = () => {
        this.setState({showModalOrganization: false})
        this.props.handlerFromParent(this.props.handlerID, false, "nothing");
    }

    manageSaveDevice() {

        if (this.props.handlerID === "new") {
            this.createOrganization();
        } else {
            this.editOrganization();
        }

    }

    createOrganization() {

        if (AxiosHeader.getConfig() !== null) {
            const url = process.env.REACT_APP_API_URL + "/Organization";

            let filename_poster = ""

            if (this.state.organization_logo_temp) {
                let timeUTC = new Date().getTime()
                filename_poster = timeUTC + "_" + this.state.organization_logo
                this.setState({organization_logo: filename_poster})
                // console.log("*****" + filename_poster)
                this.submitPosterImage(filename_poster);
            } else {
                filename_poster = this.state.organization_logo
            }


            let postData = {
                organization_name: this.state.organization_name,
                organization_description: this.state.organization_description,
                organization_logo: filename_poster,
                organization_status: this.state.organization_status,

            };

            axios.post(url, postData, AxiosHeader.getConfig())
                .then((res) => {

                    if (res.data.error === 301) {
                        this.notification("failed", "Failed to Create Organization")
                    } else {
                        this.setState({showModalMessage: false})
                        this.props.handlerFromParent(this.props.handlerID, false, "reload");
                    }
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })

        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }
    }

    editOrganization() {

        if (AxiosHeader.getConfig() !== null) {

            this.setState({loading: true});
            const url = process.env.REACT_APP_API_URL + "/Organization/" +this.props.handlerID;

            let filename_poster = ""

            if (this.state.organization_logo_temp) {
                let timeUTC = new Date().getTime()
                filename_poster = timeUTC + "_" + this.state.organization_logo
                this.setState({organization_logo: filename_poster})
                // console.log("*****" + filename_poster)
                this.submitPosterImage(filename_poster);
            } else {
                filename_poster = this.state.organization_logo
            }

            let postData = {
                organization_id: this.props.handlerID,
                organization_name: this.state.organization_name,
                organization_description: this.state.organization_description,
                organization_logo: filename_poster,
                organization_status: this.state.organization_status,
            };

            axios.put(url, postData, AxiosHeader.getConfig())
                .then((res) => {
                    if (res.data.error === 301) {
                        this.notification("failed", "Failed to updated Organization")
                        this.setState({loading: false});
                    } else {
                        this.setState({showModalMessage: false, loading: false})
                        this.props.handlerFromParent(this.props.handlerID, false, "reload");
                    }

                })
                .catch((err) => {
                    this.setState({loading: false});
                    console.log("AXIOS ERROR: ", err);
                })
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

    }

    componentDidMount() {
        this.setState({_notificationSystem: this.refs.notificationSystem})
        this.loadOrganization();
    }

    loadOrganization() {

        if (AxiosHeader.getConfig() !== null) {

            if (this.props.handlerID !== "new") {

                this.setState({loading: true});

                let dataUrl = process.env.REACT_APP_API_URL + "/Organization/" + this.props.handlerID;

                axios.get(dataUrl, AxiosHeader.getConfig()).then(res => {

                    const organizationArray = res.data;

                    this.setState({
                        organizationArray,
                        loading: false,

                        organization_id: organizationArray[0].organization_id,
                        organization_name: organizationArray[0].organization_name,
                        organization_description: organizationArray[0].organization_description,
                        organization_logo: organizationArray[0].organization_logo,
                        organization_status: organizationArray[0].organization_status,
                    });

                }).catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                });
            }
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {

        if (elem.props.name === 'statusSwitch') {
            this.setState({statusSwitch: state})
        }
    }

    notification(status, message) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>{message}</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>{message}</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }


    render() {
        return (
            <div>
                <NotificationSystem ref="notificationSystem" style={style}/>

                <Modal show={this.state.showModalOrganization} onHide={this.handleClose} bsSize="lg"
                       animation={false}>
                    <Modal.Header closeButton style={{backgroundColor: '#fbfbfb'}}>
                        <Modal.Title>{this.props.headerMessage}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Grid fluid>
                            <Row>
                                <Col sm={7}>
                                    <FormGroup>
                                        <ControlLabel>Provider ID</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="id"
                                            disabled
                                            value={this.state.organization_id || ''}
                                            placeholder="Organization ID"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Status</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="id"
                                            disabled
                                            value={this.state.organization_status || ''}
                                            placeholder="Status"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Organization Name</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="organization_name"
                                            value={this.state.organization_name || ''}
                                            placeholder="Provider Name"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Organization Description</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="organization_description"
                                            value={this.state.organization_description || ''}
                                            placeholder="Provider Description"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col sm={5}>

                                    <FormGroup
                                        style={{
                                            borderStyle: 'solid',
                                            borderWidth: 'thin',
                                            padding: '10px',
                                            borderColor: '#377cb3'
                                        }}>

                                        <ControlLabel>Provider Logo</ControlLabel>
                                        <ImageUpload
                                            imageName={this.state.organization_logo}
                                            imageType="organization"
                                            imagePosition={"organization_logo"}
                                            imageTemp={this.state.organization_logo_temp}
                                            imageFolderTemp={this.state.organization_logo_folder_temp}
                                            handlerFromParent={this.handleOrganizationImageSelect}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Grid>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button bsStyle="info" fill onClick={this.manageSaveDevice.bind(this)}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedModalOrganization = connect(mapStateToProps)(ModalOrganization);
const connectedModalOrganizationTrans = withNamespaces('common')(connectedModalOrganization);
export {connectedModalOrganizationTrans as ModalOrganization};