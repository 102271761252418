import React, {Component} from "react";
// import {Col, Grid, Panel, PanelGroup, Row} from "react-bootstrap";
// import Card from "../../components/Card/Card";
// import axios from "axios";
// import Button from "../../components/CustomButton/CustomButton.jsx";
// import {ScaleLoader} from "react-spinners";
// import ModalSave from "../../components/CustomModals/ModalSave";
// import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
// import Radio from '../../components/CustomRadio/CustomRadio.jsx';
// import Select from 'react-select';

import {DropDownTvCategory} from "../../components/CustomDropDown/DropDownTvCategory";
import {DropDownAvodCategory} from "../../components/CustomDropDown/DropDownAvodCategory";
import {DropDownTvodCategory} from "../../components/CustomDropDown/DropDownTvodCategory";
import {DropDownSvodCategory} from "../../components/CustomDropDown/DropDownSvodCategory";
import {DropDownAppCategory} from "../../components/CustomDropDown/DropDownAppCategory";
import {DropDownEventCategory} from "../../components/CustomDropDown/DropDownEventCategory";
import {DropDownMatchCategory} from "../../components/CustomDropDown/DropDownMatchCategory";
import {DropDownRadioCategory} from "../../components/CustomDropDown/DropDownRadioCategory";

class DeviceApplicationCategoryComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            categoriesSelected: null
        }

        this.handleChildCategorySelect = this.handleChildCategorySelect.bind(this);
        this.handleChildVideoCategorySelect = this.handleChildVideoCategorySelect.bind(this);

    }

    componentDidMount() {

    }

    handleChildCategorySelect(data) {

        if (data !== undefined) {
            console.log("DeviceApplicationCategoryComponent: " + data)
            this.props.handlerFromParent(data);
            console.log("DeviceApplicationCategoryComponent->applicationId: " + this.applicationId);
            console.log("DeviceApplicationCategoryComponent->forMenuId: " + this.forMenuId);
            console.log("DeviceApplicationCategoryComponent->forMenu: " + this.forMenu);
        }
    }

    handleChildVideoCategorySelect(data) {

        if (data !== undefined) {

            let categoryIDs = []

            for (let i = 0; i < data.length; i++) {
                categoryIDs.push(data[i].value)
            }

            this.props.handlerFromParent(categoryIDs);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        console.log(this.props)

        if (this.props.categoriesSelected !== prevProps.categoriesSelected) {
            //this.setState({categoriesSelected: this.props.categoriesSelected})
            console.log(this.props.categoriesSelected)
        }
    }

    renderCategory = () => {
        if (this.props.contentType === 'LIVE_TV') {
            return (
                <div>
                    <DropDownTvCategory
                        categorySelected={this.props.categoriesSelected}
                        name='categorySelect'
                        disabled={this.state.loading}
                        handlerFromParent={this.handleChildCategorySelect}
                        applicationid={this.props.applicationId}
                    />
                </div>
            )
        }

        if (this.props.contentType === 'LIVE_EVENTS') {
            return (
                <div>
                    <DropDownEventCategory
                        categorySelected={this.props.categoriesSelected}
                        name='categorySelect'
                        disabled={this.state.loading}
                        handlerFromParent={this.handleChildCategorySelect}
                        applicationid={this.props.applicationId}
                    />
                </div>
            )
        }

        if (this.props.contentType === 'LIVE_RADIO') {
            return (
                <div>
                    <DropDownRadioCategory
                        categorySelected={this.props.categoriesSelected}
                        name='categorySelect'
                        disabled={this.state.loading}
                        handlerFromParent={this.handleChildCategorySelect}
                        applicationid={this.props.applicationId}
                    />
                </div>
            )
        }

        if (this.props.contentType === 'LIVE_SPORTS') {
            return (
                <div>
                    <DropDownMatchCategory
                        categorySelected={this.props.categoriesSelected}
                        name='categorySelect'
                        disabled={this.state.loading}
                        handlerFromParent={this.handleChildCategorySelect}
                        applicationid={this.props.applicationId}
                    />
                </div>
            )
        }

        if (this.props.contentType === 'TVOD') {
            return (
                <div>
                    <DropDownTvodCategory
                        categorySelected={this.props.categoriesSelected}
                        name='categorySelect'
                        disabled={this.state.loading}
                        handlerFromParent={this.handleChildVideoCategorySelect}
                        applicationid={this.props.applicationId}
                    />
                </div>
            )
        }

        if (this.props.contentType === 'SVOD') {
            return (
                <div>
                    <DropDownSvodCategory
                        categorySelected={this.props.categoriesSelected}
                        name='categorySelect'
                        disabled={this.state.loading}
                        handlerFromParent={this.handleChildVideoCategorySelect}
                        applicationid={this.props.applicationId}
                    />
                </div>
            )
        }

        if (this.props.contentType === 'AVOD') {
            return (
                <div>
                    <DropDownAvodCategory
                        categorySelected={this.props.categoriesSelected}
                        name='categorySelect'
                        disabled={this.state.loading}
                        handlerFromParent={this.handleChildVideoCategorySelect}
                        applicationid={this.props.applicationId}
                    />
                </div>
            )
        }

        if (this.props.contentType === 'SERIES') {
            return (
                <div>
                    <DropDownSvodCategory
                        categorySelected={this.props.categoriesSelected}
                        name='categorySelect'
                        disabled={this.state.loading}
                        handlerFromParent={this.handleChildVideoCategorySelect}
                        applicationid={this.props.applicationId}
                    />
                </div>
            )
        }

        if (this.props.contentType === 'APPLICATIONS') {
            return (
                <div>
                    <DropDownAppCategory
                        categorySelected={this.props.categoriesSelected}
                        name='categorySelect'
                        disabled={this.state.loading}
                        handlerFromParent={this.handleChildCategorySelect}
                        applicationid={this.props.applicationId}
                    />
                </div>
            )
        }
    }


    render() {

        return (
            <div>
                {this.renderCategory()}
            </div>

        )


    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDeviceApplicationCategoryComponent = connect(mapStateToProps)(DeviceApplicationCategoryComponent);
const connectedDeviceApplicationCategoryComponentTrans = withNamespaces('common')(connectedDeviceApplicationCategoryComponent);
export {connectedDeviceApplicationCategoryComponentTrans as DeviceApplicationCategoryComponent};
