import React, {Component} from "react";
import {Redirect} from "react-router";
import Switch from "react-bootstrap-switch";
import axios from "axios/index";
import Datetime from "react-datetime";
import moment from 'moment';
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    InputGroup,
    HelpBlock,
    Well,
    Glyphicon,
    ButtonGroup
} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import {DropDownEventCategory} from "../../components/CustomDropDown/DropDownEventCategory";
import {DropDownEventMultiCategory} from "../../components/CustomDropDown/DropDownEventMultiCategory";
import {DropDownPayPerViewPriceList} from "../../components/CustomDropDown/DropDownPayPerViewPriceList";
import ModalSave from "../../components/CustomModals/ModalSave";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import {DropDownCountries} from "../../components/CustomDropDown/DropDownCountries";
import {Calendar} from "../../components/Calendar/Calendar";
import {DropDownContentProviders} from "../../components/CustomDropDown/DropDownContentProviders";
import Select from 'react-select';
import {DropDownRating} from "../../components/CustomDropDown/DropDownRating";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
`;

const adminArray = ['super-admin', 'admin']

const eventStatusArray = [
    {value: 'pending', label: 'Pending'},
    {value: 'started', label: 'Started'},
    {value: 'finished', label: 'Finished'},
    {value: 'uploaded', label: 'Uploaded'},
    {value: 'upload_pending', label: 'Upload Pending'},
    {value: 'recently_finished', label: 'Recently Finished'},
    {value: 'recently_uploaded', label: 'Recently Uploaded'},
];

const tokenSelection = [
    {value: 'WMSPANEL', label: 'WmsPanel'},
    {value: 'FLUSSONIC', label: 'Flussonic'}
];

class EditLiveEvents extends Component {

    constructor(props) {
        super(props);
        this.state = {

            eventArray: [],
            loading: false,
            redirect: false,
            back_button_text: this.props.t('global.cancel', {framework: "react-i18next"}),

            eventRedirect: false,
            eventStatus: false,
            eventFreeStatus: false,
            showSaveModal: false,

            p2pStatus: false,
            // webLoginStatus: false,
            castingStatus: false,
            timeshiftStatus: false,
            loginRequiredStatus: false,

            live_event_dvr_duration: null,
            live_event_billing_id: null,

            live_event_id: null,
            live_event_status: null,
            live_event_free_status: null,
            live_event_position: null,
            live_event_name_short: null,
            live_event_name_long: null,
            live_event_description: null,
            live_event_cat_id: null,
            live_event_rpgroup_id: null,
            live_event_url: null,
            live_event_date: null,
            live_event_ref_id: null,
            live_event_image: null,
            live_event_image_wide: null,
            live_event_hits: null,
            live_event_country_lock: null,
            live_event_category: null,
            live_event_pre_roll: null,
            live_event_mid_roll: null,
            live_event_post_roll: null,
            live_event_cat_name_short: null,
            live_event_cat_image: null,
            live_event_producer: null,
            live_event_actors: null,
            live_event_country: null,
            live_event_duration: null,
            live_event_genre: null,
            live_event_login_required: null,

            live_event_instagram: null,
            live_event_facebook: null,
            live_event_youtube: null,
            live_event_twitter: null,
            live_event_website: null,
            live_event_ppv_url: null,
            live_event_trailer_url: null,

            live_event_date_added: null,
            live_event_date_updated: null,
            live_event_date_deleted: null,
            live_event_user_deleted: null,
            live_event_user_updated: null,
            live_event_user_added: null,

            live_event_image_temp: false,
            live_event_image_wide_temp: false,
            live_event_image_folder_temp: "",
            live_event_image_wide_folder_temp: "",

            live_event_name_long_message: "",
            live_event_name_long_validate_state: null,
            live_event_name_short_message: "",
            live_event_name_short_validate_state: null,

            live_event_blackout_countries: null,
            live_event_blackout_url: null,
            blackOutStatus: false,

            countryBlockStatus: false,
            live_event_country_block_countries: null,

            _notificationSystem: null,

            live_event_seriesid: null,
            live_event_showid: null,
            live_event_ppv: null,

            addAllCountriesInCountryBlock: false,
            addAllCountriesInBlackOut: false,

            live_event_content_provider: null,
            current_user: null,
            user_content_provider: null,
            custom_data: null,
            private_data: null,

            live_event_playback_status: null,
            live_event_content_rating: null,
            live_event_categories: null,

            live_event_streaming_token: null,


        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);
        this.handleEventDate = this.handleEventDate.bind(this);
        this.handleChildCategorySelect = this.handleChildCategorySelect.bind(this);
        this.handleChildCategoryMultiSelect = this.handleChildCategoryMultiSelect.bind(this);
        this.handleChildPPVSelect = this.handleChildPPVSelect.bind(this);
        this.handleChildCountrySelect = this.handleChildCountrySelect.bind(this);
        this.handleChildBlockCountrySelect = this.handleChildBlockCountrySelect.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);
        this.handleEventStatusChange = this.handleEventStatusChange.bind(this);
        this.handleChildRatingSelect = this.handleChildRatingSelect.bind(this);
        this.handleStreamingTokenChange = this.handleStreamingTokenChange.bind(this);

    }

    handleChildRatingSelect(data) {

        if (data !== undefined) {
            //console.log(data);
            this.setState({
                live_event_content_rating: data === null ? 0 : data.value,
            });
        }
    }

    handleEventStatusChange = (eventStatusDropDown) => {

        this.setState({
            live_event_playback_status: eventStatusDropDown === null ? "pending" : eventStatusDropDown.value
        });
        // console.log(`Option selected:`, matchStatusDropDown);

    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
            });
        }
    }

    handleChildCountrySelect(data) {

        let countryIDs = []
        let countryNames = []

        for (let i = 0; i < data.length; i++) {
            countryIDs.push(data[i].value)
        }

        for (let i = 0; i < data.length; i++) {
            countryNames.push(data[i].label)
        }

        this.setState({
            live_event_blackout_countries: data === null ? 0 : countryIDs, addAllCountriesInCountryBlock: false
        });
    }

    handleChildBlockCountrySelect(data) {

        let countryIDs = []
        let countryNames = []

        for (let i = 0; i < data.length; i++) {
            countryIDs.push(data[i].value)
        }

        for (let i = 0; i < data.length; i++) {
            countryNames.push(data[i].label)
        }

        this.setState({
            live_event_country_block_countries: data === null ? 0 : countryIDs, addAllCountriesInBlackOut: false
        });
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        this.setState({loading: true})

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/LiveEvent/" + handle;

        if (handle === 'new') {
            this.setState({loading: false})
            this.getPosition()
        } else {
            axios
                .get(url, axiosConfig)
                .then(res => {

                    const eventArray = res.data[0];
                    let local = moment.utc(eventArray.live_event_date).local();
                    let eventStatus = eventArray.live_event_status === 1
                    let eventFreeStatus = eventArray.live_event_free_status === 1
                    let blackOutStatus = eventArray.live_event_blackout_status === 1
                    let countryBlockStatus = eventArray.live_event_country_block_status === 1

                    let p2pStatus = eventArray.live_event_p2p_status === 1
                    // let webLoginStatus = eventArray.live_event_web_login_status === 1
                    let loginRequiredStatus = eventArray.live_event_login_required === 1
                    let castingStatus = eventArray.live_event_casting_status === 1
                    let timeshiftStatus = eventArray.live_event_timeshift_status === 1

                    let countries = []
                    if (eventArray.live_event_blackout_countries !== null) {
                        let tempCountry = eventArray.live_event_blackout_countries.toString();
                        countries = tempCountry.split(",")
                    }

                    let countriesBlocked = []
                    if (eventArray.live_event_country_block_countries !== null) {
                        let tempCountry = eventArray.live_event_country_block_countries.toString();
                        countriesBlocked = tempCountry.split(",")
                    }

                    let categoryIds = []
                    if (eventArray.live_event_categories !== null) {
                        let tempCategories = eventArray.live_event_categories.toString();
                        categoryIds = tempCategories.split(",")
                    }


                    this.setState({
                        back_button_text: this.props.t('global.back', {framework: "react-i18next"}),
                        eventArray,

                        eventStatus,
                        eventFreeStatus,
                        blackOutStatus,
                        countryBlockStatus,
                        p2pStatus,
                        // webLoginStatus,
                        castingStatus,
                        timeshiftStatus,
                        loginRequiredStatus,

                        loading: false,

                        live_event_id: eventArray.live_event_id,
                        live_event_status: eventStatus,
                        live_event_position: eventArray.live_event_position,
                        live_event_name_short: eventArray.live_event_name_short,
                        live_event_name_long: eventArray.live_event_name_long,
                        live_event_description: eventArray.live_event_description,
                        live_event_cat_id: eventArray.live_event_cat_id === null ? 0 : eventArray.live_event_cat_id,
                        live_event_rpgroup_id: eventArray.live_event_rpgroup_id,
                        live_event_url: eventArray.live_event_url,
                        live_event_date: local,
                        live_event_ref_id: eventArray.live_event_ref_id,
                        live_event_image: eventArray.live_event_image,
                        live_event_image_wide: eventArray.live_event_image_wide,
                        live_event_hits: eventArray.live_event_hits,
                        live_event_date_added: eventArray.live_event_date_added,
                        live_event_date_updated: eventArray.live_event_date_updated,
                        live_event_instagram: eventArray.live_event_instagram,
                        live_event_facebook: eventArray.live_event_facebook,
                        live_event_youtube: eventArray.live_event_youtube,
                        live_event_twitter: eventArray.live_event_twitter,
                        live_event_website: eventArray.live_event_website,
                        live_event_ppv_url: eventArray.live_event_ppv_url,
                        live_event_trailer_url: eventArray.live_event_trailer_url,
                        live_event_producer: eventArray.live_event_producer,
                        live_event_actors: eventArray.live_event_actors,
                        live_event_country: eventArray.live_event_country,
                        live_event_duration: eventArray.live_event_duration,
                        live_event_genre: eventArray.live_event_genre,
                        live_event_date_deleted: eventArray.live_event_date_deleted,
                        live_event_user_deleted: eventArray.live_event_user_deleted,
                        live_event_user_updated: eventArray.live_event_user_updated,
                        live_event_user_added: eventArray.live_event_user_added,
                        live_event_country_lock: eventArray.live_event_country_lock,
                        live_event_category: eventArray.live_event_category,
                        live_event_categories: categoryIds,
                        live_event_pre_roll: eventArray.live_event_pre_roll,
                        live_event_mid_roll: eventArray.live_event_mid_roll,
                        live_event_cat_name_short: eventArray.live_event_cat_name_short,
                        live_event_cat_image: eventArray.live_event_cat_image,
                        live_event_blackout_countries: countries,
                        live_event_blackout_url: eventArray.live_event_blackout_url,
                        live_event_country_block_countries: countriesBlocked,
                        live_event_showid: eventArray.live_event_showid,
                        live_event_seriesid: eventArray.live_event_seriesid,
                        live_event_ppv: eventArray.live_event_ppv,
                        live_event_dvr_duration: eventArray.live_event_dvr_duration,
                        live_event_billing_id: eventArray.live_event_billing_id,
                        user_content_provider: eventArray.live_event_content_provider,
                        current_user: user,
                        custom_data: eventArray.custom_data,
                        private_data: eventArray.private_data,
                        live_event_playback_status: eventArray.live_event_playback_status,
                        live_event_content_rating: eventArray.live_event_content_rating,
                        live_event_streaming_token: eventArray.live_event_streaming_token


                    });

                    // console.log(eventArray.live_event_showid);
                    // console.log(eventArray.live_event_seriesid);
                })
                .catch(error => {
                    //this.props.dispatch(userActions.logoutUnauthorized());
                    this.setState({loading: false})
                    console.log(error);
                });
        }


    }

    getPosition() {

        const url = process.env.REACT_APP_API_URL + "/LiveEvent/position";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios
            .get(url, axiosConfig)
            .then(res => {

                const liveEventPosition = res.data[0];

                this.setState({
                    live_event_position: liveEventPosition.LiveEventPosition

                });
            }).catch(error => {
            this.props.dispatch(userActions.logoutUnauthorized());
            console.log(error);
        });

    }

    handleChildCategorySelect(data) {

        // console.log(data);
        this.setState({
            live_event_cat_id: data === null ? 0 : data.value
        });
    }

    handleChildCategoryMultiSelect(data) {

        // console.log(data);
        // this.setState({
        //     live_event_categories: data === null ? 0 : data.value
        // });

        let categoryIDs = []

        for (let i = 0; i < data.length; i++) {
            categoryIDs.push(data[i].value)
        }

        // console.log(categoryIDs);

        this.setState({
            live_event_categories: data === null ? 0 : categoryIDs
        });
    }

    handleStreamingTokenChange = (streamingTokenDropDown) => {

        this.setState({
            live_event_streaming_token: streamingTokenDropDown === null ? null : streamingTokenDropDown.value
        });

    }


    handleChildPPVSelect(data) {

        //console.log(data);
        this.setState({
            live_event_rpgroup_id: data === null ? 0 : data.value
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleEventDate(date) {

        let m = moment(date)

        this.setState({
            live_event_date: m
        });

    };

    handleSwitch(elem, state) {

        if (elem.props.name === 'eventStatus') {
            this.setState({eventStatus: state})
        }

        if (elem.props.name === 'eventFreeStatusSwitch') {
            this.setState({eventFreeStatus: state})
        }

        if (elem.props.name === 'blackOutSwitch') {
            this.setState({blackOutStatus: state})
        }

        if (elem.props.name === 'countryBlockSwitch') {
            this.setState({countryBlockStatus: state})
        }

        if (elem.props.name === 'p2pSwitch') {
            this.setState({p2pStatus: state})
        }

        // if (elem.props.name === 'webLoginSwitch') {
        //     this.setState({webLoginStatus: state})
        // }

        if (elem.props.name === 'castingSwitch') {
            this.setState({castingStatus: state})
        }

        if (elem.props.name === 'timeshiftSwitch') {
            this.setState({timeshiftStatus: state})
        }

        if (elem.props.name === 'loginRequiredStatusSwitch') {
            this.setState({loginRequiredStatus: state})
        }
    }

    submitWideImage(filename_wide) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.live_event_image_wide_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.live_event_image_wide_folder_temp);
            bodyFormData.set('location', "live_events");
            bodyFormData.set('filename', filename_wide);

            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({live_event_image_wide_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    submitPosterImage(filename_poster) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.live_event_image_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.live_event_image_folder_temp);
            bodyFormData.set('location', "live_events");
            bodyFormData.set('filename', filename_poster);


            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({live_event_image_temp: false})
                })
                .catch((err) => {
                    console.log("live_event - AXIOS ERROR: ", err);
                })
        }

    }

    updateEvent() {

        this.setState({loading: true})
        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/LiveEvent/" + handle;

        let live_event_status = this.state.eventStatus === true ? 1 : 0;
        let live_event_free_status = this.state.eventFreeStatus === true ? 1 : 0;
        let m = moment.utc(this.state.live_event_date).format('YYYY-MM-DD HH:mm:ss').toString();

        //RENAME FUNCTIONS START POSTER WIDE
        let filename_wide = ""

        if (this.state.live_event_image_wide_temp) {
            let timeUTC = new Date().getTime()
            filename_wide = timeUTC + "_" + this.state.live_event_image_wide
            this.setState({live_event_image_wide: filename_wide})
            //console.log("*****" + filename_wide)
            this.submitWideImage(filename_wide);
        } else {
            filename_wide = this.state.live_event_image_wide
        }

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""

        if (this.state.live_event_image_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_" + this.state.live_event_image
            this.setState({live_event_image: filename_poster})
            //console.log("*****" + filename_poster)
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.live_event_image
        }

        // Send a PUT request
        var postData = {
            event: {
                live_event_id: this.state.live_event_id,
                live_event_status: live_event_status,
                live_event_free_status: live_event_free_status,
                live_event_position: this.state.live_event_position,
                live_event_name_short: this.state.live_event_name_short,
                live_event_name_long: this.state.live_event_name_long,
                live_event_description: this.state.live_event_description,
                live_event_cat_id: this.state.live_event_cat_id,
                live_event_rpgroup_id: this.state.live_event_rpgroup_id,
                live_event_url: this.state.live_event_url,
                live_event_date: m,
                live_event_ref_id: this.state.live_event_ref_id,
                live_event_image: filename_poster,
                live_event_image_wide: filename_wide,
                live_event_hits: this.state.live_event_hits,
                live_event_date_added: this.state.live_event_date_added,
                live_event_date_updated: this.state.live_event_date_updated,
                live_event_instagram: this.state.live_event_instagram,
                live_event_facebook: this.state.live_event_facebook,
                live_event_youtube: this.state.live_event_youtube,
                live_event_twitter: this.state.live_event_twitter,
                live_event_website: this.state.live_event_website,
                live_event_ppv_url: this.state.live_event_ppv_url,
                live_event_trailer_url: this.state.live_event_trailer_url,

                live_event_producer: this.state.live_event_producer,
                live_event_actors: this.state.live_event_actors,
                live_event_country: this.state.live_event_country,
                live_event_duration: this.state.live_event_duration,
                live_event_genre: this.state.live_event_genre,
                live_event_date_deleted: this.state.live_event_date_deleted,
                live_event_user_deleted: this.state.live_event_user_deleted,
                live_event_user_updated: this.state.live_event_user_updated,
                live_event_user_added: this.state.live_event_user_added,
                live_event_country_lock: this.state.live_event_country_lock,
                live_event_category: this.state.live_event_category,
                live_event_pre_roll: this.state.live_event_pre_roll,
                live_event_mid_roll: this.state.live_event_mid_roll,
                live_event_cat_name_short: this.state.live_event_cat_name_short,
                live_event_cat_image: this.state.live_event_cat_image,
                live_event_categories: this.state.live_event_categories === null ? null : this.state.live_event_categories.join(),
                live_event_blackout_countries: this.state.live_event_blackout_countries === null ? null : this.state.live_event_blackout_countries.join(),
                live_event_blackout_url: this.state.live_event_blackout_url,
                live_event_blackout_status: this.state.blackOutStatus === true ? 1 : 0,
                live_event_country_block_status: this.state.countryBlockStatus === true ? 1 : 0,
                live_event_country_block_countries: this.state.live_event_country_block_countries === null ? null : this.state.live_event_country_block_countries.join(),
                live_event_p2p_status: this.state.p2pStatus === true ? 1 : 0,
                // live_event_web_login_status: this.state.webLoginStatus === true ? 1 : 0,
                live_event_login_required: this.state.loginRequiredStatus === true ? 1 : 0,
                live_event_casting_status: this.state.castingStatus === true ? 1 : 0,
                live_event_timeshift_status: this.state.timeshiftStatus === true ? 1 : 0,
                live_event_dvr_duration: this.state.live_event_dvr_duration,
                live_event_billing_id: this.state.live_event_billing_id,
                live_event_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data,
                private_data: this.state.private_data,
                live_event_playback_status: this.state.live_event_playback_status,
                live_event_content_rating: this.state.live_event_content_rating,
                live_event_streaming_token: this.state.live_event_streaming_token

            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                    const event = res.data;
                    this.setState({loading: false})

                    if (res.data.error === 301) {

                        this.setState({
                            live_event_name_short_message: res.data.message,
                            live_event_name_short_validate_state: "error",
                            live_event_name_long_message: "",
                            live_event_name_long_validate_state: null
                        })
                        this.notification("failed")

                    } else if (res.data.error === 302) {

                        this.setState({
                            live_event_name_short_message: res.data.message,
                            live_event_name_short_validate_state: "error",
                            live_event_name_long_message: "",
                            live_event_name_long_validate_state: null
                        })
                        this.notification("failed")

                    } else if (res.data.error === 303) {

                        this.setState({
                            live_event_name_short_message: res.data.message,
                            live_event_name_short_validate_state: "error",
                            live_event_name_long_message: "",
                            live_event_name_long_validate_state: null
                        })
                        this.notification("failed")

                    } else if (res.data.error === 304) {

                        this.setState({
                            live_event_name_long_message: res.data.message,
                            live_event_name_long_validate_state: "error",
                            live_event_name_short_message: "",
                            live_event_name_short_validate_state: null
                        })
                        this.notification("failed")

                    } else {

                        this.setState({live_event_id: event.live_event_id})
                        this.notification("success")
                    }

                }
            )
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({loading: false})
                this.notification("failed")
            })

    }

    createEvent() {


        this.setState({loading: true})
        const url = process.env.REACT_APP_API_URL + "/LiveEvent";

        let live_event_status = this.state.eventStatus === true ? 1 : 0;
        let live_event_free_status = this.state.eventFreeStatus === true ? 1 : 0;
        let m = moment.utc(this.state.live_event_date).format('YYYY-MM-DD HH:mm:ss').toString();

        //RENAME FUNCTIONS START POSTER WIDE
        let filename_wide = ""

        if (this.state.live_event_image_wide_temp) {
            let timeUTC = new Date().getTime()
            filename_wide = timeUTC + "_" + this.state.live_event_image_wide
            this.setState({live_event_image_wide: filename_wide})
            //console.log("*****" + filename_wide)
            this.submitWideImage(filename_wide);
        } else {
            filename_wide = this.state.live_event_image_wide
        }

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""

        if (this.state.live_event_image_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_" + this.state.live_event_image
            this.setState({live_event_image: filename_poster})
            //console.log("*****" + filename_poster)
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.live_event_image
        }

        var postData = {
            event: {
                live_event_status: live_event_status,
                live_event_free_status: live_event_free_status,
                live_event_position: this.state.live_event_position,
                live_event_name_short: this.state.live_event_name_short,
                live_event_name_long: this.state.live_event_name_long,
                live_event_description: this.state.live_event_description,
                live_event_cat_id: this.state.live_event_cat_id,
                live_event_rpgroup_id: this.state.live_event_rpgroup_id,
                live_event_url: this.state.live_event_url,
                live_event_date: m,
                live_event_ref_id: this.state.live_event_ref_id,
                live_event_image: filename_poster,
                live_event_image_wide: filename_wide,
                live_event_hits: this.state.live_event_hits,
                live_event_date_added: this.state.live_event_date_added,
                live_event_date_updated: this.state.live_event_date_updated,
                live_event_instagram: this.state.live_event_instagram,
                live_event_facebook: this.state.live_event_facebook,
                live_event_youtube: this.state.live_event_youtube,
                live_event_twitter: this.state.live_event_twitter,
                live_event_website: this.state.live_event_website,
                live_event_ppv_url: this.state.live_event_ppv_url,
                live_event_trailer_url: this.state.live_event_trailer_url,

                live_event_producer: this.state.live_event_producer,
                live_event_actors: this.state.live_event_actors,
                live_event_country: this.state.live_event_country,
                live_event_duration: this.state.live_event_duration,
                live_event_genre: this.state.live_event_genre,
                live_event_date_deleted: this.state.live_event_date_deleted,
                live_event_user_deleted: this.state.live_event_user_deleted,
                live_event_user_updated: this.state.live_event_user_updated,
                live_event_user_added: this.state.live_event_user_added,
                live_event_country_lock: this.state.live_event_country_lock,
                live_event_category: this.state.live_event_category,
                live_event_pre_roll: this.state.live_event_pre_roll,
                live_event_mid_roll: this.state.live_event_mid_roll,
                live_event_cat_name_short: this.state.live_event_cat_name_short,
                live_event_cat_image: this.state.live_event_cat_image,
                live_event_categories: this.state.live_event_categories === null ? null : this.state.live_event_categories.join(),
                live_event_blackout_countries: this.state.live_event_blackout_countries === null ? null : this.state.live_event_blackout_countries.join(),
                live_event_blackout_url: this.state.live_event_blackout_url,
                live_event_blackout_status: this.state.blackOutStatus === true ? 1 : 0,
                live_event_country_block_status: this.state.countryBlockStatus === true ? 1 : 0,
                live_event_country_block_countries: this.state.live_event_country_block_countries === null ? null : this.state.live_event_country_block_countries.join(),
                live_event_p2p_status: this.state.p2pStatus === true ? 1 : 0,
                // live_event_web_login_status: this.state.webLoginStatus === true ? 1 : 0,
                live_event_login_required: this.state.loginRequiredStatus === true ? 1 : 0,
                live_event_casting_status: this.state.castingStatus === true ? 1 : 0,
                live_event_timeshift_status: this.state.timeshiftStatus === true ? 1 : 0,
                live_event_dvr_duration: this.state.live_event_dvr_duration,
                live_event_billing_id: this.state.live_event_billing_id,
                live_event_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data,
                private_data: this.state.private_data,
                live_event_playback_status: this.state.live_event_playback_status,
                live_event_content_rating: this.state.live_event_content_rating,
                live_event_streaming_token: this.state.live_event_streaming_token
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {

                this.setState({saving: false})
                this.setState({loading: false})
                const event = res.data;

                if (res.data.error === 301) {
                    this.setState({
                        live_event_name_short_message: res.data.message,
                        live_event_name_short_validate_state: "error",
                        live_event_name_long_message: "",
                        live_event_name_long_validate_state: null
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        live_event_name_short_message: res.data.message,
                        live_event_name_short_validate_state: "error",
                        live_event_name_long_message: "",
                        live_event_name_long_validate_state: null
                    })
                    this.notification("failed")
                } else if (res.data.error === 303) {
                    this.setState({
                        live_event_name_short_message: res.data.message,
                        live_event_name_short_validate_state: "error",
                        live_event_name_long_message: "",
                        live_event_name_long_validate_state: null
                    })
                    this.notification("failed")
                } else if (res.data.error === 304) {
                    this.setState({
                        live_event_name_long_message: res.data.message,
                        live_event_name_long_validate_state: "error",
                        live_event_name_short_message: "",
                        live_event_name_short_validate_state: null
                    })
                    this.notification("failed")
                } else {
                    const channel = res.data;
                    this.setState({
                            channel_id: channel.channel_id,
                            live_event_name_short_message: "",
                            live_event_name_short_validate_state: null,
                            live_event_name_long_message: "",
                            live_event_name_long_validate_state: null
                        }
                    )

                    this.setState({live_event_id: event.live_event_id})
                    this.notification("success")
                    this.setEventRedirect();
                }

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({loading: false})
            })

    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Event has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update Event</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    handleChildImageSelect(folder, filename, imagePosition) {

        // console.log("" + folder);
        // console.log("" + filename);
        // console.log("" + imagePosition);
        // console.log(process.env.REACT_APP_ASSET_URL + "/tmp/" + folder + "/" + filename);

        if (imagePosition === "live_event_image") {
            this.setState({
                live_event_image_temp: filename !== null,
                live_event_image: filename,
                live_event_image_folder_temp: folder
            });
        }
        if (imagePosition === "live_event_image_wide") {
            this.setState({
                live_event_image_wide_temp: filename !== null,
                live_event_image_wide: filename,
                live_event_image_wide_folder_temp: folder
            });
        }


    }

    cancelEventEdit() {
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    setEventRedirect = () => {
        this.setState({
            eventRedirect: true,
            back_button_text: this.props.t('global.back', {framework: "react-i18next"})
        })
    }

    renderRedirect = () => {

        let path = "/live/events";
        let pathEvent = "/edit-event/" + this.state.live_event_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.eventRedirect) {
            //console.log(pathEvent);
            return <Redirect push to={pathEvent}/>
        }

    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({saving: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createEvent();
            } else {
                this.updateEvent();
            }
        }
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Live Event: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.live_event_id}
            />
        }
    }

    renderDateTimeFields = () => {

        if (this.state.live_event_ppv === 1) {
            return (
                <div>
                </div>
            )
        } else {
            return (
                <FormGroup>
                    <p>{this.props.t('events_edit.live_event_date', {framework: "react-i18next"})}</p>
                    <Datetime
                        name={"live_event_date"}
                        disabled={this.state.loading}
                        value={this.state.live_event_date}
                        onChange={this.handleEventDate}
                        inputProps={{placeholder: "Event Data and Time"}}
                    />
                </FormGroup>
            )
        }

    }

    onClickAddAllCountriesCountryBlock() {
        this.setState({addAllCountriesInCountryBlock: true})
    }

    onClickAddAllCountriesBlackOut() {
        this.setState({addAllCountriesInBlackOut: true})
    }

    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <ControlLabel>Content Provider</ControlLabel>
                                <DropDownContentProviders
                                    contentProviderSelected={this.state.user_content_provider}
                                    name='contentProviderSelect'
                                    handlerFromParent={this.handleChildContentProviderSelect}
                                    disabled={this.state.loading}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                        </Col>
                    </Row>
                )
            }
        }

    }

    render() {
        return (
            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Grid fluid>
                    <Row>
                        <Col md={8}>
                            <Card
                                content={
                                    <div>
                                        <h5>Event Information</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={2}>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('events_edit.eventStatus', {framework: "react-i18next"})}</ControlLabel>
                                                            <br/>
                                                            <Switch
                                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                                                name='eventStatus'
                                                                disabled={this.state.loading}
                                                                value={this.state.eventStatus}
                                                                defaultValue={false}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={2}>
                                                        <FormGroup>
                                                            <ControlLabel>Free Event</ControlLabel>
                                                            <br/>
                                                            <Switch
                                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                                                name='eventFreeStatusSwitch'
                                                                disabled={this.state.loading}
                                                                value={this.state.eventFreeStatus}
                                                                defaultValue={false}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                </Row>

                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>Event Status</ControlLabel>
                                                        <Select
                                                            value={this.state.live_event_playback_status}
                                                            disabled={this.state.loading}
                                                            onChange={this.handleEventStatusChange}
                                                            options={eventStatusArray}
                                                        />
                                                    </FormGroup>
                                                </Row>

                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>Content Rating</ControlLabel>
                                                        <DropDownRating
                                                            ratingSelected={this.state.live_event_content_rating}
                                                            name='ratingSelect'
                                                            handlerFromParent={this.handleChildRatingSelect}
                                                        />
                                                    </FormGroup>
                                                </Row>

                                                <Row>
                                                    {this.renderDateTimeFields()}
                                                </Row>


                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('events_edit.live_event_position', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('events_edit.live_event_position_placeholder', {framework: "react-i18next"})}
                                                            type="number"
                                                            name="live_event_position"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.live_event_position || ""}
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup
                                                        controlId="formValidationShortName"
                                                        validationState={this.state.live_event_name_short_validate_state}
                                                    >
                                                        <ControlLabel>{this.props.t('events_edit.live_event_name_short', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('events_edit.live_event_name_short_placeholder', {framework: "react-i18next"})}
                                                            type="text"
                                                            name="live_event_name_short"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.live_event_name_short || ""}
                                                        />
                                                        <HelpBlock>{this.state.live_event_name_short_message}</HelpBlock>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup
                                                        controlId="formValidationLongName"
                                                        validationState={this.state.live_event_name_long_validate_state}
                                                    >
                                                        <ControlLabel>{this.props.t('events_edit.live_event_name_long', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('events_edit.live_event_name_long_placeholder', {framework: "react-i18next"})}
                                                            type="text"
                                                            name="live_event_name_long"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.live_event_name_long || ""}
                                                        />
                                                        <HelpBlock>{this.state.live_event_name_long_message}</HelpBlock>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('events_edit.live_event_description', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('events_edit.live_event_description_placeholder', {framework: "react-i18next"})}
                                                            type="text"
                                                            componentClass="textarea"
                                                            rows={3}
                                                            name="live_event_description"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.live_event_description || ""}
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                {/*<Row>*/}
                                                {/*    <FormGroup>*/}
                                                {/*        <ControlLabel>{this.props.t('events_edit.live_event_cat_id', {framework: "react-i18next"})}</ControlLabel>*/}
                                                {/*        <DropDownEventCategory*/}
                                                {/*            categorySelected={this.state.live_event_cat_id}*/}
                                                {/*            name='categorySelect'*/}
                                                {/*            disabled={this.state.loading}*/}
                                                {/*            handlerFromParent={this.handleChildCategorySelect}*/}
                                                {/*        />*/}
                                                {/*    </FormGroup>*/}
                                                {/*</Row>*/}
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('events_edit.live_event_cat_id', {framework: "react-i18next"})}</ControlLabel>
                                                        <DropDownEventMultiCategory
                                                            categorySelected={this.state.live_event_categories}
                                                            name='categoryMultiSelect'
                                                            disabled={this.state.loading}
                                                            handlerFromParent={this.handleChildCategoryMultiSelect}
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('events_edit.live_event_rpgroup_id', {framework: "react-i18next"})}</ControlLabel>
                                                        <DropDownPayPerViewPriceList
                                                            categorySelected={this.state.live_event_rpgroup_id}
                                                            name='ppvSelect'
                                                            disabled={this.state.loading}
                                                            handlerFromParent={this.handleChildPPVSelect}
                                                        />
                                                    </FormGroup>
                                                </Row>

                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('events_edit.live_event_url', {framework: "react-i18next"})}</ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Button>
                                                                <Button fill default><i
                                                                    className="fa fa-play"/></Button>
                                                            </InputGroup.Button>
                                                            <FormControl
                                                                placeholder={this.props.t('events_edit.live_event_url_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                name="live_event_url"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.live_event_url || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>

                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('events_edit.live_event_trailer_url', {framework: "react-i18next"})}</ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Button>
                                                                <Button fill default><i
                                                                    className="fa fa-play"/></Button>
                                                            </InputGroup.Button>
                                                            <FormControl
                                                                placeholder={this.props.t('events_edit.live_event_trailer_url_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                name="live_event_trailer_url"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.live_event_trailer_url || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>

                                                </Row>


                                            </Col>
                                        </Grid>
                                    </div>
                                }
                            />

                            <Card
                                content={
                                    <div>
                                        <h5>Other Settings</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Row>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <ControlLabel>P2P</ControlLabel>
                                                        <br/>
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            name='p2pSwitch'
                                                            disabled={this.state.loading}
                                                            value={this.state.p2pStatus}
                                                            defaultValue={false}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <ControlLabel>Require Login</ControlLabel>
                                                        <br/>
                                                        {/*<Switch*/}
                                                        {/*    onChange={(el, state) => this.handleSwitch(el, state)}*/}
                                                        {/*    name='webLoginSwitch'*/}
                                                        {/*    disabled={this.state.loading}*/}
                                                        {/*    value={this.state.webLoginStatus}*/}
                                                        {/*    defaultValue={false}*/}
                                                        {/*/>*/}
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            name='loginRequiredStatusSwitch'
                                                            disabled={this.state.loading}
                                                            value={this.state.loginRequiredStatus}
                                                            defaultValue={false}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <ControlLabel>Casting</ControlLabel>
                                                        <br/>
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            name='castingSwitch'
                                                            disabled={this.state.loading}
                                                            value={this.state.castingStatus}
                                                            defaultValue={false}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <ControlLabel>Time shift</ControlLabel>
                                                        <br/>
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            name='timeshiftSwitch'
                                                            disabled={this.state.loading}
                                                            value={this.state.timeshiftStatus}
                                                            defaultValue={false}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <ControlLabel>DVR Duration (Minutes)</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('events_edit.live_event_name_short_placeholder', {framework: "react-i18next"})}
                                                            type="number"
                                                            name="live_event_dvr_duration"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.live_event_dvr_duration || ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <ControlLabel>Billing Product ID</ControlLabel>
                                                        <FormControl
                                                            placeholder="Billing Product ID"
                                                            type="text"
                                                            name="live_event_billing_id"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.live_event_billing_id || ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            {this.renderContentProvider()}

                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <ControlLabel>Token Authentication</ControlLabel>
                                                        <br/>
                                                        <FormGroup>
                                                            <Select
                                                                value={this.state.live_event_streaming_token}
                                                                onChange={this.handleStreamingTokenChange}
                                                                options={tokenSelection}
                                                            />
                                                        </FormGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                        </Grid>
                                    </div>
                                }
                            />

                            <Card
                                content={
                                    <div>
                                        <h5>Schedule</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Col md={12}>
                                                <Row>
                                                    <Calendar
                                                        eventType={this.state.live_event_ppv === 1 ? "ppv" : "local"}
                                                        seriesId={this.state.live_event_seriesid}
                                                        showId={this.state.live_event_showid}
                                                    />
                                                </Row>
                                            </Col>
                                        </Grid>
                                    </div>
                                }
                            />

                            <Card
                                content={
                                    <div>
                                        <h5>Other information</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('events_edit.live_event_producer', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('events_edit.live_event_producer_placeholder', {framework: "react-i18next"})}
                                                            type="text"
                                                            name="live_event_producer"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.live_event_producer || ""}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('events_edit.live_event_duration', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('events_edit.live_event_duration_placeholder', {framework: "react-i18next"})}
                                                            type="number"
                                                            name="live_event_duration"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.live_event_duration || 0}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('events_edit.live_event_cat_name_short', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('events_edit.live_event_cat_name_short_placeholder', {framework: "react-i18next"})}
                                                            type="text"
                                                            name="live_event_cat_name_short"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.live_event_cat_name_short || ""}
                                                        />
                                                    </FormGroup>

                                                </Col>

                                                <Col md={6}>

                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('events_edit.live_event_actors', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('events_edit.live_event_actors_placeholder', {framework: "react-i18next"})}
                                                            type="text"
                                                            name="live_event_actors"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.live_event_actors || ""}
                                                        />
                                                    </FormGroup>


                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('events_edit.live_event_genre', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('events_edit.live_event_genre_placeholder', {framework: "react-i18next"})}
                                                            type="text"
                                                            name="live_event_genre"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.live_event_genre || ""}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('events_edit.live_event_country', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('events_edit.live_event_country_placeholder', {framework: "react-i18next"})}
                                                            type="text"
                                                            name="live_event_country"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.live_event_country || ""}
                                                        />
                                                    </FormGroup>

                                                </Col>
                                            </Row>
                                        </Grid>
                                    </div>
                                }
                            />

                            <Card
                                content={
                                    <div>
                                        <h5>Blackout Settings</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Col md={12}>
                                                <Row>
                                                    <FormGroup>
                                                        {/*<p className="category">{this.props.t('channels_edit.channel_blackout_status', {framework: "react-i18next"})}</p>*/}
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            name='blackOutSwitch'
                                                            disabled={this.state.loading}
                                                            value={this.state.blackOutStatus}
                                                            defaultValue={false}
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel
                                                            className="category">{this.props.t('channels_edit.channel_blackout_countries', {framework: "react-i18next"})}</ControlLabel>
                                                        <DropDownCountries
                                                            countrySelected={this.state.live_event_blackout_countries}
                                                            name='blackOutSelect'
                                                            handlerFromParent={this.handleChildCountrySelect}
                                                            hadlerAddAllCountries={this.state.addAllCountriesInBlackOut}
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <Button
                                                            onClick={this.onClickAddAllCountriesBlackOut.bind(this)}
                                                        >
                                                            Add All countries
                                                        </Button>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('channels_edit.channel_blackout_url', {framework: "react-i18next"})}</ControlLabel>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={this.props.t('channels_edit.channel_blackout_url_placeholder', {framework: "react-i18next"})}
                                                            onChange={this.handleInputChange}
                                                            name="live_event_blackout_url"
                                                            disabled={this.state.loading}
                                                            value={this.state.live_event_blackout_url || ""}
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <Well> <Glyphicon glyph="question-sign"/> By enabling the Blackout
                                                        functionality any countries added in the "Blackout Country list"
                                                        field will be redirected to the "Blackout URL"
                                                    </Well>
                                                </Row>
                                            </Col>
                                        </Grid>
                                    </div>
                                }
                            />

                            <Card
                                content={
                                    <div>
                                        <h5>Country Block Settings</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Col md={12}>
                                                <Row>
                                                    <FormGroup>
                                                        {/*<p className="category">Country Block</p>*/}
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            name='countryBlockSwitch'
                                                            disabled={this.state.loading}
                                                            value={this.state.countryBlockStatus}
                                                            defaultValue={false}
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel className="category">Countries to
                                                            Block</ControlLabel>
                                                        <DropDownCountries
                                                            countrySelected={this.state.live_event_country_block_countries}
                                                            name='countryBlockSelect'
                                                            handlerFromParent={this.handleChildBlockCountrySelect}
                                                            hadlerAddAllCountries={this.state.addAllCountriesInCountryBlock}
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <Button
                                                            onClick={this.onClickAddAllCountriesCountryBlock.bind(this)}
                                                        >
                                                            Add All countries
                                                        </Button>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <Well> <Glyphicon glyph="question-sign"/> By enabling the Country
                                                        Block
                                                        functionality,the live event will be hidden for the countries
                                                        specified.
                                                    </Well>
                                                </Row>
                                            </Col>
                                        </Grid>
                                    </div>
                                }
                            />

                            <Card
                                content={
                                    <div>
                                        <h5>External Links</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Col md={12}>
                                                <Row>
                                                    <FormGroup>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "#c4232f"}}>
                                                                <i className="fa fa-instagram"
                                                                   style={{color: "#ffffff"}}/>
                                                            </InputGroup.Addon>
                                                            <FormControl
                                                                placeholder={this.props.t('events_edit.live_event_instagram_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                name="live_event_instagram"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.live_event_instagram || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "#3e5591"}}>
                                                                <i className="fa fa-facebook"
                                                                   style={{color: "#ffffff"}}/>
                                                            </InputGroup.Addon>
                                                            <FormControl
                                                                placeholder={this.props.t('events_edit.live_event_facebook_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                name="live_event_facebook"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.live_event_facebook || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "#df2a2f"}}>
                                                                <i className="fa fa-youtube"
                                                                   style={{color: "#ffffff"}}/>
                                                            </InputGroup.Addon>
                                                            <FormControl
                                                                placeholder={this.props.t('events_edit.live_event_youtube_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                name="live_event_youtube"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.live_event_youtube || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "#55a3ec"}}>
                                                                <i className="fa fa-twitter"
                                                                   style={{color: "#ffffff"}}/>
                                                            </InputGroup.Addon>
                                                            <FormControl
                                                                placeholder={this.props.t('events_edit.live_event_twitter_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                name="live_event_twitter"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.live_event_twitter || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>

                                                <Row>
                                                    <FormGroup>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "#3a465a"}}>
                                                                <i className="fa fa-globe" style={{color: "#ffffff"}}/>
                                                            </InputGroup.Addon>
                                                            <FormControl
                                                                placeholder={this.props.t('events_edit.live_event_website_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                name="live_event_website"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.live_event_website || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>

                                                <Row>
                                                    <FormGroup>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "#383838"}}>
                                                                <i className="fa fa-dollar" style={{color: "#ffffff"}}/>
                                                            </InputGroup.Addon>
                                                            <FormControl
                                                                placeholder={this.props.t('events_edit.live_event_ppv_url_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                name="live_event_ppv_url"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.live_event_ppv_url || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>
                                            </Col>
                                        </Grid>
                                    </div>
                                }
                            />

                            <Card
                                content={
                                    <div>
                                        <h5>Vast Configuration</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Col md={12}>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('events_edit.live_event_pre_roll', {framework: "react-i18next"})}</ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                                                <Glyphicon glyph="object-align-right"/>
                                                            </InputGroup.Addon>
                                                            <FormControl
                                                                placeholder={this.props.t('events_edit.live_event_pre_roll_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                name="live_event_pre_roll"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.live_event_pre_roll || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('events_edit.live_event_mid_roll', {framework: "react-i18next"})}</ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                                                <Glyphicon glyph="object-align-vertical"/>
                                                            </InputGroup.Addon>
                                                            <FormControl
                                                                placeholder={this.props.t('events_edit.live_event_mid_roll_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                name="live_event_mid_roll"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.live_event_mid_roll || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>POST ROLL</ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                                                <Glyphicon glyph="object-align-left"/>
                                                            </InputGroup.Addon>
                                                            <FormControl
                                                                placeholder={this.props.t('events_edit.live_event_mid_roll_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                name="live_event_post_roll"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.live_event_post_roll || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <Well> <Glyphicon glyph="question-sign"/> A VAST (Video Ad Serving
                                                        Template)
                                                        tag is a HTTP or HTTPS URL that points to a
                                                        VAST XML feed. The VAST XML URL feed contains information about
                                                        the
                                                        video ad,
                                                        including the video file, length, companion banner and more.
                                                    </Well>
                                                </Row>
                                            </Col>
                                        </Grid>
                                    </div>
                                }
                            />

                        </Col>

                        <Col md={4}>
                            <Row>
                                <Card
                                    tableFullWidth
                                    content={
                                        <div id="out-test" className="text-center center center-block">
                                            <div id="test" style={{textAlignLast: "center"}}>
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() => this.setState({showSaveModal: true})}
                                                        bsStyle="info"
                                                        fill wd
                                                        disabled={this.state.loading}
                                                        //type="submit"
                                                        name={"saveButton"}>
                                                        {this.props.t('global.save', {framework: "react-i18next"})}
                                                    </Button>
                                                    <Button bsStyle="default" fill wd
                                                            name={"cancelButton"}
                                                            disabled={this.state.loading}
                                                            onClick={this.cancelEventEdit.bind(this)}>
                                                        {this.state.back_button_text}
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>

                                    }
                                />
                            </Row>

                            <Row>
                                <Card
                                    content={
                                        <div>
                                            <h5>Assets</h5>
                                            <legend/>
                                            <Grid fluid>
                                                <Col md={12}>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('events_edit.live_event_image', {framework: "react-i18next"})}</ControlLabel>
                                                            <ImageUpload
                                                                imageName={this.state.live_event_image}
                                                                imageType="live_events"
                                                                imagePosition={"live_event_image"}
                                                                imageTemp={this.state.live_event_image_temp}
                                                                imageFolderTemp={this.state.live_event_image_folder_temp}
                                                                handlerFromParent={this.handleChildImageSelect}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('events_edit.live_event_image_wide', {framework: "react-i18next"})}</ControlLabel>
                                                            <ImageUpload
                                                                imageName={this.state.live_event_image_wide}
                                                                imageType="live_events"
                                                                imagePosition={"live_event_image_wide"}
                                                                imageTemp={this.state.live_event_image_wide_temp}
                                                                imageFolderTemp={this.state.live_event_image_wide_folder_temp}
                                                                handlerFromParent={this.handleChildImageSelect}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                </Col>
                                            </Grid>
                                        </div>
                                    }
                                />
                            </Row>
                        </Col>
                    </Row>
                </Grid>

            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedEditLiveEvents = connect(mapStateToProps)(EditLiveEvents);
const connectedEditLiveEventsTrans = withNamespaces('common')(connectedEditLiveEvents);
export {connectedEditLiveEventsTrans as EditLiveEvents};
