import React, {Component} from "react";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Modal, Row} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import {withNamespaces} from "react-i18next";
import {connect} from 'react-redux';
import axios from "axios";
import Switch from "react-bootstrap-switch";
import {userActions} from "../../authentication/_actions";
import ImageUpload from "../ImageUpload/ImageUpload";
import Select from "react-select";
import {DropDownCountries} from "../CustomDropDown/DropDownCountries";
import {DropDownContentProviders} from "../CustomDropDown/DropDownContentProviders";

const MainTypeOptions = [
    {value: 'CATEGORIES', label: 'Categories'},
    {value: 'SUB_MENU_ITEMS', label: 'Sub Menu'},
];

const LayoutOptions = [
    {value: 'Feature_Categories_Type_1', label: 'Feature_Categories_Type_1'},
    {value: 'Feature_Categories_Type_2', label: 'Feature_Categories_Type_2'},
    {value: 'Feature_Categories_Type_3', label: 'Feature_Categories_Type_3'},
    {value: 'TV_Layout_1', label: 'TV_Layout_1'},
    {value: 'TV_Layout_2', label: 'TV_Layout_2'},
    {value: 'TV_Layout_3', label: 'TV_Layout_3'},
    {value: 'Sports', label: 'Sports'},
    {value: 'Package_Management', label: 'Package Management'},
    {value: 'Radio', label: 'Radio'},
    {value: 'Enter_Code', label: 'Enter Code'},
    {value: 'View_Code_Package_Status', label: 'View Code Package Status'},
    {value: 'Browse_Code_Packages', label: 'Browse Code Packages'},
    {value: 'search', label: 'Search'},
    {value: 'External_URL', label: 'External URL'},
    {value: 'Settings', label: 'Settings'},
    {value: 'My_Account', label: 'My Account'},
    {value: 'Log_Out', label: 'Log Out'},
];

const DeviceTypeOptions = [
    {value: 'android_mobile', label: 'Android'},
    {value: 'android_tv', label: 'Android TV'},
    {value: 'ios_mobile', label: 'iOS'},
    {value: 'roku', label: 'Roku'},
    {value: 'web', label: 'Web'},
];

const adminArray = ['super-admin', 'admin']

class ModalApplicationDeviceMenuItem extends Component {

    constructor(props) {
        super(props);

        this.state = {

            showModalCustomerDevice: true,

            statusSwitch: false,

            id: null,
            menu_item_category_id: null,
            menu_item_device_type: null,
            menu_item_name: null,
            menu_item_position: null,
            menu_item_poster: null,
            menu_item_status: null,
            menu_item_type: null,
            menu_item_content_type: null,
            menu_item_layout: null,
            menu_item_colour: null,
            menu_item_content_provider: null,
            menu_item_poster_temp: false,
            menu_item_poster_folder_temp: "",
            menu_item_external_url: "",
            menu_item_device_type_multi: null,
            menu_item_block_countries: null,
            addAllCountriesInBlackList: false,
            current_user: null,


        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleMenuItemImageSelect = this.handleMenuItemImageSelect.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleDeviceTypeChange = this.handleDeviceTypeChange.bind(this);
        this.handleChildCountrySelect = this.handleChildCountrySelect.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);

    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                menu_item_content_provider: data === null ? null : data.value,
            });
        }
    }

    handleChildCountrySelect(data) {

        let countryIDs = []
        let countryNames = []

        for (let i = 0; i < data.length; i++) {
            countryIDs.push(data[i].value)
        }

        for (let i = 0; i < data.length; i++) {
            countryNames.push(data[i].label)
        }

        //console.log(data);
        this.setState({
            menu_item_block_countries: data === null ? 0 : countryIDs, addAllCountriesInBlackList: false
        });
    }

    onClickAddAllCountriesInBlackList() {

        this.setState({addAllCountriesInBlackList: true})

    }


    handleTypeChange = (menuTypeDropDown) => {

        this.setState({
            menu_item_type: menuTypeDropDown === null ? null : menuTypeDropDown.value
        });

    }

    handleLayoutChange = (type) => {

        this.setState({
            menu_item_layout: type === null ? null : type.value
        });
        console.log(`Option selected:`, type);


    }

    handleDeviceTypeChange = (data) => {

        console.log(`Option selected:`, data);

        let deviceTypeIds = []

        for (let i = 0; i < data.length; i++) {
            deviceTypeIds.push(data[i].value)
        }

        this.setState({
            menu_item_device_type_multi: data === null ? 0 : deviceTypeIds
        });

    }

    handleMenuItemImageSelect(folder, filename, imagePosition) {

        //console.log("" + folder);
        //console.log("" + filename);
        //console.log(process.env.REACT_APP_ASSET_URL + "/tmp/" + folder + "/" + filename);

        if (imagePosition === "menu_item_poster") {
            this.setState({
                menu_item_poster_temp: filename !== null,
                menu_item_poster: filename !== null ? filename : null,
                menu_item_poster_folder_temp: folder
            });
        }

    }

    submitPosterImage(filename_poster) {

        if (this.state.menu_item_poster_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.menu_item_poster_folder_temp);
            bodyFormData.set('location', "device_menu");
            bodyFormData.set('filename', filename_poster);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({menu_item_poster_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    handleClose = () => {
        this.setState({showModalCustomerDevice: false})
        this.props.handlerFromParent(this.props.handlerID, false, "nothing", this.state.menu_item_type);
    }

    handleMainTypeChange = (type) => {

        this.setState({
            menu_item_type: type === null ? null : type.value
        });
        console.log(`Option selected:`, type);

    }

    manageSaveDevice() {

        if (this.props.newMenuItem === true) {
            this.createMenuItem();
        } else {
            this.editMenuItem();
        }

    }

    createMenuItem() {

        const url = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + this.props.applicationId;

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""

        if (this.state.menu_item_poster_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_" + this.state.menu_item_poster
            this.setState({menu_item_poster: filename_poster})
            console.log("*****" + filename_poster)
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.menu_item_poster
        }

        let menuItemStatus = this.state.statusSwitch === true ? 1 : 0;

        let postData = {
            type: "add_device_menu",
            menu_item_category_id: this.state.menu_item_category_id,
            menu_item_device_type: this.props.deviceType,
            menu_item_name: this.state.menu_item_name,
            menu_item_position: this.state.menu_item_position,
            menu_item_poster: filename_poster,
            menu_item_status: menuItemStatus,
            menu_item_type: this.state.menu_item_type,
            menu_item_layout: this.state.menu_item_layout,
            menu_item_colour: this.state.menu_item_colour,
            menu_item_external_url: this.state.menu_item_external_url,
            menu_item_content_provider: this.state.menu_item_content_provider,
            menu_item_block_countries: this.state.menu_item_block_countries === null ? null : this.state.menu_item_block_countries.join(),
            menu_item_application_id: this.props.applicationId,
            menu_item_device_type_multi: this.state.menu_item_device_type_multi === null ? null : this.state.menu_item_device_type_multi.join(),
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {

                this.setState({showModalMessage: false})
                this.props.handlerFromParent(this.props.handlerID, false, "reload", this.state.menu_item_type);

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            })
    }

    editMenuItem() {

        // console.log(this.state.menu_item_content_provider);
        // console.log("editMenuItem");

        const url = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + this.props.applicationId;

        let menuItemStatus = this.state.statusSwitch === true ? 1 : 0;

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""

        if (this.state.menu_item_poster_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_" + this.state.menu_item_poster
            this.setState({menu_item_poster: filename_poster})
            console.log("*****" + filename_poster)
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.menu_item_poster
        }

        let postData = {
            type: "update_device_menu",
            menu_item_category_id: this.state.menu_item_category_id,
            menu_item_device_type: this.props.deviceType,
            menu_item_name: this.state.menu_item_name,
            menu_item_position: this.state.menu_item_position,
            menu_item_poster: filename_poster,
            menu_item_status: menuItemStatus,
            menu_item_type: this.state.menu_item_type,
            menu_item_content_provider: this.state.menu_item_content_provider,
            menu_item_layout: this.state.menu_item_layout,
            menu_item_colour: this.state.menu_item_colour,
            menu_item_external_url: this.state.menu_item_external_url,
            menu_item_block_countries: this.state.menu_item_block_countries === null ? null : this.state.menu_item_block_countries.join(),
            menu_item_id: this.state.id,
            menu_item_device_type_multi: this.state.menu_item_device_type_multi === null ? null : this.state.menu_item_device_type_multi.join(),
        };

        console.log(postData);

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                this.setState({showModalMessage: false})
                this.props.handlerFromParent(this.props.handlerID, false, "reload", this.state.menu_item_type);
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            })
    }

    componentDidMount() {

        console.log("ModalApplicationDeviceMenu->componentDidMount");
        // console.log("ModalApplicationDeviceMenu->componentDidMount");
        // console.log("ModalApplicationDeviceMenu->Application ID: " + this.props.applicationId);
        // console.log("ModalApplicationDeviceMenu->Menu ID: " + this.props.menuItemObject.id);
        // console.log("ModalApplicationDeviceMenu->Menu Object: " + this.props.menuItemObject);
        // console.log("ModalApplicationDeviceMenu->Menu Object: " + this.props.menuItem);
        // console.log("ModalApplicationDeviceMenuCategoryItem->Menu Multi: " + this.props.menuItemObject.menu_item_device_type_multi);
        // console.log("ModalApplicationDeviceMenu->Menu Multi: " + this.props.menuItem.menu_item_device_type_multi);

        let  current_user = JSON.parse(localStorage.getItem('user'));
        console.log(current_user)

        if (this.props.newMenuItem === false) {

            let statusSwitch = false

            if (this.props.menuItemObject.menu_item_status === 1) {
                statusSwitch = true
            }

            let deviceTypes = []
            if (this.props.menuItemObject.menu_item_device_type_multi !== null) {
                let tempDeviceTypes = this.props.menuItemObject.menu_item_device_type_multi.toString();
                deviceTypes = tempDeviceTypes.split(",")
            }

            let countries = []
            if (this.props.menuItemObject.menu_item_block_countries !== null) {
                let tempCountry = this.props.menuItemObject.menu_item_block_countries.toString();
                countries = tempCountry.split(",")
            }

            let id = this.props.menuItemObject.id
            let menu_item_category_id = this.props.menuItemObject.menu_item_category_id
            let menu_item_device_type = this.props.deviceType
            let menu_item_name = this.props.menuItemObject.menu_item_name
            let menu_item_position = this.props.menuItemObject.menu_item_position
            let menu_item_poster = this.props.menuItemObject.menu_item_poster
            let menu_item_status = this.props.menuItemObject.menu_item_status
            let menu_item_content_provider = this.props.menuItemObject.menu_item_content_provider
            let menu_item_type = this.props.menuItemObject.menu_item_type
            let menu_item_colour = this.props.menuItemObject.menu_item_colour
            let menu_item_external_url = this.props.menuItemObject.menu_item_external_url
            let menu_item_block_countries = countries
            let menu_item_layout = this.props.menuItemObject.menu_item_layout
            let menu_item_device_type_multi = deviceTypes

            this.setState(
                {
                    id,
                    menu_item_category_id,
                    menu_item_device_type,
                    menu_item_name,
                    menu_item_position,
                    menu_item_poster,
                    menu_item_status,
                    menu_item_type,
                    statusSwitch,
                    menu_item_colour,
                    menu_item_layout,
                    menu_item_content_provider,
                    menu_item_external_url,
                    menu_item_block_countries,
                    menu_item_device_type_multi,
                    current_user:current_user
                }
            )

        }

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });


    }

    handleSwitch(elem, state) {

        if (elem.props.name === 'statusSwitch') {
            this.setState({statusSwitch: state})
        }

    }

    renderExternalUrl = () => {

        if (this.state.menu_item_layout === "External_URL" || this.state.menu_item_layout === "My_Account") {

            return (
                <FormGroup>
                    <ControlLabel>External URL</ControlLabel>
                    <FormControl
                        type="text"
                        name="menu_item_external_url"
                        value={this.state.menu_item_external_url || ''}
                        placeholder="enter the external url"
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
            )
        }
    }

    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <FormGroup>
                        <ControlLabel>Content Provider</ControlLabel>
                        <DropDownContentProviders
                            contentProviderSelected={this.state.menu_item_content_provider}
                            name='contentProviderSelect'
                            handlerFromParent={this.handleChildContentProviderSelect}
                            disabled={this.state.loading}
                            applicationID={this.props.applicationId}
                        />
                    </FormGroup>

                )
            }
        }

    }

    render() {
        return (
            <div>
                <Modal show={this.state.showModalCustomerDevice} onHide={this.handleClose}>
                    <Modal.Header closeButton style={{backgroundColor: '#fbfbfb'}}>
                        <Modal.Title>{this.props.headerMessage}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Grid fluid>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <ControlLabel>ID</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="id"
                                            disabled
                                            value={this.state.id || ''}
                                            placeholder="NEW"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Device Type</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="menu_item_device_type"
                                            disabled
                                            value={this.state.menu_item_device_type || ''}
                                            placeholder="DEVICE_TYPE"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Media</ControlLabel>
                                        <ImageUpload
                                            imageName={this.state.menu_item_poster}
                                            imageType="device_menu"
                                            imagePosition={"menu_item_poster"}
                                            imageTemp={this.state.menu_item_poster_temp}
                                            imageFolderTemp={this.state.menu_item_poster_folder_temp}
                                            handlerFromParent={this.handleMenuItemImageSelect}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm={8}>
                                    <FormGroup>
                                        <ControlLabel>Status</ControlLabel>
                                        <p className="category"/>
                                        <Switch
                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                            name='statusSwitch'
                                            value={this.state.statusSwitch}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>Name</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="menu_item_name"
                                            value={this.state.menu_item_name || ''}
                                            placeholder="enter a name"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>Device Type - Multi</ControlLabel>
                                        <Select
                                            value={this.state.menu_item_device_type_multi}
                                            onChange={this.handleDeviceTypeChange}
                                            options={DeviceTypeOptions}
                                            multi={true}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>Position</ControlLabel>
                                        <FormControl
                                            type="number"
                                            name="menu_item_position"
                                            value={this.state.menu_item_position || ''}
                                            placeholder="enter the position"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>Color</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="menu_item_colour"
                                            value={this.state.menu_item_colour || ''}
                                            placeholder="enter a name"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>Layout</ControlLabel>
                                        <Select
                                            value={this.state.menu_item_layout}
                                            onChange={this.handleLayoutChange}
                                            options={LayoutOptions}
                                        />
                                    </FormGroup>

                                    {this.renderExternalUrl()}

                                    {this.renderContentProvider()}


                                    <FormGroup>
                                        <ControlLabel>Item Type</ControlLabel>
                                        <Select
                                            value={this.state.menu_item_type}
                                            onChange={this.handleMainTypeChange}
                                            options={MainTypeOptions}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>{'Block Countries'}</ControlLabel>
                                        <DropDownCountries
                                            countrySelected={this.state.menu_item_block_countries}
                                            name='blackOutSelect'
                                            handlerFromParent={this.handleChildCountrySelect}
                                            hadlerAddAllCountries={this.state.addAllCountriesInBlackList}

                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Button
                                            onClick={this.onClickAddAllCountriesInBlackList.bind(this)}
                                        >
                                            Add All countries
                                        </Button>
                                    </FormGroup>

                                </Col>

                            </Row>
                        </Grid>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button bsStyle="info" fill onClick={this.manageSaveDevice.bind(this)}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedModalApplicationDeviceMenuItem = connect(mapStateToProps)(ModalApplicationDeviceMenuItem);
const connectedModalApplicationDeviceMenuItemTrans = withNamespaces('common')(connectedModalApplicationDeviceMenuItem);
export {connectedModalApplicationDeviceMenuItemTrans as ModalApplicationDeviceMenuItem};
