import React from "react";
import axios from "axios";
import Button from "../CustomButton/CustomButton.jsx";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {OverlayTrigger, Tooltip, InputGroup, Row, Grid, Col, FormGroup} from "react-bootstrap";
import ModalDelete from "../CustomModals/ModalDelete";
import {ModalOrganization} from "../CustomModals/ModalOrganization";
import ReactTableV6Simple from "../CustomDataTables/ReactTableV6Simple";
import Card from "../Card/Card";
import {loadImage} from "../../functions/LoadImages";

class DataOrganizations extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            edit_organization_tooltip: (<Tooltip id="edit_tooltip">Edit</Tooltip>),
            remove_organization_tooltip: (<Tooltip id="remove_tooltip">Remove</Tooltip>),

            redirect: false,
            organization_id: 1,
            loading: true,
            organizationsArray: [],
            lastSelectedOrganization: [],
            showDeleteModal: false,
            showOrganizationModal: false,
            searchOrganization: "",
            filter: null,

            columns: [
                {
                    accessor: "organization_id",
                    Header: "ID",
                    maxWidth: 80,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "organization_name",
                    Header: "Name",
                    maxWidth: 450,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "organization_description",
                    Header: "Description",
                    maxWidth: 450,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "organization_logo",
                    Header: "Logo",
                    sortable: true,
                    maxWidth: 200,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            <img
                                src={loadImage.load_organization_image(row.value)}
                                // width="90%"
                                height="60px"
                                alt={""}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                                }}
                            />
                        </div>
                    )
                },
                {
                    Header: 'actions',
                    maxWidth: 200,
                    sortable: false,
                    filterable: false,
                    accessor: "organization_id",
                    Cell: row => (
                        <div className="actions-right">
                            {this.setHelperButton(row.value)}
                            {this.setDeleteButton(row.value)}
                        </div>
                    )
                }
            ],
            data: []

        };

        this.setDisplayModal = this.setDisplayModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }


    handleInputChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let dt = new Date();
        let utcDate = dt.toUTCString();

        if (name === "searchOrganization") {

            this.setState({
                lastUpdate: utcDate,
                filtered: [''],
                filter: value,
                activePage: 1,
                searchOrganization: value
            })

            this.loadOrganizationPost(value);
        }

    }

    setHelperButton(organization_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.edit_organization_tooltip}>
            <Button
                onClick={() => this.setState({
                    showOrganizationModal: true,
                    organization_id: organization_id
                })}

                bsStyle="info"
                simple
                icon
            >
                <i className="fa fa-edit"/>
            </Button>
        </OverlayTrigger>)

    }

    setDeleteButton(organization_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.remove_organization_tooltip}>
            <Button
                onClick={() => this.setState({showDeleteModal: true, organization_id: organization_id})}

                bsStyle="danger"
                simple
                icon
            >
                <i className="fa fa-times"/>
            </Button>
        </OverlayTrigger>)

    }

    setDisplayModal = (id, value) => {

        let device = this.findArrayElementByID(this.state.organizationsArray, id)

        this.setState({
            showModal: value,
            decoder_id: id,
            lastSelectedDevice: device

        })
    }

    componentDidMount() {

        this.loadOrganizations();

    }

    loadOrganizations() {

        this.setState({loading: true});

        let dataUrl = process.env.REACT_APP_API_URL + "/Organization";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios.get(dataUrl, axiosConfig).then(res => {
            const organizationsArray = res.data;
            const loading = false;
            this.setState({organizationsArray, loading});

        }).catch(error => {
            this.setState({loading: false});
            //this.props.dispatch(userActions.logoutUnauthorized());
            console.log(error);
        });
    }

    loadOrganizationsPost(value) {

        this.setState({loading: true});

        let dataUrl = process.env.REACT_APP_API_URL + "/Organization";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        var postData = {
            type: "list",
            searchProvider: value
        }

        axios.post(dataUrl, postData, axiosConfig).then(res => {
            const organizationsArray = res.data;
            const loading = false;
            this.setState({organizationsArray, loading});
        }).catch(error => {
            this.setState({loading: false});
            //this.props.dispatch(userActions.logoutUnauthorized());
            console.log(error);
        });

    }

    deleteOrganization() {

        const deleteURL = process.env.REACT_APP_API_URL + "/Organization/" + this.state.organization_id;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios.delete(deleteURL, axiosConfig)
            .then((res) => {
                this.loadOrganizations();
                //this.props.handlerFromParent();
                this.setState({organization_id: null});
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })


    }

    setDeleteOrganizationModal = (id, value, action) => {

        this.setState({
            showDeleteModal: value
        })

        if (action === "delete") {
            this.deleteOrganization();

        }


    }

    setEditOrganizationModal = (id, value, action) => {

        this.setState({
            showOrganizationModal: value,
            organization_id: null
        })

        if (action === "reload") {
            this.loadOrganizations();
            //this.props.handlerFromParent();
        }

    }

    renderDeleteOrganizationModal = () => {

        let bodyMessageVar = "this action will delete the Organization.";

        if (this.state.showDeleteModal) {
            return <ModalDelete
                headerMessage={"Delete Organization"}
                bodyMessage={bodyMessageVar}
                handlerFromParent={this.setDeleteOrganizationModal}
                handlerID={this.state.organization_id}
            />
        }
    }

    renderOrganizationModal = () => {

        let bodyMessageVar = "";

        if (this.state.showOrganizationModal) {
            return <ModalOrganization
                headerMessage={"Edit Organization"}
                bodyMessage={bodyMessageVar}
                handlerFromParent={this.setEditOrganizationModal}
                handlerID={this.state.organization_id}
            />
        }
    }

    clearSearchButton() {

        let dt = new Date();
        let utcDate = dt.toUTCString();
        let value = "";

        // if (this.props.listState === "removed") {
        //     set.removed_page_filter(value)
        //     set.removed_page("1")
        // } else {
        //     set.active_page_filter(value)
        //     set.active_page("1")
        // }

        this.setState({
            lastUpdate: utcDate,
            filtered: [''],
            filter: value,
            activePage: 1,
            searchOrganization: ""
        })

        this.loadOrganizations();

    }

    render() {
        return (
            <div>

                {/*{this.renderRedirect()}*/}
                {this.renderDeleteOrganizationModal()}
                {this.renderOrganizationModal()}

                <Card
                    content={
                        <div>
                            <div>
                                <Grid fluid>
                                    <Row>
                                        <Col md={12}>
                                            <div className="pull-left">
                                                <h5>
                                                    {this.props.listName}
                                                </h5>
                                            </div>
                                            <div className="pull-left">
                                                <Button bsStyle="info" fill bsSize="xs"
                                                        style={{marginLeft: "10px", marginTop: "10px"}}
                                                        onClick={() => this.setState({
                                                            showOrganizationModal: true,
                                                            organization_id: "new"
                                                        })}
                                                >
                                                    {/*<i className="fa fa-plus-circle fa-lg"/>*/}
                                                    Add new organization
                                                </Button>
                                            </div>
                                            <div className="pull-right" style={{width: "250px"}}>
                                                <FormGroup>
                                                    <InputGroup>
                                                        <input
                                                            className="form-control form-control-sm ml-3 w-75"
                                                            type="text"
                                                            placeholder="Search"
                                                            aria-label="Search"
                                                            name="searchOrganization"
                                                            onChange={this.handleInputChange}
                                                            value={this.state.searchOrganization || ""}
                                                        />
                                                        <InputGroup.Addon>
                                                            <i style={{color: this.state.searchOrganization !== "" ? "#f7263b" : "#343434"}}
                                                               className={this.state.searchOrganization !== "" ? "fa fa-times" : "fa fa-search"}
                                                               onClick={this.clearSearchButton.bind(this)}/>
                                                        </InputGroup.Addon>
                                                    </InputGroup>
                                                </FormGroup>

                                            </div>

                                        </Col>
                                    </Row>
                                </Grid>
                            </div>

                            <legend/>


                            <ReactTableV6Simple
                                data={this.state.organizationsArray}
                                loading={this.state.loading}
                                columns={this.state.columns}
                                defaultPageSize={5}
                            />

                        </div>
                    }
                />

            </div>
        );

    }
}

function mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedDataOrganizations = connect(mapStateToProps)(DataOrganizations);
export {connectedDataOrganizations as DataOrganizations};