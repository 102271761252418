import React, {Component} from "react";
import {Redirect} from "react-router";
import Switch from "react-bootstrap-switch";
import axios from "axios/index";
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl, InputGroup} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import ModalSave from "../../components/CustomModals/ModalSave";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import {DropDownContentProviders} from "../../components/CustomDropDown/DropDownContentProviders";
import {DropDownApplications} from "../../components/CustomDropDown/DropDownApplications";
import {DropDownFeatureMenus} from "../../components/CustomDropDown/DropDownFeatureMenus";
import {DropDownFeatureContent} from "../../components/CustomDropDown/DropDownFeatureContent";
import Select from "react-select";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

const adminArray = ['super-admin', 'admin']

const deviceSelection = [
    // {value: 'android_mobile', label: 'Android'},
    // {value: 'ios_mobile', label: 'iOS'},
    // {value: 'android_tv', label: 'Android TV'},
    // {value: 'roku', label: 'Roku'},
    // {value: 'web', label: 'Web'},
    {value: 'global', label: 'Global'},
];

const contentTypeSelection = [
    {value: 'svod_movies', label: 'SVOD Movies'},
    {value: 'svod_series', label: 'SVOD Series'},
    {value: 'svod_all', label: 'SVOD All Content'},
    {value: 'events', label: 'Events'},
    {value: 'sports', label: 'Sports'},
];


class EditFeatureSection extends Component {

    constructor(props) {
        super(props);
        this.state = {

            _notificationSystem: null,

            featureArray: [],

            loading: false,
            redirect: false,

            back_button_text: "Cancel",

            showSaveModal: false,
            featureRedirect: false,
            featureStatus: false,

            feature_id: null,
            feature_status: null,
            feature_position: null,
            feature_name: null,

            feature_trailer_url: null,
            feature_title: null,
            feature_description: null,
            feature_device_type: null,
            feature_application_id: null,

            feature_notes: null,
            feature_menu_id: null,
            feature_content_type: null,
            feature_content_id: null,
            feature_is_series: null,
            feature_portrait_image: null,
            feature_landscape_image: null,
            feature_title_image: null,

            feature_portrait_image_temp: false,
            feature_portrait_image_folder_temp: "",

            feature_landscape_image_temp: false,
            feature_landscape_image_folder_temp: "",

            feature_title_image_temp: false,
            feature_title_image_folder_temp: "",

            feature_content_provider: null,
            current_user: null,
            user_content_provider: null,
            custom_data: null,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);
        this.handleChildApplicationSelect = this.handleChildApplicationSelect.bind(this);
        this.handleChildMenuSelect = this.handleChildMenuSelect.bind(this);
        this.handleChildContentSelect = this.handleChildContentSelect.bind(this);
        this.handleDeviceChange = this.handleDeviceChange.bind(this);
        this.handleContentTypeChange = this.handleContentTypeChange.bind(this);

    }

    handleDeviceChange = (data) => {

        let devices = []

        for (let i = 0; i < data.length; i++) {
            devices.push(data[i].value)
        }

        if (this.state.feature_device_type !== data.value) {
            this.setState({
                feature_menu_id: null,
            });
        }

        this.setState({
            feature_device_type: data === null ? null : devices
        });

    }

    handleContentTypeChange = (contentTypeDropDown) => {

        if (this.state.feature_device_type !== contentTypeDropDown.value) {
            this.setState({
                feature_content_type: null,
            });
        }

        this.setState({
            feature_content_type: contentTypeDropDown === null ? null : contentTypeDropDown.value
        });

    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
            });
        }
    }

    handleChildApplicationSelect(data) {

        if (data !== undefined) {

            //console.log(this.state.feature_application_id)
            // console.log(data.value)
            //console.log(data)

            if(data !== null){
                if (this.state.feature_application_id !== data.value) {
                    this.setState({
                        feature_menu_id: null,
                    });
                }
            }else{
                this.setState({
                    feature_menu_id: null,
                });
            }


            this.setState({
                feature_application_id: data === null ? null : data.value,
            });
        }
    }

    handleChildMenuSelect(data) {

        let menuIDs = []

        for (let i = 0; i < data.length; i++) {
            menuIDs.push(data[i].value)
        }

        this.setState({
            feature_menu_id: data === null ? null : menuIDs
        });
    }

    handleChildContentSelect(data) {

        let contentIDs = []

        for (let i = 0; i < data.length; i++) {
            contentIDs.push(data[i].value)
        }

        this.setState({
            feature_content_id: data === null ? null : contentIDs
        });
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})
        this.setState({loading: true});

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
            this.setState({current_user: user});
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/Feature/" + handle;

        if (handle === 'new') {
            this.setState({loading: false})
            this.getPosition();
        } else {
            axios
                .get(url, axiosConfig)
                .then(res => {

                    const featureArray = res.data[0];
                    const loading = false;

                    let featureStatus = featureArray.status === 1

                    let menuIDs = []

                    if (featureArray.menu_id !== null) {
                        let temp = featureArray.menu_id.toString();
                        menuIDs = temp.split(",")
                    }

                    let devices = []

                    // if (featureArray.device_type !== null) {
                    //     let temp = featureArray.device_type.toString();
                    //     devices = temp.split(",")
                    // }

                    let content = []

                    if (featureArray.content_id !== null) {
                        let temp = featureArray.content_id.toString();
                        content = temp.split(",")
                    }

                    this.setState({
                        back_button_text: "Back",
                        featureArray,
                        featureStatus,
                        loading,

                        feature_id: featureArray.id,
                        feature_status: featureStatus,
                        feature_position: featureArray.position,
                        feature_name: featureArray.name,
                        feature_trailer_url: featureArray.trailer_url,
                        feature_title: featureArray.title,
                        feature_description: featureArray.description,
                        feature_device_type: 'global',
                        feature_application_id: featureArray.application_id,
                        feature_notes: featureArray.notes,
                        feature_menu_id: menuIDs,
                        feature_content_type: featureArray.content_type,
                        feature_content_id: content,
                        feature_is_series: featureArray.is_series,
                        feature_portrait_image: featureArray.portrait_image,
                        feature_landscape_image: featureArray.landscape_image,
                        feature_title_image: featureArray.title_image,
                        feature_content_provider: featureArray.content_provider,
                        current_user: user,
                        user_content_provider: featureArray.content_provider,
                        custom_data: featureArray.custom_data

                    });
                })

                .catch(error => {
                    this.setState({loading: false});
                    //this.props.dispatch(userActions.logoutUnauthorized());
                    console.log(error);
                });
        }


    }

    getPosition() {

        const url = process.env.REACT_APP_API_URL + "/Feature/position";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios
            .get(url, axiosConfig)
            .then(res => {
                const featureArray = res.data[0];
                this.setState({
                    feature_position: featureArray.featurePosition
                });
            })
            .catch(error => {
                //this.props.dispatch(userActions.logoutUnauthorized());
                console.log(error);
            });

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {

        if (elem.props.name === 'featureStatus') {
            this.setState({featureStatus: state})
        }
    }

    submitPortraitImage(filename_portrait) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.feature_portrait_image_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.feature_portrait_image_folder_temp);
            bodyFormData.set('location', "feature_list");
            bodyFormData.set('filename', filename_portrait);


            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({feature_portrait_image_temp: false})
                })
                .catch((err) => {
                    console.log("feature_image - AXIOS ERROR: ", err);
                })
        }

    }

    submitLandscapeImage(filename_landscape) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.feature_landscape_image_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.feature_landscape_image_folder_temp);
            bodyFormData.set('location', "feature_list");
            bodyFormData.set('filename', filename_landscape);

            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({feature_landscape_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }


    }

    submitTitleImage(filename_title) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.feature_title_image_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.feature_title_image_folder_temp);
            bodyFormData.set('location', "feature_list");
            bodyFormData.set('filename', filename_title);

            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({feature_title_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }


    }

    updateFeature() {


        this.setState({loading: true})
        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/Feature/" + handle;

        //RENAME FUNCTIONS START POSTER WIDE
        let filename_portrait = ""
        if (this.state.feature_portrait_image_temp) {
            let timeUTC = new Date().getTime()
            filename_portrait = timeUTC + "_p_" + this.state.feature_portrait_image
            this.setState({feature_portrait_image: filename_portrait})
            this.submitPortraitImage(filename_portrait);
        } else {
            filename_portrait = this.state.feature_portrait_image
        }

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_landscape = ""
        if (this.state.feature_landscape_image_temp) {
            let timeUTC = new Date().getTime()
            filename_landscape = timeUTC + "_l_" + this.state.feature_landscape_image
            this.setState({feature_landscape_image: filename_landscape})
            this.submitLandscapeImage(filename_landscape);
        } else {
            filename_landscape = this.state.feature_landscape_image
        }

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_title = ""
        if (this.state.feature_title_image_temp) {
            let timeUTC = new Date().getTime()
            filename_title = timeUTC + "_t_" + this.state.feature_title_image
            this.setState({feature_title_image: filename_title})
            this.submitTitleImage(filename_title);
        } else {
            filename_title = this.state.feature_title_image
        }


        let tempFeatureStatus = this.state.featureStatus === true ? 1 : 0;

        // Send a PUT request
        var postData = {

            feature_id: this.state.feature_id,
            feature_status: tempFeatureStatus,
            feature_position: this.state.feature_position,
            feature_name: this.state.feature_name,
            feature_trailer_url: this.state.feature_trailer_url,
            feature_title: this.state.feature_title,
            feature_description: this.state.feature_description,
            // feature_device_type: this.state.feature_device_type !== null ? this.state.feature_device_type.join() : null,
            feature_device_type: 'global',
            feature_application_id: this.state.feature_application_id,
            feature_notes: this.state.feature_notes,
            feature_menu_id: this.state.feature_menu_id !== null ? this.state.feature_menu_id.join() : null,
            feature_content_type: this.state.feature_content_type,
            feature_content_id: this.state.feature_content_id !== null ? this.state.feature_content_id.join() : null,
            feature_is_series: this.state.feature_is_series,
            feature_portrait_image: filename_portrait,
            feature_landscape_image: filename_landscape,
            feature_title_image: filename_title,
            feature_content_provider: this.state.user_content_provider,
            custom_data: this.state.custom_data,

        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})
                this.notification("success")
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.notification("failed")
            })

    }

    createFeature() {


        this.setState({loading: true})
        const url = process.env.REACT_APP_API_URL + "/Feature";

        //RENAME FUNCTIONS START POSTER WIDE
        let filename_portrait = ""
        if (this.state.feature_portrait_image_temp) {
            let timeUTC = new Date().getTime()
            filename_portrait = timeUTC + "_p_" + this.state.feature_portrait_image
            this.setState({feature_portrait_image: filename_portrait})
            this.submitPortraitImage(filename_portrait);
        } else {
            filename_portrait = this.state.feature_portrait_image
        }

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_landscape = ""
        if (this.state.feature_landscape_image_temp) {
            let timeUTC = new Date().getTime()
            filename_landscape = timeUTC + "_l_" + this.state.feature_landscape_image
            this.setState({feature_landscape_image: filename_landscape})
            this.submitLandscapeImage(filename_landscape);
        } else {
            filename_landscape = this.state.feature_landscape_image
        }

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_title = ""
        if (this.state.feature_title_image_temp) {
            let timeUTC = new Date().getTime()
            filename_title = timeUTC + "_t_" + this.state.feature_title_image
            this.setState({feature_title_image: filename_title})
            this.submitTitleImage(filename_title);
        } else {
            filename_title = this.state.filename_title
        }


        let tempFeatureStatus = this.state.featureStatus === true ? 1 : 0;

        // Send a PUT request
        var postData = {
            feature_status: tempFeatureStatus,
            feature_position: this.state.feature_position,
            feature_name: this.state.feature_name,
            feature_trailer_url: this.state.feature_trailer_url,
            feature_title: this.state.feature_title,
            feature_description: this.state.feature_description,
            // feature_device_type: this.state.feature_device_type !== null ? this.state.feature_device_type.join() : null,
            feature_device_type: 'global',
            feature_application_id: this.state.feature_application_id,
            feature_notes: this.state.feature_notes,
            feature_menu_id: this.state.feature_menu_id !== null ? this.state.feature_menu_id.join() : null,
            feature_content_type: this.state.feature_content_type,
            feature_content_id: this.state.feature_content_id !== null ? this.state.feature_content_id.join() : null,
            feature_is_series: this.state.feature_is_series,
            feature_portrait_image: filename_portrait,
            feature_landscape_image: filename_landscape,
            feature_title_image: filename_title,
            feature_content_provider: this.state.user_content_provider,
            custom_data: this.state.custom_data,
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                const data = res.data;
                this.setState({loading: false, feature_id: data.feature_id})
                this.setFeatureRedirect();
                this.notification("success")
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.notification("failed")
            })

    }

    handleChildImageSelect(folder, filename, imagePosition) {

        console.log("" + folder);
        console.log("" + filename);
        console.log("" + imagePosition);
        console.log(process.env.REACT_APP_ASSET_URL + "/tmp/" + folder + "/" + filename);

        if (imagePosition === "feature_portrait_image") {
            this.setState({
                feature_portrait_image_temp: filename !== null,
                feature_portrait_image: filename,
                feature_portrait_image_folder_temp: folder
            });
        }
        if (imagePosition === "feature_landscape_image") {
            this.setState({
                feature_landscape_image_temp: filename !== null,
                feature_landscape_image: filename,
                feature_landscape_image_folder_temp: folder
            });
        }
        if (imagePosition === "feature_title_image") {
            this.setState({
                feature_title_image_temp: filename !== null,
                feature_title_image: filename,
                feature_title_image_folder_temp: folder
            });
        }


    }

    cancelFeatureEdit() {
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    setFeatureRedirect = () => {
        this.setState({
            featureRedirect: true,
            back_button_text: "Back"
        })
    }

    renderRedirect = () => {

        let path = "/feature";
        let pathFeature = "/edit-feature-section/" + this.state.feature_id;

        if (this.state.redirect) {
            this.setState({
                redirect: false,
                featureRedirect: false
            })
            return <Redirect push to={path}/>
        }

        if (this.state.featureRedirect) {
            this.setState({
                redirect: false,
                featureRedirect: false
            })
            return <Redirect push to={pathFeature}/>
        }

    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({saving: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createFeature();
            } else {
                this.updateFeature();
            }
        }
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Feature section: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.feature_id}
            />
        }
    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Feature section has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update Feature section</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <FormGroup>
                        <ControlLabel>Content Provider</ControlLabel>
                        <DropDownContentProviders
                            contentProviderSelected={this.state.user_content_provider}
                            name='contentProviderSelect'
                            handlerFromParent={this.handleChildContentProviderSelect}
                            disabled={this.state.loading}
                        />
                    </FormGroup>

                )
            }
        }

    }

    renderAppSelection = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <FormGroup>
                        <ControlLabel>Application</ControlLabel>
                        <DropDownApplications
                            applicationSelected={this.state.feature_application_id}
                            name='applicationSelect'
                            handlerFromParent={this.handleChildApplicationSelect}
                            disabled={this.state.loading}
                        />
                    </FormGroup>

                )
            }
        }

    }


    renderDeviceSelection = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <FormGroup>
                        <ControlLabel className="category">Device Type</ControlLabel>
                        <Select
                            value={this.state.feature_device_type}
                            onChange={this.handleDeviceChange}
                            options={deviceSelection}
                            multi={true}
                            // disabled={this.state.newPackage}
                        />
                    </FormGroup>

                )
            }
        }

    }

    renderMenuSelection = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <FormGroup>
                        <ControlLabel>Menu Items</ControlLabel>
                        <DropDownFeatureMenus
                            menuSelected={this.state.feature_menu_id}
                            name='menuSelect'
                            handlerFromParent={this.handleChildMenuSelect}
                            deviceType={'global'}
                            // deviceType={this.state.feature_device_type !== null ? this.state.feature_device_type.join() : null}
                            applicationId={this.state.feature_application_id}
                            disabled={this.state.loading}
                        />
                    </FormGroup>
                )
            }
        }
    }

    renderTypeSelection = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <FormGroup>
                        <ControlLabel className="category">Content Type</ControlLabel>
                        <Select
                            value={this.state.feature_content_type}
                            onChange={this.handleContentTypeChange}
                            options={contentTypeSelection}
                        />
                    </FormGroup>

                )
            }
        }
    }

    renderContentSelection = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <FormGroup>
                        <ControlLabel>Content</ControlLabel>
                        <DropDownFeatureContent
                            contentSelected={this.state.feature_content_id}
                            name='menuSelect'
                            handlerFromParent={this.handleChildContentSelect}
                            deviceType={'global'}
                            // deviceType={this.state.feature_device_type !== null ? this.state.feature_device_type.join() : null}
                            applicationId={this.state.feature_application_id}
                            contentProvider={this.state.user_content_provider}
                            contentType={this.state.feature_content_type}
                            disabled={this.state.loading}
                        />
                    </FormGroup>
                )
            }
        }
    }

    renderIsSeriesSelection = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <FormGroup>
                        <ControlLabel className="category">Is Series</ControlLabel>
                        <FormControl placeholder="Enter is series"
                                     type="text"
                                     name="feature_is_series"
                                     disabled={this.state.loading}
                                     onChange={this.handleInputChange}
                                     value={this.state.feature_is_series || ""}
                        />
                    </FormGroup>
                )
            }
        }
    }

    render() {
        return (
            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={8}>
                            <Card
                                title="Feature Section - Details"
                                content={
                                    <div>
                                        <legend/>
                                        <Grid fluid>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <p className="category">Status</p>
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            name='featureStatus'
                                                            disabled={this.state.loading}
                                                            value={this.state.featureStatus}
                                                            defaultValue={false}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <ControlLabel className="category">Position</ControlLabel>
                                                        <FormControl placeholder="Enter the position"
                                                                     type="number"
                                                                     name="feature_position"
                                                                     disabled={this.state.loading}
                                                                     onChange={this.handleInputChange}
                                                                     value={this.state.feature_position || ""}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <ControlLabel className="category">Name</ControlLabel>
                                                        <FormControl placeholder="Enter the name of the section"
                                                                     type="text"
                                                                     name="feature_name"
                                                                     disabled={this.state.loading}
                                                                     onChange={this.handleInputChange}
                                                                     value={this.state.feature_name || ""}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <ControlLabel className="category">Title</ControlLabel>
                                                        <FormControl placeholder="Enter the title of the section"
                                                                     type="text"
                                                                     name="feature_title"
                                                                     disabled={this.state.loading}
                                                                     onChange={this.handleInputChange}
                                                                     value={this.state.feature_title || ""}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <ControlLabel className="category">Description</ControlLabel>
                                                        <FormControl placeholder="Enter a description"
                                                                     type="text"
                                                                     name="feature_description"
                                                                     disabled={this.state.loading}
                                                                     onChange={this.handleInputChange}
                                                                     value={this.state.feature_description || ""}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <ControlLabel className="category">Trailer URL</ControlLabel>
                                                        <FormControl placeholder="Enter the trailer url"
                                                                     type="text"
                                                                     name="feature_trailer_url"
                                                                     disabled={this.state.loading}
                                                                     onChange={this.handleInputChange}
                                                                     value={this.state.feature_trailer_url || ""}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <ControlLabel className="category"
                                                                      style={{fontWeight: "bold"}}>Configuration</ControlLabel>
                                                        <legend/>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Card
                                                            content={
                                                                <Grid fluid>
                                                                    {this.renderContentProvider()}
                                                                    {this.renderAppSelection()}
                                                                    {/*{this.renderDeviceSelection()}*/}
                                                                    {this.renderMenuSelection()}
                                                                    {this.renderTypeSelection()}
                                                                    {this.renderContentSelection()}
                                                                    {/*{this.renderIsSeriesSelection()}*/}
                                                                </Grid>
                                                            }
                                                        />
                                                    </FormGroup>


                                                </Col>
                                            </Row>

                                        </Grid>
                                    </div>
                                }
                                ftTextCenter
                                legend={
                                    <div className="buttons-with-margin">
                                        <Button bsStyle="info" fill wd
                                                name={"saveButton"}
                                                disabled={this.state.loading}
                                                onClick={() => this.setState({showSaveModal: true})}
                                        >
                                            {this.state.loading ? 'Loading...' : this.props.t('global.save', {framework: "react-i18next"})}
                                        </Button>
                                        <Button bsStyle="default" fill wd
                                                name={"cancelButton"}
                                                disabled={this.state.loading}
                                                onClick={this.cancelFeatureEdit.bind(this)}>
                                            {this.state.back_button_text}
                                        </Button>
                                    </div>
                                }
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4}>
                            <Card
                                title="Media Assets"
                                content={
                                    <div>
                                        <legend/>
                                        <Grid fluid>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup style={{
                                                        marginBottom: "50px",
                                                        marginTop: "50px"
                                                    }}>
                                                        <ControlLabel>Portrait</ControlLabel>
                                                        <ImageUpload
                                                            imageName={this.state.feature_portrait_image}
                                                            imageType="feature_list"
                                                            imagePosition={"feature_portrait_image"}
                                                            imageTemp={this.state.feature_portrait_image_temp}
                                                            imageFolderTemp={this.state.feature_portrait_image_folder_temp}
                                                            handlerFromParent={this.handleChildImageSelect}
                                                        />
                                                    </FormGroup>
                                                    <legend/>
                                                    <FormGroup style={{
                                                        marginBottom: "50px",
                                                        marginTop: "50px"
                                                    }}>
                                                        <ControlLabel>Landscape</ControlLabel>
                                                        <ImageUpload
                                                            imageName={this.state.feature_landscape_image}
                                                            imageType="feature_list"
                                                            imagePosition={"feature_landscape_image"}
                                                            imageTemp={this.state.feature_landscape_image_temp}
                                                            imageFolderTemp={this.state.feature_landscape_image_folder_temp}
                                                            handlerFromParent={this.handleChildImageSelect}
                                                        />
                                                    </FormGroup>
                                                    <legend/>
                                                    <FormGroup style={{
                                                        marginBottom: "50px",
                                                        marginTop: "50px"
                                                    }}>
                                                        <ControlLabel>Title</ControlLabel>
                                                        <ImageUpload
                                                            imageName={this.state.feature_title_image}
                                                            imageType="feature_list"
                                                            imagePosition={"feature_title_image"}
                                                            imageTemp={this.state.feature_title_image_temp}
                                                            imageFolderTemp={this.state.feature_title_image_folder_temp}
                                                            handlerFromParent={this.handleChildImageSelect}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                        </Grid>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>

            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedEditFeatureSection = connect(mapStateToProps)(EditFeatureSection);
const connectedEditFeatureSectionTrans = withNamespaces('common')(connectedEditFeatureSection);
export {connectedEditFeatureSectionTrans as EditFeatureSection};
