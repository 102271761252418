import React, {Component} from "react";
import {Redirect} from "react-router";
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl, ButtonGroup, Well, Glyphicon} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import Switch from "react-bootstrap-switch";
import axios from "axios/index";
import Button from "../../components/CustomButton/CustomButton.jsx";
import {DropDownGenres} from "../../components/CustomDropDown/DropDownGenres";
import {DropDownCountries} from "../../components/CustomDropDown/DropDownCountries";
import {DropDownRating} from "../../components/CustomDropDown/DropDownRating";
import {DropDownSvodCategory} from "../../components/CustomDropDown/DropDownSvodCategory";
import {DataVodSeasons} from "../../components/Middleware/DataVodSeasons";
import {DataVodEpisodes} from "../../components/Middleware/DataVodEpisodes";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import {SearchBarTvShows} from "../../components/Tmdb/SearchBarTvShows";
import ModalSave from "../../components/CustomModals/ModalSave";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import {DropDownContentProviders} from "../../components/CustomDropDown/DropDownContentProviders";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

const adminArray = ['super-admin', 'admin']

class EditVodSeries extends Component {

    constructor(props) {
        super(props);
        this.state = {

            tvodObject: [],
            loading: true,
            redirect: false,
            seriesRedirect: false,
            back_button_text: "Cancel",

            showSaveModal: false,

            loadSeasons: false,
            loadEpisodes: false,

            vod_series_id: null,
            vod_series_position: null,
            vod_series_status: false,
            vod_series_name_short: null,
            vod_series_name_long: null,
            vod_series_description: null,
            vod_series_image: null,
            vod_series_image_wide: null,
            vod_series_image_backdrop: null,
            vod_series_genre_id: null,
            vod_series_genre_names: null,
            vod_series_cat_id: null,
            vod_series_vut_category_display: null,
            vod_series_rating: null,
            vod_series_number_of_seasons: 0,
            vod_series_created_by: null,
            vod_series_first_air_date: null,
            vod_series_last_air_date: null,
            vod_series_tmdb_id: null,
            vod_series_imdb_id: null,
            vod_series_in_production: null,
            vod_series_production_status: false,
            vod_series_production_countries: null,
            vod_series_production_companies: null,

            vod_series_image_temp: false,
            vod_series_image_backdrop_temp: false,
            vod_series_image_wide_temp: false,

            vod_series_image_folder_temp: "",
            vod_series_image_backdrop_folder_temp: "",
            vod_series_image_wide_folder_temp: "",

            tmdb_status: false,
            tmdb_type: "",

            vodSeriesStatusSwitch: false,
            vodSeasonsTitle: "Seasons",
            vodSelectedSeasonNumber: null,
            vodSelectedSeasonMiddlewareID: null,
            refreshEpisodes: false,
            _notificationSystem: null,

            addAllCountriesInCountryBlock: false,
            vod_series_countries_block: null,

            vod_series_content_provider: null,
            current_user: null,
            user_content_provider: null,
            custom_data: null,

            loginRequiredStatus: false,
            vod_series_login_required: null,


        };

        // this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.handleChildCountrySelect = this.handleChildCountrySelect.bind(this);
        this.handleChildGenreSelect = this.handleChildGenreSelect.bind(this);
        this.handleChildCategorySelect = this.handleChildCategorySelect.bind(this);
        this.handleChildRatingSelect = this.handleChildRatingSelect.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);
        this.handleChildTmdbSelect = this.handleChildTmdbSelect.bind(this);
        this.handleChildSeasons = this.handleChildSeasons.bind(this);
        this.handleChildEpisodes = this.handleChildEpisodes.bind(this);
        this.handleChildCountryBlockSelect = this.handleChildCountryBlockSelect.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);

        this.handleInitialLoad()
    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
            });
        }
    }

    handleInitialLoad() {

        const {handle} = this.props.match.params

        if (handle !== undefined) {
            sessionStorage.setItem('series_edit', handle);
        }

    }

    handleChildSeasons(season, seasonName, status, seasonMiddlewareId) {

        sessionStorage.setItem('series_season_selected', season);
        sessionStorage.setItem('series_season_id', seasonMiddlewareId);

        this.setState({
            loadEpisodes: status,
            vodSeasonsTitle: seasonName,
            vodSelectedSeasonNumber: season,
            vodSelectedSeasonMiddlewareID: seasonMiddlewareId
        })
    }

    handleChildEpisodes() {

        // this.setState({
        //     loadEpisodes: status,
        //     vodSeasonsTitle: seasonName,
        //     vodSelectedSeason: season
        // })
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})
        window.scroll(0, 0)

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/VodSeries/" + handle;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        if (handle === 'new') {
            this.setState({loading: false})
            this.getPosition()
        } else {
            axios
                .get(url, axiosConfig)
                .then(res => {

                    const seriesObject = res.data[0];
                    const loading = false;

                    let vodSeriesStatusSwitch = seriesObject.vod_series_status === 1;
                    let loginRequiredStatus = seriesObject.vod_series_login_required === 1;

                    let genreIDs = []
                    let genreNames = []
                    let categoryIDs = []
                    let countries = []
                    let companies = []

                    if (seriesObject.vod_series_genre_id !== null) {
                        let temp = seriesObject.vod_series_genre_id.toString();
                        genreIDs = temp.split(",")
                    }

                    if (seriesObject.vod_series_cat_id !== null) {
                        let tempCat = seriesObject.vod_series_cat_id.toString();
                        categoryIDs = tempCat.split(",")
                    }

                    if (seriesObject.vod_series_genre_names !== null) {
                        let tempName = seriesObject.vod_series_genre_names.toString();
                        genreNames = tempName.split(",")
                    }

                    if (seriesObject.vod_series_production_countries !== null) {
                        let tempCountry = seriesObject.vod_series_production_countries.toString();
                        countries = tempCountry.split(",")
                    }

                    if (seriesObject.vod_series_production_companies !== null) {
                        let tempCompany = seriesObject.vod_series_production_companies.toString();
                        companies = tempCompany.split(",")
                    }

                    let countriesBlocked = []
                    if (seriesObject.vod_series_countries_block !== null) {
                        let tempCountries = seriesObject.vod_series_countries_block.toString();
                        countriesBlocked = tempCountries.split(",")
                    }

                    sessionStorage.setItem('series_main_tmdb_id', seriesObject.vod_series_tmdb_id);

                    this.setState({
                        back_button_text: "Back",
                        seriesObject,
                        loading,
                        vodSeriesStatusSwitch,
                        loginRequiredStatus,
                        loadSeasons: true,
                        loadEpisodes: true,
                        vod_series_id: seriesObject.vod_series_id,
                        vod_series_position: seriesObject.vod_series_position,
                        vod_series_status: vodSeriesStatusSwitch,
                        vod_series_login_required: loginRequiredStatus,
                        vod_series_name_short: seriesObject.vod_series_name_short,
                        vod_series_name_long: seriesObject.vod_series_name_long,
                        vod_series_description: seriesObject.vod_series_description,
                        vod_series_image: seriesObject.vod_series_image,
                        vod_series_image_wide: seriesObject.vod_series_image_wide,
                        vod_series_image_backdrop: seriesObject.vod_series_image_backdrop,
                        vod_series_genre_id: genreIDs,
                        vod_series_genre_names: genreNames,
                        vod_series_cat_id: categoryIDs,
                        vod_series_vut_category_display: seriesObject.vod_series_vut_category_display,
                        vod_series_rating: seriesObject.vod_series_rating,
                        vod_series_number_of_seasons: seriesObject.vod_series_number_of_seasons,
                        vod_series_created_by: seriesObject.vod_series_created_by,
                        vod_series_first_air_date: seriesObject.vod_series_first_air_date,
                        vod_series_last_air_date: seriesObject.vod_series_last_air_date,
                        vod_series_tmdb_id: seriesObject.vod_series_tmdb_id,
                        vod_series_imdb_id: seriesObject.vod_series_imdb_id,
                        vod_series_in_production: seriesObject.vod_series_in_production,
                        vod_series_production_status: seriesObject.vod_series_production_status,
                        vod_series_production_countries: countries,
                        vod_series_production_companies: companies,
                        vod_series_countries_block: countriesBlocked,
                        current_user: user,
                        user_content_provider: seriesObject.vod_series_content_provider,
                        custom_data: seriesObject.custom_data,

                    });

                }).catch(error => {
                this.setState({loading: false});
                this.props.dispatch(userActions.logoutUnauthorized());
                console.log(error);
            });

        }
    }

    getPosition() {

        const url = process.env.REACT_APP_API_URL + "/VodSeries/position";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url, axiosConfig)
            .then(res => {

                const vodSeriesPosition = res.data[0];

                this.setState({
                    vod_series_position: vodSeriesPosition.VodSeriesPosition

                });
            });

    }

    handleChildTmdbSelect(data) {


        let seriesData = data.data;
        let genres = seriesData.genres;
        let genreIDs = [];
        let genreNames = [];
        let productionNames = [];
        let production = seriesData.production_companies;
        let createdNames = [];
        let created = seriesData.created_by;
        let productionCountries = [];
        let countries = seriesData.origin_country;

        for (let i = 0; i < countries.length; i++) {
            productionCountries.push(countries[i])
        }

        for (let i = 0; i < genres.length; i++) {
            genreIDs.push(genres[i].id)
            genreNames.push(genres[i].name)
        }


        for (let i = 0; i < production.length; i++) {
            productionNames.push(production[i].name)
        }

        for (let i = 0; i < created.length; i++) {
            createdNames.push(created[i].name)
        }


        sessionStorage.setItem('series_main_tmdb_id', seriesData.id);

        this.setState({
            tmdb_status: true,
            vod_series_name_short: seriesData.name,
            vod_series_name_long: seriesData.original_name,
            vod_series_description: seriesData.overview,
            vod_series_production_countries: productionCountries,
            vod_series_first_air_date: seriesData.first_air_date,
            vod_series_last_air_date: seriesData.last_air_date,
            vod_series_tmdb_id: seriesData.id,
            vod_series_production_companies: productionNames,
            vod_series_created_by: createdNames.join(","),
            vod_series_image: seriesData.poster_path,
            vod_series_image_wide: seriesData.backdrop_path,
            vod_series_image_backdrop: seriesData.backdrop_path,
            vod_series_genre_id: genres === null ? 0 : genreIDs,
            vod_series_genre_names: genres === null ? 0 : genreNames,

        });
    }

    handleChildImageSelect(folder, filename, imagePosition) {

        if (filename !== null) {
            if (imagePosition === "vod_series_image") {
                this.setState({
                    vod_series_image_temp: true,
                    vod_series_image: filename,
                    vod_series_image_folder_temp: folder
                });
            }
            if (imagePosition === "vod_series_backdrop") {

                this.setState({
                    vod_series_image_backdrop_temp: true,
                    vod_series_image_backdrop: filename,
                    vod_series_image_backdrop_folder_temp: folder
                });

            }
            if (imagePosition === "vod_series_image_wide") {
                this.setState({
                    vod_series_image_wide_temp: true,
                    vod_series_image_wide: filename,
                    vod_series_image_wide_folder_temp: folder
                });
            }
        }

    }

    handleChildCountrySelect(data) {

        if (data !== undefined) {
            let countryIDs = []
            let countryNames = []

            for (let i = 0; i < data.length; i++) {
                countryIDs.push(data[i].value)
            }

            for (let i = 0; i < data.length; i++) {
                countryNames.push(data[i].label)
            }

            this.setState({
                vod_series_production_countries: data === null ? [] : countryIDs,
            });
        }
    }

    handleChildGenreSelect(data) {

        if (data !== undefined) {
            let genreIDs = []
            let genreNames = []

            for (let i = 0; i < data.length; i++) {
                genreIDs.push(data[i].value)
            }

            for (let i = 0; i < data.length; i++) {
                genreNames.push(data[i].label)
            }

            this.setState({
                vod_series_genre_id: data === null ? [] : genreIDs,
                vod_series_genre_names: data === null ? [] : genreNames
            });
        }
    }

    handleChildCategorySelect(data) {

        if (data !== undefined) {
            let categoryIDs = []
            let categoryNames = []

            for (let i = 0; i < data.length; i++) {
                categoryIDs.push(data[i].value)
            }

            for (let i = 0; i < data.length; i++) {
                categoryNames.push(data[i].label)
            }

            this.setState({
                vod_series_cat_id: data === null ? [] : categoryIDs,
                vod_series_vut_category_display: categoryNames === [] ? "" : categoryNames.toString()
            });
        }
    }

    handleChildRatingSelect(data) {

        if (data !== undefined) {
            this.setState({
                vod_series_rating: data === null ? 0 : data.value,
            });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'vodSeriesStatusSwitch' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'vodSeriesStatusSwitch') {
            this.setState({vodSeriesStatusSwitch: state})
        }
        if (elem.props.name === 'loginRequiredStatusSwitch') {
            this.setState({loginRequiredStatus: state})
        }
    }

    submitTmdbImage() {

        if (this.state.tmdb_status) {

            const url = process.env.REACT_APP_TMDB_IMAGE_API_URL;
            const imgUrl = "https://image.tmdb.org/t/p/w500" + this.state.vod_series_image;

            let bodyFormData = new FormData();
            bodyFormData.set('filename', this.state.vod_series_image.replace(/\//g, ''));
            bodyFormData.set('location', "vod_series");
            bodyFormData.set('url', imgUrl);

            axios.post(url, bodyFormData)
                .then((res) => {
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })


            const urlBackdrop = process.env.REACT_APP_TMDB_IMAGE_API_URL;
            const imgUrlBackdrop = "https://image.tmdb.org/t/p/w1280" + this.state.vod_series_image_backdrop;


            let bodyFormDataBackdrop = new FormData();
            bodyFormDataBackdrop.set('filename', "w1280_" + this.state.vod_series_image_backdrop.replace(/\//g, ''));
            bodyFormDataBackdrop.set('location', "vod_series");
            bodyFormDataBackdrop.set('url', imgUrlBackdrop);

            axios.post(urlBackdrop, bodyFormDataBackdrop)
                .then((res) => {
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })


            const urlwide = process.env.REACT_APP_TMDB_IMAGE_API_URL;
            const imgUrlwide = "https://image.tmdb.org/t/p/w300" + this.state.vod_series_image_wide;


            let bodyFormDatawide = new FormData();
            bodyFormDatawide.set('filename', "w300_" + this.state.vod_series_image_wide.replace(/\//g, ''));
            bodyFormDatawide.set('location', "vod_series");
            bodyFormDatawide.set('url', imgUrlwide);

            axios.post(urlwide, bodyFormDatawide)
                .then((res) => {
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })

        }


    }

    submitPosterImage(filename_poster) {

        if (this.state.vod_series_image_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.vod_series_image_folder_temp);
            bodyFormData.set('location', "vod_series");
            bodyFormData.set('filename', filename_poster);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({vod_series_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    submitWideImage(filename_wide) {

        if (this.state.vod_series_image_wide_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.vod_series_image_wide_folder_temp);
            bodyFormData.set('location', "vod_series");
            bodyFormData.set('filename', filename_wide);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({vod_series_image_wide_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    submitBackDropImage(filename_backdrop) {

        if (this.state.vod_series_image_backdrop_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.vod_series_image_backdrop_folder_temp);
            bodyFormData.set('location', "vod_series");
            bodyFormData.set('filename', filename_backdrop);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({vod_series_image_backdrop_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    updateSeries() {

        this.setState({loading: true})
        this.submitTmdbImage();

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/VodSeries/" + handle;
        let tempSeriesStatus = this.state.vodSeriesStatusSwitch === true ? 1 : 0;
        let tempLoginRequiredStatus = this.state.loginRequiredStatus === true ? 1 : 0;

        let filename_poster = ""
        let filename_wide = ""
        let filename_backdrop = ""

        if (!this.state.tmdb_status) {

            if (this.state.vod_series_image_temp) {
                let timeUTC = new Date().getTime()
                filename_poster = timeUTC + "_" + this.state.vod_series_image
                this.setState({vod_series_image: filename_poster})
                this.submitPosterImage(filename_poster);
            } else {
                filename_poster = this.state.vod_series_image
            }

            if (this.state.vod_series_image_wide_temp) {
                let timeUTC = new Date().getTime()
                filename_wide = timeUTC + "_" + this.state.vod_series_image_wide
                this.setState({vod_series_image_wide: filename_wide})
                this.submitWideImage(filename_wide);
            } else {
                filename_wide = this.state.vod_series_image_wide
            }


            if (this.state.vod_series_image_backdrop_temp) {
                let timeUTC = new Date().getTime()
                filename_backdrop = timeUTC + "_" + this.state.vod_series_image_backdrop
                this.setState({vod_series_image_backdrop: filename_backdrop})
                this.submitBackDropImage(filename_backdrop);
            } else {
                filename_backdrop = this.state.vod_series_image_backdrop
            }
        } else {
            filename_poster = this.state.vod_series_image !== null ? this.state.vod_series_image.replace(/\//g, '') : null
            filename_wide = this.state.vod_series_image_wide !== null ? "w300_" + this.state.vod_series_image_wide.replace(/\//g, '') : null
            filename_backdrop = this.state.vod_series_image_backdrop !== null ? "w1280_" + this.state.vod_series_image_backdrop.replace(/\//g, '') : null
        }

        // Send a PUT request
        var postData = {
            vod_series: {
                vod_series_id: this.state.vod_series_id,
                vod_series_position: this.state.vod_series_position,
                vod_series_status: tempSeriesStatus,
                vod_series_login_required: tempLoginRequiredStatus,
                vod_series_name_short: this.state.vod_series_name_short,
                vod_series_name_long: this.state.vod_series_name_long,
                vod_series_description: this.state.vod_series_description,
                vod_series_image: filename_poster,
                vod_series_image_wide: filename_wide,
                vod_series_image_backdrop: filename_backdrop,
                vod_series_genre_id: this.state.vod_series_genre_id === null ? null : this.state.vod_series_genre_id.join(),
                vod_series_genre_names: this.state.vod_series_genre_names === null ? null : this.state.vod_series_genre_names.join(),
                vod_series_cat_id: this.state.vod_series_cat_id === null ? null : this.state.vod_series_cat_id.join(),
                vod_series_vut_category_display: this.state.vod_series_vut_category_display,
                vod_series_rating: this.state.vod_series_rating,
                vod_series_number_of_seasons: this.state.vod_series_number_of_seasons,
                vod_series_created_by: this.state.vod_series_created_by,
                vod_series_first_air_date: this.state.vod_series_first_air_date,
                vod_series_last_air_date: this.state.vod_series_last_air_date,
                vod_series_tmdb_id: this.state.vod_series_tmdb_id,
                vod_series_imdb_id: this.state.vod_series_imdb_id,
                vod_series_in_production: this.state.vod_series_in_production,
                vod_series_production_status: this.state.vod_series_production_status,
                vod_series_production_countries: this.state.vod_series_production_countries === null ? null : this.state.vod_series_production_countries.join(),
                vod_series_production_companies: this.state.vod_series_production_companies === null ? null : this.state.vod_series_production_companies.join(),
                vod_series_countries_block: this.state.vod_series_countries_block === null ? null : this.state.vod_series_countries_block.join(),
                vod_series_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data,
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 401) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                } else if (res.data.error === 301) {
                    //handle errror
                    this.setState({loading: false})
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    //handle errror
                    this.setState({loading: false})
                    this.notification("failed")
                } else if (res.data.error === 303) {
                    //handle errror
                    this.setState({loading: false})
                    this.notification("failed")

                } else {
                    this.setState({loading: false})
                    this.notification("success")

                    this.submitPosterImage(filename_poster);
                    this.submitWideImage(filename_wide);
                    this.submitBackDropImage(filename_backdrop);
                }

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.notification("failed")
            })


    }

    createSeries() {

        this.setState({loading: true})
        const url = process.env.REACT_APP_API_URL + "/VodSeries";
        let tempSeriesStatus = this.state.vodSeriesStatusSwitch === true ? 1 : 0;
        let tempLoginRequiredStatus = this.state.loginRequiredStatus === true ? 1 : 0;

        let filename_poster = ""
        let filename_wide = ""
        let filename_backdrop = ""

        this.submitTmdbImage();

        if (!this.state.tmdb_status) {

            if (this.state.vod_series_image_temp) {
                let timeUTC = new Date().getTime()
                filename_poster = timeUTC + "_" + this.state.vod_series_image
                this.setState({vod_series_image: filename_poster})
                this.submitPosterImage(filename_poster);
            } else {
                filename_poster = this.state.vod_series_image
            }

            if (this.state.vod_series_image_wide_temp) {
                let timeUTC = new Date().getTime()
                filename_wide = timeUTC + "_" + this.state.vod_series_image_wide
                this.setState({vod_series_image_wide: filename_wide})
                this.submitWideImage(filename_wide);
            } else {
                filename_wide = this.state.vod_series_image_wide
            }


            if (this.state.vod_series_image_backdrop_temp) {
                let timeUTC = new Date().getTime()
                filename_backdrop = timeUTC + "_" + this.state.vod_series_image_backdrop
                this.setState({vod_series_image_backdrop: filename_backdrop})
                this.submitBackDropImage(filename_backdrop);
            } else {
                filename_backdrop = this.state.vod_series_image_backdrop
            }
        } else {
            filename_poster = this.state.vod_series_image !== null ? this.state.vod_series_image.replace(/\//g, '') : null
            filename_wide = this.state.vod_series_image_wide !== null ? "w300_" + this.state.vod_series_image_wide.replace(/\//g, '') : null
            filename_backdrop = this.state.vod_series_image_backdrop !== null ? "w1280_" + this.state.vod_series_image_backdrop.replace(/\//g, '') : null
        }

        var postData = {
            vod_series: {
                vod_series_position: this.state.vod_series_position,
                vod_series_status: tempSeriesStatus,
                vod_series_login_required: tempLoginRequiredStatus,
                vod_series_name_short: this.state.vod_series_name_short,
                vod_series_name_long: this.state.vod_series_name_long,
                vod_series_description: this.state.vod_series_description,
                vod_series_image: filename_poster,
                vod_series_image_wide: filename_wide,
                vod_series_image_backdrop: filename_backdrop,
                vod_series_genre_id: this.state.vod_series_genre_id === null ? null : this.state.vod_series_genre_id.join(),
                vod_series_genre_names: this.state.vod_series_genre_names === null ? null : this.state.vod_series_genre_names.join(),
                vod_series_cat_id: this.state.vod_series_cat_id === null ? null : this.state.vod_series_cat_id.join(),
                vod_series_vut_category_display: this.state.vod_series_vut_category_display,
                vod_series_rating: this.state.vod_series_rating,
                vod_series_number_of_seasons: this.state.vod_series_number_of_seasons,
                vod_series_created_by: this.state.vod_series_created_by,
                vod_series_first_air_date: this.state.vod_series_first_air_date,
                vod_series_last_air_date: this.state.vod_series_last_air_date,
                vod_series_tmdb_id: this.state.vod_series_tmdb_id,
                vod_series_imdb_id: this.state.vod_series_imdb_id,
                vod_series_in_production: this.state.vod_series_in_production,
                vod_series_production_status: this.state.vod_series_production_status,
                vod_series_production_countries: this.state.vod_series_production_countries === null ? null : this.state.vod_series_production_countries.join(),
                vod_series_production_companies: this.state.vod_series_production_companies === null ? null : this.state.vod_series_production_companies.join(),
                vod_series_countries_block: this.state.vod_series_countries_block === null ? null : this.state.vod_series_countries_block.join(),
                vod_series_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data,

            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 401) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                } else if (res.data.error === 301) {
                    //handle errror
                    this.setState({loading: false})
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    //handle errror
                    this.setState({loading: false})
                    this.notification("failed")
                } else if (res.data.error === 303) {
                    //handle errror
                    this.setState({loading: false})
                    this.notification("failed")
                } else {

                    this.setState({loading: false, loadSeasons: true, loadEpisodes: true})
                    //this.submitPosterImage();

                    const vod = res.data;
                    this.setState({vod_series_id: vod.vod_series_id})
                    this.notification("success")

                    this.submitPosterImage(filename_poster);
                    this.submitWideImage(filename_wide);
                    this.submitBackDropImage(filename_backdrop);

                    this.setSeriesRedirect();

                }


            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.notification("failed")
            })

    }

    cancelSeriesEdit() {
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    setSeriesRedirect = () => {
        this.setState({
            seriesRedirect: true,
            back_button_text: "Back"
        })
    }

    renderRedirect = () => {

        let path = "/movies/vseries";
        let pathSeries = "/edit-series/" + this.state.vod_series_id;

        if (this.state.redirect) {

            sessionStorage.removeItem('series_season_selected');
            sessionStorage.removeItem('series_season_id');
            sessionStorage.removeItem('series_main_tmdb_id');
            sessionStorage.removeItem('series_edit');

            return <Redirect push to={path}/>
        }

        if (this.state.seriesRedirect) {
            return <Redirect push to={pathSeries}/>
        }

    }

    renderSeasons = () => {

        if (this.state.loadSeasons) {
            return (
                <Grid fluid>
                    <Row>
                        <Card
                            title={<legend>{this.state.vodSeasonsTitle}</legend>}
                            category={<code>Click season to manage it</code>}
                            content={
                                <div>
                                    <DataVodSeasons
                                        seriesID={this.state.vod_series_id}
                                        providerID={this.state.vod_series_tmdb_id}
                                        handlerFromParent={this.handleChildSeasons}
                                    />
                                </div>
                            }
                        />
                    </Row>
                </Grid>
            );
        }
    }

    renderEpisodes = () => {

        if (this.state.loadEpisodes && this.state.vodSelectedSeasonMiddlewareID !== null) {
            return (
                <Grid fluid>
                    <Row>
                        <Card
                            title={
                                <legend>{this.state.vodSeasonsTitle === "Seasons" ? "" : this.state.vodSeasonsTitle + " - Episodes"}</legend>}
                            content={
                                <div>
                                    <DataVodEpisodes
                                        seriesID={this.state.vod_series_id}
                                        providerID={this.state.vod_series_tmdb_id}
                                        seasonID={this.state.vodSelectedSeasonMiddlewareID}
                                        seasonNumber={this.state.vodSelectedSeasonNumber}
                                        seriesCatID={this.state.vod_series_cat_id}
                                        seriesCatDisplay={this.state.vod_series_vut_category_display}
                                        seriesName={this.state.vod_series_name_short}
                                        refreshEpisodes={true}
                                        handlerFromParent={this.handleChildEpisodes}
                                    />
                                </div>
                            }
                        />
                    </Row>
                </Grid>
            );
        }
    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createSeries();
            } else {
                this.updateSeries();
            }
        }
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Series: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.vod_series_name_long}
            />
        }
    }

    //1. Constructor
    //2. ComponentDidMount
    //3. Render
    //4. Function
    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Series updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Failed to update Series</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    renderTmdbSeriesSearch = () => {

        if (process.env.REACT_APP_TMDB === "1") {
            return (
                <FormGroup>
                    <div className="pull-right">
                        <SearchBarTvShows handlerFromParent={this.handleChildTmdbSelect}/>
                    </div>
                </FormGroup>
            )
        }
    }

    onClickAddAllCountriesCountryBlock() {
        this.setState({addAllCountriesInCountryBlock: true})
    }

    handleChildCountryBlockSelect(data) {

        let countryIDs = []
        let countryNames = []

        for (let i = 0; i < data.length; i++) {
            countryIDs.push(data[i].value)
        }

        for (let i = 0; i < data.length; i++) {
            countryNames.push(data[i].label)
        }

        //console.log(data);
        this.setState({
            vod_series_countries_block: data === null ? 0 : countryIDs, addAllCountriesInCountryBlock: false
        });
    }

    renderCountryBlockFields = () => {

        return (
            <div>
                <Row>
                    <FormGroup>
                        {/*<ControlLabel>Countries to block</ControlLabel>*/}
                        <DropDownCountries
                            countrySelected={this.state.vod_series_countries_block}
                            name='countryBlockSelect'
                            handlerFromParent={this.handleChildCountryBlockSelect}
                            hadlerAddAllCountries={this.state.addAllCountriesInCountryBlock}
                        />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                        <Button
                            onClick={this.onClickAddAllCountriesCountryBlock.bind(this)}
                        >
                            Add All countries
                        </Button>
                    </FormGroup>
                </Row>
                <Row>
                    <Well><Glyphicon glyph="question-sign"/> By enabling the Country Block
                        functionality,the Series will be hidden for the countries specified.
                    </Well>
                </Row>
            </div>
        );

    }

    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <ControlLabel>Content Provider</ControlLabel>
                                <DropDownContentProviders
                                    contentProviderSelected={this.state.user_content_provider}
                                    name='contentProviderSelect'
                                    handlerFromParent={this.handleChildContentProviderSelect}
                                    disabled={this.state.loading}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                )
            }
        }

    }

    render() {
        return (
            <div>
                <NotificationSystem ref="notificationSystem" style={style}/>
                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="main-content">
                    <div className="sweet-loader">
                        <ScaleLoader
                            css={override}
                            color={'#797979'}
                            loading={this.state.loading}
                        />
                    </div>

                    <Card
                        content={
                            <div>
                                <Grid fluid>
                                    <Row>
                                        <Col md={6}>
                                            <ButtonGroup>
                                                <Button
                                                    onClick={() => this.setState({showSaveModal: true})}
                                                    bsStyle="info" fill wd
                                                    name={"saveButton"}>
                                                    Save
                                                </Button>
                                                <Button
                                                    bsStyle="default"
                                                    fill wd name={"cancelButton"}
                                                    onClick={this.cancelSeriesEdit.bind(this)}>
                                                    {this.state.back_button_text}
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                        <Col md={6}>
                                            {this.renderTmdbSeriesSearch()}
                                        </Col>
                                    </Row>
                                </Grid>
                            </div>
                        }
                    />

                    <Card
                        content={
                            <div>
                                <legend>{this.state.vod_series_name_long} - General Settings</legend>
                                <Grid fluid>
                                    <Row>
                                        <Col sm={2}>
                                            <Row>
                                                <ImageUpload
                                                    imageName={this.state.vod_series_image}
                                                    imageType="vod_series"
                                                    imagePosition={"vod_series_image"}
                                                    imageTemp={this.state.vod_series_image_temp}
                                                    imageFolderTemp={this.state.vod_series_image_folder_temp}
                                                    imageSource={this.state.tmdb_status === undefined ? false : this.state.tmdb_status}
                                                    handlerFromParent={this.handleChildImageSelect}
                                                    imageTmdbType={"poster_path"}

                                                />
                                            </Row>
                                            <Row>
                                                <div>

                                                </div>
                                            </Row>
                                        </Col>
                                        <Col sm={8}>
                                            <Row>
                                                <Col sm={2}>
                                                    <FormGroup>
                                                        <p className="category">{this.props.t('global.status', {framework: "react-i18next"})}</p>
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            defaultValue={false}
                                                            name='vodSeriesStatusSwitch'
                                                            value={this.state.vodSeriesStatusSwitch}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup>
                                                        <p className="category">Login Required</p>
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            defaultValue={false}
                                                            name='loginRequiredStatusSwitch'
                                                            value={this.state.loginRequiredStatus}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col sm={3}>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('global.position', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl placeholder="Position"
                                                                     type="number"
                                                                     name={"vod_series_position"}
                                                                     onChange={this.handleInputChange}
                                                                     value={this.state.vod_series_position || ""}/>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                </Col>
                                                <Col sm={3}>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={3}>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('series_edit.name_short', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl placeholder="Enter Short Name" type="text"
                                                                     onChange={this.handleInputChange}
                                                                     name="vod_series_name_short"
                                                                     value={this.state.vod_series_name_short || ""}/>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={9}>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('series_edit.name_long', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl placeholder="Enter Long Name" type="text"
                                                                     onChange={this.handleInputChange}
                                                                     name="vod_series_name_long"
                                                                     value={this.state.vod_series_name_long || ""}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('series_edit.description', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl componentClass="textarea"
                                                                     type="text"
                                                                     rows={5}
                                                                     name="vod_series_description"
                                                                     onChange={this.handleInputChange}
                                                                     placeholder="Enter the description"
                                                                     value={this.state.vod_series_description || ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            {this.renderContentProvider()}

                                        </Col>
                                    </Row>

                                </Grid>
                            </div>
                        }
                    />

                    <Card
                        title={
                            <legend>{this.props.t('series_edit.additional_settings_legend', {framework: "react-i18next"})}</legend>}
                        content={
                            <div>
                                <Grid fluid>
                                    <Row>

                                        <Col sm={3}>
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <ControlLabel
                                                            className="category">{this.props.t('series_edit.genre_dropdown', {framework: "react-i18next"})}</ControlLabel>
                                                        <DropDownGenres
                                                            genreSelected={this.state.vod_series_genre_id}
                                                            name='genreSelect'
                                                            handlerFromParent={this.handleChildGenreSelect}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <ControlLabel
                                                            className="category">{this.props.t('series_edit.country_dropdown', {framework: "react-i18next"})}</ControlLabel>
                                                        <DropDownCountries
                                                            countrySelected={this.state.vod_series_production_countries}
                                                            name='countrySelect'
                                                            handlerFromParent={this.handleChildCountrySelect}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <ControlLabel
                                                            className="category">{this.props.t('series_edit.rating_dropdown', {framework: "react-i18next"})}</ControlLabel>
                                                        <DropDownRating
                                                            ratingSelected={this.state.vod_series_rating}
                                                            name='ratingSelect'
                                                            handlerFromParent={this.handleChildRatingSelect}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <ControlLabel
                                                            className="category">{this.props.t('series_edit.category_dropdown', {framework: "react-i18next"})}</ControlLabel>
                                                        <DropDownSvodCategory
                                                            categorySelected={this.state.vod_series_cat_id}
                                                            name='categorySelect'
                                                            handlerFromParent={this.handleChildCategorySelect}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={3}>
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('series_edit.producer', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl placeholder="Enter Long Name"
                                                                     type="text"
                                                                     onChange={this.handleInputChange}
                                                                     name="vod_series_production_companies"
                                                                     value={this.state.vod_series_production_companies || ""}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('series_edit.created_by', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl placeholder="Created by"
                                                                     type="text"
                                                                     onChange={this.handleInputChange}
                                                                     name="vod_series_created_by"
                                                                     value={this.state.vod_series_created_by || ""}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={3}>
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('series_edit.first_air_date', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl placeholder="first_air_date"
                                                                     type="text"
                                                                     onChange={this.handleInputChange}
                                                                     name="vod_series_first_air_date"
                                                                     value={this.state.vod_series_first_air_date || ""}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('series_edit.last_air_date', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl placeholder="last_air_date"
                                                                     type="text"
                                                                     onChange={this.handleInputChange}
                                                                     name="vod_series_last_air_date"
                                                                     value={this.state.vod_series_last_air_date || ""}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </Grid>
                            </div>
                        }
                    />

                    <Card
                        title={<legend>Assets</legend>}
                        content={
                            <div>
                                <Grid fluid>
                                    <Col sm={3}>
                                        <ImageUpload
                                            imageName={this.state.vod_series_image}
                                            imageType={"vod_series"}
                                            imagePosition={"vod_series_image"}
                                            imageTemp={this.state.vod_series_image_temp}
                                            imageFolderTemp={this.state.vod_series_image_folder_temp}
                                            handlerFromParent={this.handleChildImageSelect}
                                            imageSource={this.state.tmdb_status === undefined ? false : this.state.tmdb_status}
                                            imageTmdbType={"poster_path"}

                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <ImageUpload
                                            imageName={this.state.vod_series_image_backdrop}
                                            imageType={"vod_series"}
                                            imagePosition={"vod_series_backdrop"}
                                            imageTemp={this.state.vod_series_image_backdrop_temp}
                                            imageFolderTemp={this.state.vod_series_image_backdrop_folder_temp}
                                            handlerFromParent={this.handleChildImageSelect}
                                            imageSource={this.state.tmdb_status === undefined ? false : this.state.tmdb_status}
                                            imageTmdbType={"backdrop_path"}

                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <ImageUpload
                                            imageName={this.state.vod_series_image_wide}
                                            imageType={"vod_series"}
                                            imagePosition={"vod_series_image_wide"}
                                            imageTemp={this.state.vod_series_image_wide_temp}
                                            imageFolderTemp={this.state.vod_series_image_wide_folder_temp}
                                            handlerFromParent={this.handleChildImageSelect}
                                            imageSource={this.state.tmdb_status === undefined ? false : this.state.tmdb_status}
                                            imageTmdbType={"poster_path_wide"}

                                        />
                                    </Col>
                                </Grid>
                            </div>
                        }
                    />

                    <Card
                        content={
                            <div>
                                <h5>Country Block</h5>
                                <legend/>
                                <Grid fluid>
                                    <Row>
                                        <Col md={12}>
                                            {this.renderCountryBlockFields()}
                                        </Col>
                                    </Row>
                                </Grid>
                            </div>
                        }
                    />

                    {this.renderSeasons()}

                    {this.renderEpisodes()}


                </div>
            </div>
        );
    }
}

function

mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const
    connectedEditVodSeries = connect(mapStateToProps)(EditVodSeries);
const
    connectedEditVodSeriesTrans = withNamespaces('common')(connectedEditVodSeries);
export {
    connectedEditVodSeriesTrans
        as
            EditVodSeries
};
