import React, {Component} from "react";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import axios from "axios/index";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {AxiosHeader} from "../../functions/GlobalFunctions";

class DropDownCountries extends Component {

    constructor(props) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);
        this.state = {
            loading: true,
            gCountriesArray: []
        };

    }

    handleSelect(value) {
        this.props.handlerFromParent(value);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.hadlerAddAllCountries !== prevProps.hadlerAddAllCountries) {
            if(this.props.hadlerAddAllCountries === true){
                this.addAllCoutnries()
            }
        }
    }

    addAllCoutnries(){
        this.props.handlerFromParent(this.state.gCountriesArray);
    }

    componentDidMount() {

        if (AxiosHeader.getConfig() !== null) {

            const url = process.env.REACT_APP_API_URL + "/Global/Countries";

            axios
                .get(url, AxiosHeader.getConfig())
                .then(res => {
                    const gCountriesArray = res.data;
                    const loading = false;
                    this.setState({gCountriesArray, loading});
                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);

                });
        }else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }
    }

    render() {
        return (
            <Select
                placeholder="Country Select"
                name="countrySelect"
                autoFocus={false}
                multi={true}
                isSearchable={true}
                value={this.props.countrySelected}
                options={this.state.gCountriesArray}
                onChange={value => this.handleSelect(value)}

            />
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDropDownCountries = connect(mapStateToProps)(DropDownCountries);
const connectedDropDownCountriesTrans = withNamespaces('common')(connectedDropDownCountries);
export {connectedDropDownCountriesTrans as DropDownCountries};
