import React, {Component} from "react";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import axios from "axios/index";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {AxiosHeader} from "../../functions/GlobalFunctions";

class DropDownEventCategory extends Component {

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.state = {
            loading: true,
            eventCategory: []
        };
    }

    handleSelect(value) {
        this.props.handlerFromParent(value);
    }

    componentDidMount() {

        if (AxiosHeader.getConfig() !== null) {

            let url = process.env.REACT_APP_API_URL + "/LiveEvent/categories";

            if(this.props.applicationid){
                url = process.env.REACT_APP_API_URL+"/ApplicationsManagement/"+this.props.applicationid+"/event-categories";
            }

            axios
                .get(url, AxiosHeader.getConfig())
                .then(res => {
                    const eventCategory = res.data;
                    const loading = false;
                    this.setState({eventCategory, loading});
                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);

                });

        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }
    }

    render() {
        return (
            <Select
                placeholder="Event Category Select"
                name="categorySelect"
                autoFocus={false}
                multi={false}
                isSearchable={true}
                disabled={this.props.disabled}
                value={this.props.categorySelected}
                options={this.state.eventCategory}
                onChange={value => this.handleSelect(value)}

            />
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDropDownEventCategory = connect(mapStateToProps)(DropDownEventCategory);
const connectedDropDownEventCategoryTrans = withNamespaces('common')(connectedDropDownEventCategory);
export {connectedDropDownEventCategoryTrans as DropDownEventCategory};

