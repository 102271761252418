import React from "react";
import axios from "axios";
import {Badge, OverlayTrigger, Tooltip, Grid, Row, Col} from "react-bootstrap";
import Button from "../CustomButton/CustomButton.jsx";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {ModalApplicationDeviceSubMenuItem} from "../CustomModals/ModalApplicationDeviceSubMenuItem";
import ModalDelete from "../CustomModals/ModalDelete";
import ReactTableV6Simple from "../CustomDataTables/ReactTableV6Simple";
// import {DataApplicationHomeScreen} from "./DataApplicationHomeScreen";
// import {DataApplicationDeviceSubMenuItems} from "./DataApplicationDeviceSubMenuItems";
import {DataApplicationDeviceCategoryItems} from "./DataApplicationDeviceCategoryItems";

class DataApplicationDeviceSubMenu extends React.Component {

    constructor(props) {

        super(props);
        this.state = {

            edit_menu_item_tooltip: (<Tooltip id="edit_menu_item_tooltip">Edit</Tooltip>),
            remove_menu_item_tooltip: (<Tooltip id="remove_menu_item_tooltip">Remove</Tooltip>),

            redirect: false,

            showEditMenuItemModal: false,
            showDeleteMenuItemModal: false,
            newItem: false,

            menu_id: 0,
            loading: true,

            deviceMenuItemArray: [],
            arraySize: 0,

            columns: [
                // {
                //     accessor: "id",
                //     Header: "ID",
                //     maxWidth: 100,
                //     sortable: true,
                //     filterable: false
                // },
                {
                    accessor: "menu_item_status",
                    Header: "Status",
                    maxWidth: 100,
                    sortable: true,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            <Badge fill="true" style={row.value === 1 ? {
                                background: '#87cb16',
                                borderRadius: '2px',
                                lineHeight: "2",
                                width: "100%"
                            } : {background: '#545557', borderRadius: '2px', lineHeight: "2", width: "100%"}}>
                                {
                                    row.value === 1 ? 'Active' : 'Disabled'
                                }
                            </Badge>
                        </div>
                    )
                },
                {
                    accessor: "menu_item_name",
                    Header: "Name",
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "menu_item_position",
                    Header: "Position",
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "menu_item_layout",
                    Header: "menu_item_layout",
                    sortable: true,
                    filterable: false,
                    maxWidth: 250
                },
                {
                    accessor: "menu_item_colour",
                    Header: "menu_item_colour",
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "menu_item_poster",
                    Header: "Image",
                    sortable: true,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            <img
                                src={this.loadImage(row.value)}
                                width="50px" height="40" alt={""}/>
                        </div>
                    )
                },
                {
                    accessor: "menu_item_type",
                    Header: "Type",
                    sortable: true,
                    filterable: false
                },
                {
                    Header: 'actions',
                    maxWidth: 200,
                    sortable: false,
                    filterable: false,
                    accessor: "id",
                    Cell: row => (
                        <div className="actions-right">
                            {this.setHelperButton(row.value)}
                            {this.setDeleteButton(row.value)}
                        </div>
                    )
                }

            ]

        };

    }

    componentDidMount() {
        this.updateMenuItemList(this.props.selectedMenuId)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        //console.log(this.props.selectedMenuId)
        if (this.props.applicationId !== prevProps.applicationId) {
            this.updateMenuItemList(this.props.selectedMenuId);
        }

        if (this.props.selectedMenuId !== prevProps.selectedMenuId) {
            this.updateMenuItemList(this.props.selectedMenuId);
        }
    }


    updateMenuItemList(selectedMenuId) {

        if (this.props.applicationId && this.props.applicationId !== 0 && selectedMenuId !== null) {

            const dataUrl = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + this.props.applicationId + "/device-menu?deviceType=" + this.props.deviceType + "&menuId=" + selectedMenuId;

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {
                    'X-Id-Token': token
                }
            };

            axios
                .get(dataUrl, axiosConfig)
                .then(res => {
                    const deviceMenuItemArray = res.data;
                    const loading = false;
                    this.setState({deviceMenuItemArray, loading, arraySize: deviceMenuItemArray.length});
                })
                .catch((err) => {
                    console.log("ERROR: ", err);
                    this.props.dispatch(userActions.logoutUnauthorized());
                })

        } else {
            this.setState({loading: false, deviceMenuItemArray: []});
        }

    }

    addItem() {
        this.setState({
            showEditMenuItemModal: true,
            newItem: true
        })
    }

    loadImage(value) {

        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
            return image;
        } else {
            let image = process.env.REACT_APP_ASSET_URL + "/device_menu/" + value
            return image;
        }

    }

    //BUTTONS
    setHelperButton(menu_id) {

        return (
            <OverlayTrigger placement="top" overlay={this.state.edit_menu_item_tooltip}>
                <Button
                    onClick={() => this.setState({menu_id: menu_id, showEditMenuItemModal: true})}
                    bsStyle="info"
                    simple
                    icon
                >
                    <i className="fa fa-edit"/>
                </Button>
            </OverlayTrigger>

        )

    }

    setDeleteButton(menu_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.remove_menu_item_tooltip}>
            <Button
                onClick={() => this.setState({menu_id: menu_id, showDeleteMenuItemModal: true})}
                bsStyle="danger"
                simple
                icon
            >
                <i className="fa fa-times"/>
            </Button>
        </OverlayTrigger>)

    }

    setAddEditMenuItemModal = (id, value, action) => {

        //console.log(id)

        this.setState({
            showEditMenuItemModal: value,
            newItem: false
        })

        if (action === "reload") {
            this.updateMenuItemList(id);
        }

    }

    setDeleteMenuItemModal = (id, value, action) => {

        this.setState({
            showDeleteMenuItemModal: value,
            newItem: false
        })

        if (action === "delete") {
            this.deleteMenuItem(id);
        }

    }

    deleteMenuItem(submenu_id) {

        if (this.props.applicationId && this.props.applicationId !== 0) {

            const deleteURL = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + this.props.applicationId + "/menu/" + this.props.selectedMenuId + "/device-sub-menu/" + submenu_id + "?deviceType=" + this.props.deviceType;

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {
                    'X-Id-Token': token
                }
            };

            axios.delete(deleteURL, axiosConfig)
                .then((res) => {
                    this.updateMenuItemList(this.props.selectedMenuId);
                    this.setState({menu_id: 0});
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                    //this.props.dispatch(userActions.logoutUnauthorized());
                })

        }


    }

    //HELPERS
    findArrayElementByID(item_id) {

        //console.log(this.state.deviceMenuItemArray)
        return this.state.deviceMenuItemArray.find((element) => {
            return element.id === item_id;
        })
    }

    renderAddEditDeviceMenuItem = () => {

        // let bodyMessageVar = "";
        let menuItemObject = [];
        //
        if (!this.state.newItem) {
            menuItemObject = this.findArrayElementByID(this.state.menu_id)
            //console.log(menuItemObject)
        }

        if (this.state.showEditMenuItemModal) {
            console.log("ClickedSub Sub Menu Item Modal...");
            console.log(this.props.selectedMenu);
            return <ModalApplicationDeviceSubMenuItem
                headerMessage={"Sub Menu Item: "}
                bodyMessage={""}
                handlerFromParent={this.setAddEditMenuItemModal}
                menuItemObject={menuItemObject}
                newMenuItem={this.state.newItem}
                handlerID={this.state.menu_id}
                deviceType={this.props.deviceType}
                selectedMenuId={this.props.selectedMenuId}
                applicationId={this.props.applicationId}
                menuItem={this.props.selectedMenu}
                deviceTypeMulti={this.state.menu_item_device_type_multi}
            />
        }
    }

    renderDeleteMenuItemModal = () => {

        let bodyMessageVar = "this action will delete the device. This action can be undone";

        if (this.state.showDeleteMenuItemModal) {
            console.log("Clicked Sub Menu Item Modal...");
            return <ModalDelete
                headerMessage={"Delete Sub Menu Item"}
                bodyMessage={bodyMessageVar}
                handlerFromParent={this.setDeleteMenuItemModal}
                handlerID={this.state.menu_id}
                applicationId={this.props.applicationId}
                selectedMenuId={this.props.selectedMenuId}
            />
        }
    }

    renderDeviceCategoryMenuItems = (deviceType, row) => {

        if (deviceType !== null ) {

            console.log(row)
            return (
                <DataApplicationDeviceCategoryItems
                    deviceType={deviceType}
                    applicationId={this.props.applicationId}
                    selectedMenuId={row.original.id}
                    menuItemObject={row}
                />
            );

        }


    }

    render() {
        return (
            <div>
                {this.renderAddEditDeviceMenuItem()}
                {this.renderDeleteMenuItemModal()}

                <br/>
                <div style={{
                    border: "aliceblue",
                    borderWidth: "thin",
                    borderStyle: "groove"
                }}>
                    <Grid fluid>
                        <Row>
                            <Col md={12} style={{backgroundColor: "cornsilk"}}>
                                <div className="pull-left">
                                    <h5>
                                        Sub Menu Items
                                    </h5>
                                </div>
                                <div className="pull-left">
                                    <Button bsStyle={this.props.selectedMenuId === null ? "default" : "info"} fill
                                            bsSize="xs"
                                            style={{marginLeft: "10px", marginTop: "10px"}}
                                            onClick={this.addItem.bind(this)}
                                            disabled={this.props.selectedMenuId === null ? true : false}
                                    >
                                        Add New
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </div>

                <ReactTableV6Simple
                    data={this.state.deviceMenuItemArray}
                    loading={this.state.loading}
                    columns={this.state.columns}
                    defaultPageSize={5}
                    pageSize={this.state.deviceMenuItemArray.length}
                    showPagination={false}
                    showPaginationBottom={false}
                    SubComponent={row => {
                        //console.log(row.original)
                        //console.log(row.original.id)
                        // console.log(row.original.menu_item_application_id)
                        if(row.original.menu_item_type === 'category'){
                            return (
                                <div style={{paddingLeft: "50px", paddingBottom: "50px"}}>
                                    {this.renderDeviceCategoryMenuItems(this.props.deviceType, row)}
                                </div>


                            );
                        }

                    }}
                />
            </div>
        );

    }
}

function mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedDataApplicationDeviceSubMenu = connect(mapStateToProps)(DataApplicationDeviceSubMenu);
export {connectedDataApplicationDeviceSubMenu as DataApplicationDeviceSubMenu};
