import React, {Component} from "react";
import {Grid, Row, Col, FormGroup, FormControl, InputGroup, ControlLabel, Glyphicon,Well} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import axios from "axios/index";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import {Redirect} from "react-router";
import Button from "../../components/CustomButton/CustomButton";
import ModalSave from "../../components/CustomModals/ModalSave";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import {userActions} from "../../authentication/_actions";
import Switch from "react-bootstrap-switch";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {DualListBoxPackageEvent} from "../../components/DualListBox/DualListBoxPackageEvent";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import {DropDownContentProviders} from "../../components/CustomDropDown/DropDownContentProviders";
import {DropDownEventPriceList} from "../../components/CustomDropDown/DropDownEventPriceList";
import {DropDownCountries} from "../../components/CustomDropDown/DropDownCountries";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
`;

const adminArray = ['super-admin', 'admin']

class EditPackagesEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,

            redirect: false,
            eventPackageRedirect: false,

            back_button_text: "Cancel",
            showSaveModal: false,

            eventPackageObject: [],

            event_package_id: 0,
            event_package_name_short: null,
            event_package_name_long: null,
            event_package_description: null,
            event_package_status: false,
            event_package_price: 0.0,
            event_package_is_adult: false,
            event_package_display_while_inactive: false,
            event_package_billing_code: null,
            event_package_subscription_url: null,
            event_package_trial: null,

            statusSwitch: false,


            expirySwitch: false,
            trialSwitch: false,

            assignedEventsInPackage: [],

            event_package_duration: null,

            newPackage: true,

            _notificationSystem: null,

            event_package_content_provider: null,
            current_user: null,
            user_content_provider: null,

            event_package_image_temp: false,
            event_package_image: null,
            event_package_image_folder_temp: "",

            event_package_inactive_image_temp: false,
            event_package_inactive_image: null,
            event_package_inactive_image_folder_temp: "",
            custom_data: null,

            event_package_credit_card_status: false,
            event_package_credit_card_product_id: null,
            event_package_credit_card_control_text: null,
            event_package_credit_card_help_url: null,

            event_package_code_status: false,
            event_package_code_control_text: null,
            event_package_code_help_url: null,

            event_package_account_status: false,
            event_package_account_control_text: null,
            event_package_account_help_url: null,
            event_pgroups: [],

            event_pgroups_cards: null,
            event_pgroups_codes: null,

            creditCardSwitch: false,
            codeSwitch: false,
            accountSwitch: false,

            countryBlockSwitch: false,
            addAllCountriesInCountryBlock: false,
            event_package_country_block_countries: null,


        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);
        this.handleEventPackageChange = this.handleEventPackageChange.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);

        this.handleChildEventPriceGroupsCodesSelected = this.handleChildEventPriceGroupsCodesSelected.bind(this);
        this.handleChildEventPriceGroupsCreditCardSelected = this.handleChildEventPriceGroupsCreditCardSelected.bind(this);
        this.handleChildCountryBlockSelect = this.handleChildCountryBlockSelect.bind(this);

    }


    handleChildEventPriceGroupsCreditCardSelected(data) {

        if (data !== undefined) {
            let speSelectedIds = []
            let speSelectedNames = []

            for (let i = 0; i < data.length; i++) {
                speSelectedIds.push(data[i].value)
            }

            for (let i = 0; i < data.length; i++) {
                speSelectedNames.push(data[i].label)
            }

            this.setState({
                event_pgroups_cards: data === null ? null : speSelectedIds,
            });
        }
    }

    handleChildEventPriceGroupsCodesSelected(data) {

        if (data !== undefined) {
            let speSelectedIds = []
            let speSelectedNames = []

            for (let i = 0; i < data.length; i++) {
                speSelectedIds.push(data[i].value)
            }

            for (let i = 0; i < data.length; i++) {
                speSelectedNames.push(data[i].label)
            }

            this.setState({
                event_pgroups_codes: data === null ? null : speSelectedIds,
            });
        }
    }

    handleChildImageSelect(folder, filename, imagePosition) {

        if (imagePosition === "event_package_image") {
            this.setState({
                event_package_image_temp: filename !== null,
                event_package_image: filename,
                event_package_image_folder_temp: folder
            });
        }
        if (imagePosition === "event_package_inactive_image") {
            this.setState({
                event_package_inactive_image_temp: filename !== null,
                event_package_inactive_image: filename,
                event_package_inactive_image_folder_temp: folder
            });
        }

    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
                assignedEventsInPackage: []
            });
        }
    }

    handleEventPackageChange(selectedFromChild) {

        let selectedEvents = []

        if (selectedFromChild !== undefined !== null) {

            for (let i = 0; i < selectedFromChild.length; i++) {
                selectedEvents.push(selectedFromChild[i].toString())
            }
        }

        this.setState({assignedEventsInPackage: selectedEvents})
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'statusSwitch') {
            this.setState({statusSwitch: state})
        }
        if (elem.props.name === 'expirySwitch') {
            this.setState({expirySwitch: state})
        }
        if (elem.props.name === 'trialSwitch') {
            this.setState({trialSwitch: state})
        }

        if (elem.props.name === 'creditCardSwitch') {
            if (state) {
                this.setState({creditCardSwitch: state})
            } else {
                this.setState({creditCardSwitch: state})
            }
        }

        if (elem.props.name === 'codeSwitch') {
            if (state) {
                this.setState({codeSwitch: state})
            } else {
                this.setState({codeSwitch: state})
            }
        }

        if (elem.props.name === 'accountSwitch') {
            if (state) {
                this.setState({accountSwitch: state})
            } else {
                this.setState({accountSwitch: state})
            }
        }

        if (elem.props.name === 'countryBlockSwitch') {
            this.setState({countryBlockSwitch: state})
        }
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        const {handle} = this.props.match.params

        if (handle === 'new') {
            let user = JSON.parse(localStorage.getItem('user'));
            this.setState({loading: false, newPackage: false, current_user: user})

        } else {

            this.setState({loading: true})
            const url = process.env.REACT_APP_API_URL + "/PackageEvent/" + handle;

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            axios
                .get(url, axiosConfig)
                .then(res => {

                    const eventPackageObject = res.data[0];
                    const loading = false;

                    let selectedContent = []

                    if (eventPackageObject.event_package_assigned_content !== null) {
                        selectedContent = eventPackageObject.event_package_assigned_content.toString().split(",")
                    } else {
                        selectedContent = []
                    }

                    let status = eventPackageObject.event_package_status === 1
                    let expirySwitch = eventPackageObject.event_package_no_expiry === 1
                    let trialSwitch = eventPackageObject.event_package_trial === 1
                    let creditCardSwitch = eventPackageObject.event_package_credit_card_status === 1
                    let codeSwitch = eventPackageObject.event_package_code_status === 1
                    let accountSwitch = eventPackageObject.event_package_account_status === 1
                    let countryBlockSwitch = eventPackageObject.event_package_country_block_status  === 1

                    let eventGroupCardIds = []

                    if (eventPackageObject.event_pgroups_cards !== null) {
                        let temp = eventPackageObject.event_pgroups_cards.toString();
                        eventGroupCardIds = temp.split(",")
                    }

                    let evenGroupCodeIds = []

                    if (eventPackageObject.event_pgroups_codes !== null) {
                        let temp = eventPackageObject.event_pgroups_codes.toString();
                        evenGroupCodeIds = temp.split(",")
                    }


                    let countriesBlocked = []
                    if (eventPackageObject.event_package_country_block_countries !== null) {
                        let tempCountry = eventPackageObject.event_package_country_block_countries.toString();
                        countriesBlocked = tempCountry.split(",")
                    }


                    this.setState({
                        back_button_text: "Back",
                        event_package_id: eventPackageObject.event_package_id,
                        event_package_name_short: eventPackageObject.event_package_name_short,
                        event_package_name_long: eventPackageObject.event_package_name_long,
                        event_package_description: eventPackageObject.event_package_description,
                        event_package_status: eventPackageObject.event_package_status,
                        event_package_price: eventPackageObject.event_package_price,
                        event_package_image: eventPackageObject.event_package_image,
                        event_package_inactive_image: eventPackageObject.event_package_inactive_image,
                        event_package_is_adult: eventPackageObject.event_package_is_adult,
                        event_package_display_while_inactive: eventPackageObject.event_package_display_while_inactive,
                        event_package_billing_code: eventPackageObject.event_package_billing_code,
                        event_package_subscription_url: eventPackageObject.event_package_subscription_url,
                        event_package_trial: trialSwitch,
                        event_package_duration: eventPackageObject.event_package_duration,
                        statusSwitch: status,
                        assignedEventsInPackage: selectedContent,
                        expirySwitch,
                        loading,
                        trialSwitch,
                        eventPackageObject,
                        creditCardSwitch,
                        codeSwitch,
                        accountSwitch,
                        countryBlockSwitch,
                        current_user: user,
                        user_content_provider: eventPackageObject.event_package_content_provider,
                        custom_data: eventPackageObject.custom_data,
                        event_package_credit_card_status: eventPackageObject.event_package_credit_card_status,
                        event_package_credit_card_product_id: eventPackageObject.event_package_credit_card_product_id,
                        event_package_credit_card_control_text: eventPackageObject.event_package_credit_card_control_text,
                        event_package_credit_card_help_url: eventPackageObject.event_package_credit_card_help_url,
                        event_package_code_status: eventPackageObject.event_package_code_status,
                        event_package_code_control_text: eventPackageObject.event_package_code_control_text,
                        event_package_code_help_url: eventPackageObject.event_package_code_help_url,
                        event_package_account_status: eventPackageObject.event_package_account_status,
                        event_package_account_control_text: eventPackageObject.event_package_account_control_text,
                        event_package_account_help_url: eventPackageObject.event_package_account_help_url,
                        event_pgroups_codes: evenGroupCodeIds,
                        event_pgroups_cards: eventGroupCardIds,

                        event_package_country_block_countries: countriesBlocked,
                    });
                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                    //this.props.dispatch(userActions.logoutUnauthorized());
                });
        }
    }

    updateEventPackage() {

        this.setState({loading: true})

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/PackageEvent/" + handle;

        let status = this.state.statusSwitch === false ? 0 : 1
        let expirySwitch = this.state.expirySwitch === false ? 0 : 1
        let trialSwitch = this.state.trialSwitch === false ? 0 : 1
        let creditCardSwitch = this.state.creditCardSwitch === false ? 0 : 1
        let codeSwitch = this.state.codeSwitch === false ? 0 : 1
        let accountSwitch = this.state.accountSwitch === false ? 0 : 1
        let countryBlockStatus = this.state.countryBlockSwitch === false ? 0 : 1

        let contentInPackage = []

        for (let i = 0; i < this.state.assignedEventsInPackage.length; i++) {
            contentInPackage.push(this.state.assignedEventsInPackage[i])
        }

        //RENAME FUNCTIONS START IMAGE ACTIVE
        let filename_active = ""

        if (this.state.event_package_image_temp) {
            let timeUTC = new Date().getTime()
            filename_active = timeUTC + "_" + this.state.event_package_image
            this.setState({event_package_image: filename_active})
            console.log("*****" + filename_active)
            this.submitActiveImage(filename_active);
        } else {
            filename_active = this.state.event_package_image
        }

        //RENAME FUNCTIONS START IMAGE INACTIVE
        let filename_inactive = ""

        if (this.state.event_package_inactive_image_temp) {
            let timeUTC = new Date().getTime()
            filename_inactive = timeUTC + "_" + this.state.event_package_inactive_image
            this.setState({event_package_inactive_image: filename_inactive})
            console.log("*****" + filename_inactive)
            this.submitInactiveImage(filename_inactive);
        } else {
            filename_inactive = this.state.event_package_inactive_image
        }

        // Send a PUT request
        var postData = {
            event_package_id: this.state.event_package_id,
            event_package_name_short: this.state.event_package_name_short,
            event_package_name_long: this.state.event_package_name_long,
            event_package_description: this.state.event_package_description,
            event_package_status: status,
            event_package_price: this.state.event_package_price,
            event_package_image: filename_active,
            event_package_inactive_image: filename_inactive,
            event_package_no_expiry: expirySwitch,
            event_package_display_while_inactive: null,
            event_package_billing_code: this.state.event_package_billing_code,
            event_package_subscription_url: this.state.event_package_subscription_url,
            event_package_trial: trialSwitch,
            event_package_duration: this.state.event_package_duration,
            event_package_assigned_content: contentInPackage.join(),
            event_package_content_provider: this.state.user_content_provider,
            custom_data: this.state.custom_data,
            event_package_credit_card_status: creditCardSwitch,
            event_package_credit_card_product_id: this.state.event_package_credit_card_product_id,
            event_package_credit_card_control_text: this.state.event_package_credit_card_control_text,
            event_package_credit_card_help_url: this.state.event_package_credit_card_help_url,
            event_package_code_status: codeSwitch,
            event_package_code_control_text: this.state.event_package_code_control_text,
            event_package_code_help_url: this.state.event_package_code_help_url,
            event_package_account_status: accountSwitch,
            event_package_account_control_text: this.state.event_package_account_control_text,
            event_package_account_help_url: this.state.event_package_account_help_url,
            event_pgroups_codes: this.state.event_pgroups_codes != null ? this.state.event_pgroups_codes.join() : null,
            event_pgroups_cards: this.state.event_pgroups_cards != null ? this.state.event_pgroups_cards.join() : null,

            event_package_country_block_status: countryBlockStatus,
            event_package_country_block_countries: this.state.event_package_country_block_countries === null ? null : this.state.event_package_country_block_countries.join(),

        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 301) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else {
                    this.setState({loading: false})
                    this.notification("success")
                }

            })
            .catch((err) => {
                console.log("ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    createEventPackage() {

        this.setState({loading: true})

        const url = process.env.REACT_APP_API_URL + "/PackageEvent";

        let contentInPackage = []

        for (let i = 0; i < this.state.assignedEventsInPackage.length; i++) {
            contentInPackage.push(this.state.assignedEventsInPackage[i])
        }

        let status = this.state.statusSwitch === false ? 0 : 1
        let expirySwitch = this.state.expirySwitch === false ? 0 : 1
        let trialSwitch = this.state.trialSwitch === false ? 0 : 1
        let creditCardSwitch = this.state.creditCardSwitch === false ? 0 : 1
        let codeSwitch = this.state.codeSwitch === false ? 0 : 1
        let accountSwitch = this.state.accountSwitch === false ? 0 : 1
        let countryBlockStatus = this.state.countryBlockSwitch === false ? 0 : 1

        //RENAME FUNCTIONS START IMAGE ACTIVE
        let filename_active = ""

        if (this.state.event_package_image_temp) {
            let timeUTC = new Date().getTime()
            filename_active = timeUTC + "_" + this.state.event_package_image
            this.setState({event_package_image: filename_active})
            console.log("*****" + filename_active)
            this.submitActiveImage(filename_active);
        } else {
            filename_active = this.state.event_package_image
        }

        //RENAME FUNCTIONS START IMAGE INACTIVE
        let filename_inactive = ""

        if (this.state.event_package_inactive_image_temp) {
            let timeUTC = new Date().getTime()
            filename_inactive = timeUTC + "_" + this.state.event_package_inactive_image
            this.setState({event_package_inactive_image: filename_inactive})
            console.log("*****" + filename_inactive)
            this.submitInactiveImage(filename_inactive);
        } else {
            filename_inactive = this.state.event_package_inactive_image
        }

        var postData = {
            event_package_name_short: this.state.event_package_name_short,
            event_package_name_long: this.state.event_package_name_long,
            event_package_description: this.state.event_package_description,
            event_package_status: status,
            event_package_price: this.state.event_package_price,
            event_package_image: filename_active,
            event_package_inactive_image: filename_inactive,
            event_package_no_expiry: expirySwitch,
            event_package_trial: trialSwitch,
            event_package_display_while_inactive: null,
            event_package_billing_code: this.state.event_package_billing_code,
            event_package_assigned_content: contentInPackage.join(),
            event_package_subscription_url: this.state.event_package_subscription_url,
            event_package_duration: this.state.event_package_duration,
            event_package_content_provider: this.state.user_content_provider,
            custom_data: this.state.custom_data,
            event_package_credit_card_status: creditCardSwitch,
            event_package_credit_card_product_id: this.state.event_package_credit_card_product_id,
            event_package_credit_card_control_text: this.state.event_package_credit_card_control_text,
            event_package_credit_card_help_url: this.state.event_package_credit_card_help_url,
            event_package_code_status: codeSwitch,
            event_package_code_control_text: this.state.event_package_code_control_text,
            event_package_code_help_url: this.state.event_package_code_help_url,
            event_package_account_status: accountSwitch,
            event_package_account_control_text: this.state.event_package_account_control_text,
            event_package_account_help_url: this.state.event_package_account_help_url,
            event_pgroups_codes: this.state.event_pgroups_codes != null ? this.state.event_pgroups_codes.join() : null,
            event_pgroups_cards: this.state.event_pgroups_cards != null ? this.state.event_pgroups_cards.join() : null,

            event_package_country_block_status: countryBlockStatus,
            event_package_country_block_countries: this.state.event_package_country_block_countries === null ? null : this.state.event_package_country_block_countries.join(),


        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})

                if (res.data.error === 301) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else {
                    const eventPackageObject = res.data;
                    this.setState({event_package_id: eventPackageObject.event_package_id})
                    this.setEventPackageRedirect();
                    this.notification("success")
                }
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    submitActiveImage(filename_active) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.event_package_image_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.event_package_image_folder_temp);
            bodyFormData.set('location', "package_events");
            bodyFormData.set('filename', filename_active);

            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({event_package_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    submitInactiveImage(filename_inactive) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.event_package_inactive_image_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.event_package_inactive_image_folder_temp);
            bodyFormData.set('location', "package_events");
            bodyFormData.set('filename', filename_inactive);


            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({event_package_inactive_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({loading: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createEventPackage();
            } else {
                this.updateEventPackage();
            }
        }
    }

    cancelEventPackageEdit() {
        this.setRedirect();
    }

    setEventPackageRedirect = () => {
        this.setState({
            eventPackageRedirect: true,
            back_button_text: "Back"
        })
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Event Package: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.event_package_id === 0 ? '' : this.state.event_package_id}
            />
        }

    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/packages/event-package";
        let pathEventpackage = "/edit-event-package/" + this.state.event_package_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.eventPackageRedirect) {

            this.setState({
                eventPackageRedirect: false,
                redirect: false
            })

            return <Redirect push to={pathEventpackage}/>
        }

    }

    renderSelection = () => {

        return (
            <Card
                content={
                    <div>
                        <h5>Event Selection</h5>
                        <legend/>
                        <Grid fluid>
                            <Row>
                                <DualListBoxPackageEvent
                                    eventsInPackage={this.state.assignedEventsInPackage}
                                    packageID={this.state.event_package_id}
                                    handlerFromParent={this.handleEventPackageChange}
                                    eventProvider={this.state.user_content_provider}
                                />
                            </Row>
                        </Grid>
                    </div>
                }
            />
        )


    }

    renderDurationFields = () => {

        if (!this.state.expirySwitch) {
            return (
                <FormGroup>
                    <ControlLabel>Duration (Days)</ControlLabel>
                    <FormControl placeholder="Enter the duration"
                                 type="number"
                                 name={"event_package_duration"}
                                 disabled={this.state.loading}
                                 onChange={this.handleInputChange}
                                 value={this.state.event_package_duration || ""}/>
                </FormGroup>
            );
        }


    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Event Package has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update Event Package</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <FormGroup>
                        <ControlLabel>Content Provider</ControlLabel>
                        <DropDownContentProviders
                            contentProviderSelected={this.state.user_content_provider}
                            name='contentProviderSelect'
                            handlerFromParent={this.handleChildContentProviderSelect}
                            disabled={this.state.loading}
                        />
                    </FormGroup>

                )
            }
        }

    }

    onClickAddAllCountriesCountryBlock() {

        this.setState({addAllCountriesInCountryBlock: true})

    }

    handleChildCountryBlockSelect(data) {

        let countryIDs = []
        let countryNames = []

        for (let i = 0; i < data.length; i++) {
            countryIDs.push(data[i].value)
        }

        for (let i = 0; i < data.length; i++) {
            countryNames.push(data[i].label)
        }

        //console.log(data);
        this.setState({
            event_package_country_block_countries: data === null ? 0 : countryIDs, addAllCountriesInCountryBlock: false
        });
    }

    renderCountryBlockFields = () => {

        if (this.state.countryBlockSwitch) {
            return (
                <div>
                    <FormGroup>
                        <ControlLabel>Countries to block</ControlLabel>
                        <DropDownCountries
                            countrySelected={this.state.event_package_country_block_countries}
                            name='countryBlockSelect'
                            handlerFromParent={this.handleChildCountryBlockSelect}
                            hadlerAddAllCountries={this.state.addAllCountriesInCountryBlock}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Button
                            onClick={this.onClickAddAllCountriesCountryBlock.bind(this)}
                        >
                            Add All countries
                        </Button>
                    </FormGroup>

                    <Well><Glyphicon glyph="question-sign"/> By enabling the Country Block
                        functionality,the live channels will be hidden for the countries specified.
                    </Well>
                </div>
            );
        }
    }

    renderCreditCardFields = () => {


        if (this.state.creditCardSwitch) {
            return (
                <div>

                    <FormGroup>
                        <ControlLabel>Credit Card Control Text</ControlLabel>
                        <FormControl placeholder="Enter credit card control text"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="event_package_credit_card_control_text"
                                     onChange={this.handleInputChange}
                                     value={this.state.event_package_credit_card_control_text || ""}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>Credit Card product ID</ControlLabel>
                        <FormControl placeholder="Enter credit card product id"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="event_package_credit_card_product_id"
                                     onChange={this.handleInputChange}
                                     value={this.state.event_package_credit_card_product_id || ""}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>Credit Card Help URL</ControlLabel>
                        <FormControl placeholder="Enter credit card help url"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="event_package_credit_card_help_url"
                                     onChange={this.handleInputChange}
                                     value={this.state.event_package_credit_card_help_url || ""}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>Card price groups</ControlLabel>
                        <DropDownEventPriceList
                            priceGroupsSelected={this.state.event_pgroups_cards}
                            name='eventCardPriceGroupsSelected'
                            handlerFromParent={this.handleChildEventPriceGroupsCreditCardSelected}
                            disabled={this.state.loading}
                        />
                    </FormGroup>

                </div>
            );
        }
    }

    renderCodeFields = () => {


        if (this.state.codeSwitch) {
            return (
                <div>

                    <FormGroup>
                        <ControlLabel>Code Control Text</ControlLabel>
                        <FormControl placeholder="Enter code control text"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="event_package_code_control_text"
                                     onChange={this.handleInputChange}
                                     value={this.state.event_package_code_control_text || ""}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>Code Help URL</ControlLabel>
                        <FormControl placeholder="Enter code help url"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="event_package_code_help_url"
                                     onChange={this.handleInputChange}
                                     value={this.state.event_package_code_help_url || ""}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>Code price groups</ControlLabel>
                        <DropDownEventPriceList
                            priceGroupsSelected={this.state.event_pgroups_codes}
                            name='eventCodePriceGroupsSelected'
                            handlerFromParent={this.handleChildEventPriceGroupsCodesSelected}
                            disabled={this.state.loading}
                        />
                    </FormGroup>

                </div>
            );
        }
    }

    renderAccountFields = () => {

        if (this.state.accountSwitch) {
            return (
                <div>

                    <FormGroup>
                        <ControlLabel>Account Control Text</ControlLabel>
                        <FormControl placeholder="Enter account control text"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="event_package_account_control_text"
                                     onChange={this.handleInputChange}
                                     value={this.state.event_package_account_control_text || ""}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>Account Help URL</ControlLabel>
                        <FormControl placeholder="Enter account help url"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="event_package_account_help_url"
                                     onChange={this.handleInputChange}
                                     value={this.state.event_package_account_help_url || ""}
                        />
                    </FormGroup>

                </div>
            );
        }
    }

    render() {
        return (
            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                        //loading={true}
                    />
                </div>

                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Card
                                content={
                                    <div>
                                        <h5>Event Package Information</h5>
                                        <legend/>

                                        <FormGroup>
                                            <Row>
                                                <Col md={3}>
                                                    <ControlLabel>Status</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"statusSwitch"}
                                                        disabled={this.state.loading}
                                                        value={this.state.statusSwitch}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <ControlLabel>No Expiry</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"expirySwitch"}
                                                        disabled={this.state.loading}
                                                        value={this.state.expirySwitch}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <ControlLabel>Trial</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"trialSwitch"}
                                                        disabled={this.state.loading || this.state.expirySwitch}
                                                        value={this.state.trialSwitch}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                </Col>
                                            </Row>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Short Name</ControlLabel>
                                            <FormControl placeholder="Enter Short Name"
                                                         type="text"
                                                         name={"event_package_name_short"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.event_package_name_short || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Long Name</ControlLabel>
                                            <FormControl placeholder="Enter Long Name"
                                                         type="text"
                                                         name={"event_package_name_long"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.event_package_name_long || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Description</ControlLabel>
                                            <FormControl componentClass="textarea"
                                                         type="text"
                                                         rows={4}
                                                         name="event_package_description"
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         placeholder="Enter the description"
                                                         value={this.state.event_package_description || ""}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Reference ID</ControlLabel>
                                            <FormControl placeholder="Enter the price"
                                                         type="text"
                                                         name={"event_package_billing_code"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.event_package_billing_code || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Price</ControlLabel>
                                            <FormControl placeholder="Enter the price"
                                                         type="number"
                                                         name={"event_package_price"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.event_package_price || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>External URL</ControlLabel>
                                            <InputGroup>
                                                <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                                    <Glyphicon glyph="globe"/>
                                                </InputGroup.Addon>
                                                <FormControl
                                                    placeholder="Enter external url"
                                                    type="text"
                                                    name="event_package_subscription_url"
                                                    disabled={this.state.loading}
                                                    onChange={this.handleInputChange}
                                                    value={this.state.event_package_subscription_url || ""}
                                                />
                                            </InputGroup>
                                        </FormGroup>


                                        {this.renderDurationFields()}

                                        {this.renderContentProvider()}

                                        <FormGroup>
                                            <ControlLabel>Country Block</ControlLabel>
                                            <br/>
                                            <Switch
                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                                name='countryBlockSwitch'
                                                disabled={this.state.loading}
                                                value={this.state.countryBlockSwitch}
                                                defaultValue={false}
                                            />
                                        </FormGroup>

                                        {this.renderCountryBlockFields()}


                                        <div>
                                            <br/>
                                            <legend/>
                                            <ControlLabel>Credit Card</ControlLabel>
                                            <FormGroup>
                                                <Switch
                                                    onChange={(el, state) => this.handleSwitch(el, state)}
                                                    name='creditCardSwitch'
                                                    disabled={this.state.loading}
                                                    value={this.state.creditCardSwitch}
                                                    defaultValue={false}
                                                />
                                            </FormGroup>
                                            {this.renderCreditCardFields()}
                                        </div>

                                        <div>
                                            <br/>
                                            <legend/>
                                            <ControlLabel>Codes</ControlLabel>
                                            <FormGroup>
                                                <Switch
                                                    onChange={(el, state) => this.handleSwitch(el, state)}
                                                    name='codeSwitch'
                                                    disabled={this.state.loading}
                                                    value={this.state.codeSwitch}
                                                    defaultValue={false}
                                                />
                                            </FormGroup>
                                            {this.renderCodeFields()}
                                        </div>

                                        <div>
                                            <br/>
                                            <legend/>
                                            <ControlLabel>Account</ControlLabel>
                                            <FormGroup>
                                                <Switch
                                                    onChange={(el, state) => this.handleSwitch(el, state)}
                                                    name='accountSwitch'
                                                    disabled={this.state.loading}
                                                    value={this.state.accountSwitch}
                                                    defaultValue={false}
                                                />
                                            </FormGroup>
                                            {this.renderAccountFields()}
                                        </div>

                                    </div>
                                }
                                ftTextCenter
                                legend={
                                    <div className="buttons-with-margin">
                                        <Button bsStyle="info" fill wd
                                                name={"saveButton"}
                                                disabled={this.state.loading}
                                                onClick={() => this.setState({showSaveModal: true})}
                                        >
                                            Save
                                        </Button>
                                        <Button bsStyle="default" fill wd
                                                name={"cancelButton"}
                                                disabled={this.state.loading}
                                                onClick={this.cancelEventPackageEdit.bind(this)}>
                                            {this.state.back_button_text}
                                        </Button>
                                    </div>
                                }
                            />
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Card
                                content={
                                    <div>
                                        <h5>Assets</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="text-center">
                                                        <p>Active</p>
                                                    </div>
                                                    <ImageUpload
                                                        imageName={this.state.event_package_image}
                                                        imageType="package_events"
                                                        imagePosition={"event_package_image"}
                                                        imageTemp={this.state.event_package_image_temp}
                                                        imageFolderTemp={this.state.event_package_image_folder_temp}
                                                        handlerFromParent={this.handleChildImageSelect}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <div className="text-center">
                                                        <p>Inactive</p>
                                                    </div>
                                                    <ImageUpload
                                                        imageName={this.state.event_package_inactive_image}
                                                        imageType="package_events"
                                                        imagePosition={"event_package_inactive_image"}
                                                        imageTemp={this.state.event_package_inactive_image_temp}
                                                        imageFolderTemp={this.state.event_package_inactive_image_folder_temp}
                                                        handlerFromParent={this.handleChildImageSelect}
                                                    />
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </div>
                                }
                            />
                        </Col>


                        <Col xs={12} sm={12} md={12} lg={6}>
                            {this.renderSelection()}
                        </Col>

                    </Row>
                </Grid>


            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedEditPackagesEvent = connect(mapStateToProps)(EditPackagesEvent);
const connectedEditPackagesEventTrans = withNamespaces('common')(connectedEditPackagesEvent);
export {connectedEditPackagesEventTrans as EditPackagesEvent};
