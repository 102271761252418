import React, {Component} from "react";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import axios from "axios/index";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class DropDownRadioCategory extends Component {

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.state = {
            loading: true,
            tvCategory: []
        };

    }

    handleSelect(value) {
        this.props.handlerFromParent(value);
    }

    componentDidMount() {

        let url = process.env.REACT_APP_API_URL+"/Radio/categories";

        if(this.props.applicationid){
            url = process.env.REACT_APP_API_URL+"/ApplicationsManagement/"+this.props.applicationid+"/radio-categories";
        }


        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url,axiosConfig)
            .then(res => {
                const tvCategory = res.data;
                const loading = false;
                this.setState({tvCategory, loading});
            })
            .catch(error => {
                this.setState({loading: false});
                console.log(error);
                this.props.dispatch(userActions.logoutUnauthorized());
            });
    }

    render() {
        return (
            <Select
                placeholder="Category Select"
                name="categorySelect"
                autoFocus={false}
                multi={false}
                isSearchable={true}
                disabled={this.props.loading}
                value={this.props.categorySelected}
                options={this.state.tvCategory}
                onChange={value => this.handleSelect(value)}

            />
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDropDownRadioCategory = connect(mapStateToProps)(DropDownRadioCategory);
const connectedDropDownRadioCategoryTrans = withNamespaces('common')(connectedDropDownRadioCategory);
export {connectedDropDownRadioCategoryTrans as DropDownRadioCategory};
