import React, {Component} from "react";
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl, HelpBlock} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import Switch from "react-bootstrap-switch";
import axios from "axios/index";
import { css } from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import {Redirect} from "react-router";
import Button from "../../components/CustomButton/CustomButton";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import ModalSave from "../../components/CustomModals/ModalSave";
import Select from "react-select";
import {DropDownContentProviders} from "../../components/CustomDropDown/DropDownContentProviders";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

const vodCatType = [
    {value: 'TVOD', label: 'TVOD'},
    {value: 'AVOD', label: 'AVOD'},
    {value: 'SVOD', label: 'SVOD'}
];

const adminArray = ['super-admin', 'admin']

class EditVodCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {

            _notificationSystem: null,
            showSaveModal: false,

            loading: false,
            saving: false,
            redirect: false,
            vodCatRedirect: false,
            back_button_text: "Cancel",

            vodCategoryObject: [],

            vod_cat_id: null,
            vod_cat_position: null,
            vod_cat_status: false,
            vod_cat_global_status: false,
            vod_cat_image: null,
            vod_cat_name_short: null,
            vod_cat_name_long: null,
            vod_cat_description: null,

            vod_cat_image_temp: false,
            vod_cat_image_folder_temp: "",
            vod_cat_type: null,
            vod_cat_system_name: null,

            vod_cat_name_short_message: "",
            vod_cat_name_short_state: null,
            vod_cat_name_long_message: "",
            vod_cat_name_long_state: null,
            vod_cat_position_message: "",
            vod_cat_position_state: null,
            vod_cat_type_state: null,
            vod_cat_type_message: "",
            vod_cat_system_name_state: null,
            vod_cat_system_name_message: "",

            vod_cat_content_provider:null,
            current_user: null,
            user_content_provider: null,
            custom_data: null
        };

        //this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);

    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
            });
        }
    }


    handleCategoryTypeChange = (vodCatType) => {

        this.setState({
            vodCatType,
            vod_cat_type: vodCatType === null ? "pending" : vodCatType.value
        });
        //console.log(`Option selected:`, vodCatType);

    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/movies/vcategories";
        let pathCategory = "/edit-vod-cat/" + this.state.vod_cat_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.vodCatRedirect) {
            //console.log(pathCategory);
            return <Redirect push to={pathCategory}/>
        }

    }

    submitPosterImage() {

        if (this.state.vod_cat_image_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.vod_cat_image_folder_temp);
            bodyFormData.set('location', "vod_cat");

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({vod_cat_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    handleChildImageSelect(folder, filename) {

        //console.log("" + folder);
        //console.log("" + filename);
        //console.log(process.env.REACT_APP_ASSET_URL + "/tmp/" + folder + "/" + filename);

        this.setState({
            vod_cat_image_temp: true,
            vod_cat_image: filename,
            vod_cat_image_folder_temp: folder
        });
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/VodCategories/" + handle;

        if (handle === 'new') {
            this.setState({loading: false})
        } else {

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            axios
                .get(url, axiosConfig)
                .then(res => {

                    const categoryObject = res.data[0];
                    const loading = false;

                    //console.log(categoryObject);

                    this.setState({
                        back_button_text: "Back",
                        vod_cat_id: categoryObject.vod_cat_id,
                        vod_cat_status: categoryObject.vod_cat_status === 1,
                        vod_cat_global_status: categoryObject.vod_cat_global_status === 1,
                        vod_cat_position: categoryObject.vod_cat_position === null ? 0 : categoryObject.vod_cat_position,
                        vod_cat_name_short: categoryObject.vod_cat_name_short === null ? "" : categoryObject.vod_cat_name_short,
                        vod_cat_name_long: categoryObject.vod_cat_name_long === null ? "" : categoryObject.vod_cat_name_long,
                        vod_cat_description: categoryObject.vod_cat_description === null ? "" : categoryObject.vod_cat_description,
                        vod_cat_image: categoryObject.vod_cat_image === null ? "" : categoryObject.vod_cat_image,
                        vod_cat_type: categoryObject.vod_cat_type === null ? null : categoryObject.vod_cat_type,
                        vod_cat_system_name: categoryObject.vod_cat_system_name === null ? null : categoryObject.vod_cat_system_name,
                        loading,
                        vodCategoryObject: categoryObject,
                        current_user: user,
                        user_content_provider: categoryObject.vod_cat_content_provider,
                        custom_data: categoryObject.custom_data,
                    });
                })
                .catch(error => {
                    this.setState({loading: false});
                    this.props.dispatch(userActions.logoutUnauthorized());
                    console.log(error);
                });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'statusSwitch' ? target.checked : target.value;
        const name = target.name;

        console.log("1." + target);
        console.log("2." + value);
        console.log("3." + name);

        if (name === "vod_cat_name_short") {

            let tempValue = this.state.vod_cat_type + "/" + value
            console.log("4." + tempValue);

            this.setState({
                vod_cat_system_name: tempValue,
                vod_cat_name_short: value
            });

        } else {
            this.setState({
                [name]: value
            });
        }
    }

    updateVodCategory() {

        this.submitPosterImage();

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/VodCategories/" + handle;

        let tempVodStatus = this.state.vod_cat_status === true ? 1 : 0;
        let tempVodGlobalStatus = this.state.vod_cat_global_status === true ? 1 : 0;

        // Send a PUT request
        var postData = {
            vod_categories: {
                vod_cat_id: handle,
                vod_cat_status: tempVodStatus,
                vod_cat_global_status: tempVodGlobalStatus,
                vod_cat_position: this.state.vod_cat_position,
                vod_cat_name_short: this.state.vod_cat_name_short,
                vod_cat_name_long: this.state.vod_cat_name_long,
                vod_cat_description: this.state.vod_cat_description,
                vod_cat_image: this.state.vod_cat_image,
                vod_cat_type: this.state.vod_cat_type,
                vod_cat_system_name: this.state.vod_cat_system_name,
                vod_cat_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data,
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                this.setState({saving: false, loading: false})

                if (res.data.error === 401 || res.data.error === 403) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                }

                if (res.data.state === false) {
                    this.notification("failed")

                    this.setState({
                        vod_cat_name_short_message: res.data.vod_cat_name_short_message,
                        vod_cat_name_short_state: res.data.vod_cat_name_short_state,
                        vod_cat_name_long_message: res.data.vod_cat_name_long_message,
                        vod_cat_name_long_state: res.data.vod_cat_name_long_state,
                        vod_cat_position_message: res.data.vod_cat_position_message,
                        vod_cat_position_state: res.data.vod_cat_position_state,
                        vod_cat_type_state: res.data.vod_cat_type_state,
                        vod_cat_type_message: res.data.vod_cat_type_message,
                        vod_cat_system_name_state: res.data.vod_cat_system_name_state,
                        vod_cat_system_name_message: res.data.vod_cat_system_name_message,
                        vod_cat_status: res.data.vod_cat_status,
                        vod_cat_global_status: res.data.vod_cat_global_status,
                        custom_data: res.data.custom_data,
                    })

                } else {
                    this.notification("success")
                }
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                //this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    //1. Constructor
    //2. ComponentDidMount
    //3. Render
    //4. Function
    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Category updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Failed to update category</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    createVodCategory() {

        const url = process.env.REACT_APP_API_URL + "/VodCategories";

        let tempVodStatus = this.state.vod_cat_status === true ? 1 : 0;
        let tempVodGlobalStatus = this.state.vod_cat_global_status === true ? 1 : 0;

        var postData = {
            vod_categories: {
                vod_cat_status: tempVodStatus,
                vod_cat_global_status: tempVodGlobalStatus,
                vod_cat_position: this.state.vod_cat_position,
                vod_cat_name_short: this.state.vod_cat_name_short,
                vod_cat_name_long: this.state.vod_cat_name_long,
                vod_cat_description: this.state.vod_cat_description,
                vod_cat_image: this.state.vod_cat_image,
                vod_cat_type: this.state.vod_cat_type,
                vod_cat_system_name: this.state.vod_cat_system_name,
                vod_cat_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data,
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {

                this.setState({saving: false, loading: false})

                if (res.data.error === 401 || res.data.error === 403) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                }

                if (res.data.state === false) {
                    this.notification("failed")

                    this.setState({
                        vod_cat_name_short_message: res.data.vod_cat_name_short_message,
                        vod_cat_name_short_state: res.data.vod_cat_name_short_state,
                        vod_cat_name_long_message: res.data.vod_cat_name_long_message,
                        vod_cat_name_long_state: res.data.vod_cat_name_long_state,
                        vod_cat_position_message: res.data.vod_cat_position_message,
                        vod_cat_position_state: res.data.vod_cat_position_state,
                        vod_cat_type_state: res.data.vod_cat_type_state,
                        vod_cat_type_message: res.data.vod_cat_type_message,
                        vod_cat_system_name_state: res.data.vod_cat_system_name_state,
                        vod_cat_system_name_message: res.data.vod_cat_system_name_message,
                        custom_data: res.data.custom_data,
                    })

                } else {
                    this.notification("success")
                    const vodCategoryObject = res.data;
                    this.setState({vod_cat_id: vodCategoryObject.vod_cat_id})
                    this.submitPosterImage();
                    this.setVodCatRedirect();
                }


            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            })

    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'statusSwitch') {
            this.setState({vod_cat_status: state})
        }
        if (elem.props.name === 'statusGlobalSwitch') {
            this.setState({vod_cat_global_status: state})
        }
    }

    cancelVodCatEdit() {
        this.setRedirect();
    }

    setVodCatRedirect = () => {
        this.setState({
            vodCatRedirect: true,
            back_button_text: "Back"
        })
    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({saving: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createVodCategory();
            } else {
                this.updateVodCategory();
            }
        }
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Category: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.vod_cat_id === null ? '' : this.state.vod_cat_id}
            />
        }
    }

    title() {
        if (this.state.vod_cat_name_long === null) {
            return "New - Category Settings";
        } else {
            return this.state.vod_cat_name_long + " - Category Settings";
        }
    }

    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <FormGroup>
                        <ControlLabel>Content Provider</ControlLabel>
                        <DropDownContentProviders
                            contentProviderSelected={this.state.user_content_provider}
                            name='contentProviderSelect'
                            handlerFromParent={this.handleChildContentProviderSelect}
                            disabled={this.state.loading}
                        />
                    </FormGroup>

                )
            }
        }

    }

    render() {
        const {handle} = this.props.match.params
        return (
            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>
                {this.renderSaveModal()}
                {this.renderRedirect()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Grid fluid>
                    <Row>
                        <Col md={6}>
                            <Card
                                title={this.title()}
                                content={
                                    <div>
                                        <Grid fluid>
                                            <Row>
                                                <legend/>
                                                <FormGroup>
                                                    <ImageUpload
                                                        imageName={this.state.vod_cat_image}
                                                        imageType="vod_cat"
                                                        imagePosition={"vod_cat_image"}
                                                        imageTemp={this.state.vod_cat_image_temp}
                                                        imageFolderTemp={this.state.vod_cat_image_folder_temp}
                                                        handlerFromParent={this.handleChildImageSelect}
                                                    />
                                                </FormGroup>
                                                <legend/>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <p className="category">{this.props.t('global.status', {framework: "react-i18next"})}</p>
                                                        <Switch
                                                            name={"statusSwitch"}
                                                            value={this.state.vod_cat_status}
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <p className="category">{"Globally Available"}</p>
                                                        <Switch
                                                            name={"statusGlobalSwitch"}
                                                            value={this.state.vod_cat_global_status}
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup controlId="formValidationType"
                                                               validationState={this.state.vod_cat_type_state}>
                                                        <ControlLabel>Type</ControlLabel>
                                                        <Select
                                                            value={this.state.vod_cat_type}
                                                            onChange={this.handleCategoryTypeChange}
                                                            options={vodCatType}
                                                            disabled={handle !== 'new'}
                                                        />
                                                        <HelpBlock>{this.state.vod_cat_type_message}</HelpBlock>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup controlId="formValidationPosition"
                                                               validationState={this.state.vod_cat_position_state}>
                                                        <ControlLabel>{this.props.t('global.position', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl placeholder="Position"
                                                                     type="number"
                                                                     name={"vod_cat_position"}
                                                                     onChange={this.handleInputChange}
                                                                     disabled={this.state.vod_cat_type === null}
                                                                     value={this.state.vod_cat_position || ''}/>
                                                        <HelpBlock>{this.state.vod_cat_position_message}</HelpBlock>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>

                                            </Row>

                                            <FormGroup controlId="formValidationSystemName"
                                                       validationState={this.state.vod_cat_system_name_state}>
                                                <ControlLabel>System Name</ControlLabel>
                                                <FormControl placeholder="System Name"
                                                             type="text"
                                                             disabled
                                                             name={"vod_cat_system_name"}
                                                             value={this.state.vod_cat_system_name || ''}/>
                                                <HelpBlock>{this.state.vod_cat_system_name_message}</HelpBlock>
                                            </FormGroup>

                                            <FormGroup controlId="formValidationShortName"
                                                       validationState={this.state.vod_cat_name_short_state}>
                                                <ControlLabel>Short Name</ControlLabel>
                                                <FormControl placeholder="Enter Short Name"
                                                             type="text"
                                                             name={"vod_cat_name_short"}
                                                             onChange={this.handleInputChange}
                                                             disabled={this.state.vod_cat_type === null}
                                                             value={this.state.vod_cat_name_short || ''}/>
                                                <HelpBlock>{this.state.vod_cat_name_short_message}</HelpBlock>
                                            </FormGroup>

                                            <FormGroup controlId="formValidationLongName"
                                                       validationState={this.state.vod_cat_name_long_state}>
                                                <ControlLabel>Long Name</ControlLabel>
                                                <FormControl placeholder="Enter Long Name"
                                                             type="text"
                                                             name={"vod_cat_name_long"}
                                                             onChange={this.handleInputChange}
                                                             disabled={this.state.vod_cat_type === null}
                                                             value={this.state.vod_cat_name_long || ''}/>
                                                <HelpBlock>{this.state.vod_cat_name_long_message}</HelpBlock>
                                            </FormGroup>

                                            <FormGroup>
                                                <ControlLabel>{this.props.t('vod_category_edit.description', {framework: "react-i18next"})}</ControlLabel>
                                                <FormControl componentClass="textarea"
                                                             type="text"
                                                             name="vod_cat_description"
                                                             disabled={this.state.vod_cat_type === null}
                                                             onChange={this.handleInputChange}
                                                             placeholder="Enter the description"
                                                             value={this.state.vod_cat_description || ''}
                                                />
                                            </FormGroup>

                                            {this.renderContentProvider()}

                                        </Grid>
                                    </div>
                                }
                                ftTextCenter
                                legend={
                                    <div className="buttons-with-margin">
                                        <Button bsStyle="info" fill wd
                                                name={"saveButton"}
                                                onClick={() => this.setState({showSaveModal: true})}
                                        >
                                            Save
                                        </Button>
                                        <Button bsStyle="default" fill wd name={"cancelButton"}
                                                onClick={this.cancelVodCatEdit.bind(this)}>
                                            {this.state.back_button_text}
                                        </Button>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>


            </div>
        );
    }
}

function mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedEditVodCategory = connect(mapStateToProps)(EditVodCategory);
const connectedEditVodCategoryTrans = withNamespaces('common')(connectedEditVodCategory);
export {connectedEditVodCategoryTrans as EditVodCategory};

