import React, {Component} from "react";
import {Col, Grid, Row} from "react-bootstrap";
import Card from "../../components/Card/Card";
import {DataOrganizations} from "../../components/Middleware/DataOrganizations";
import contentOrganizations from "../../assets/img/corporation.png";

class Organizations extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };

    }


    render() {
        return (
            <div className="main-content">

                <Grid fluid>
                    <Row>
                        <Col sm={12}>
                            <Card
                                content={
                                    <Grid fluid>
                                        <Row>
                                            <Col sm={5}>
                                            </Col>
                                            <Col sm={2}>
                                                <div className="center">
                                                    <img className="img-responsive center-block"
                                                         style={{maxHeight: '60px', maxWidth: '100%'}}
                                                         src={contentOrganizations}
                                                         alt={"contentOrganizations"}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={5}>
                                            </Col>
                                        </Row>
                                    </Grid>
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <Card
                                content={
                                    <DataOrganizations
                                        listName={"List of Organizations"}
                                        // handlerFromParent={this.handleChildUpdate}
                                    />

                                }
                            />
                        </Col>
                    </Row>


                </Grid>
            </div>
        )
    }

}

export default Organizations;
